import $ from "jquery";
import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Link } from "react-router-dom";
import { axiosInstance } from "../../../Services/PostData";
import "./CompSkills.css";
import DataTable from 'react-data-table-component';
import DataTableExtensions from "react-data-table-component-extensions";

class CompSkills extends Component {
  constructor() {
    super();
    this.state = {
      values: [],
    };
    this.getSkillDetails = this.getSkillDetails.bind(this);
  }

  componentDidMount() {
    let spinner = "loadercustumspinner";
    let overlay = "loadingcustumspinner";
    this.setState({ spinner, overlay });
    this.getSkillDetails();
    $(document).ready(function () {
      setTimeout(
        function () {
          $("#default-ordering").DataTable({
            oLanguage: {
              oPaginate: {
                sPrevious:
                  '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-left"><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg>',
                sNext:
                  '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>',
              },
              sInfo: "Showing page _PAGE_ of _PAGES_",
              sSearch:
                '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>',
              sSearchPlaceholder: "Search...",
              sLengthMenu: "Results :  _MENU_",
            },
            order: [[0, "asc"]],
            stripeClasses: [],
            lengthMenu: [10, 20, 50],
            pageLength: 10,
            drawCallback: function () {
              $(".dataTables_paginate > .pagination").addClass(
                " pagination-style-13 pagination-bordered mb-5"
              );
            },
          });
        }.bind(this),
        100
      );
    });
  }

  async getSkillDetails() {
    await axiosInstance
      .get(`getSkillCategory`)
      .then((res) => {
        if (res.data.status === "success" && res.data.statusCode === "200") {
          let spinner = "loadercustumspinner";
          let overlay = "loadingcustumspinner hide__container";
          this.setState({ spinner, overlay });
          let response = res.data.skill_details;
          if (response.length != 0 || response.length != "0") {
            setTimeout(function () {
              $(".dataTables_empty").hide();
            }, 100);
          }
          let response_Details = response.map((value) => {
            return value;
          });
          this.setState({ values: response_Details });
        }
      })
      .catch((err) => console.log("Login: ", err));
  }

  // editFucntion(id) {
  //     // this.props.history.push()
  //     this.setState({redirect: true});
  // }

  deleteFucntion(id) {
    confirmAlert({
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            let spinner = "loadercustumspinner";
            let overlay = "loadingcustumspinner";
            this.setState({ spinner, overlay });

            const user = { skill_category_id: id };
            axiosInstance
              .post(`deleteSkillCategory`, user)
              .then((res) => {
                if (
                  res.data.status === "success" &&
                  res.data.statusCode === "200"
                ) {
                  window.location.reload();
                  let spinner = "loadercustumspinner";
                  let overlay = "loadingcustumspinner hide__container";
                  this.setState({ spinner, overlay });
                }
              })
              .catch((err) => console.log("Login: ", err));
          },
        },
        {
          label: "No",
        },
      ],
    });
  }

  render() {

    const columns = [
      {
        name: 'Skill Category Name',
        selector: 'skill_category',
        sortable: true,
        filterable: true,
      },
      {
        name: 'Action',
        selector: (row, index) => {
          return <div>
                    <Link to={{  pathname: "/EditSkills", search: "skillId=" + row.skill_category_id, }} >
                        <i class="fa fa-pencil text-success font-18" aria-hidden="true" ></i>
                    </Link>
                    <i class="fa fa-trash text-danger font-18 m-l-10" aria-hidden="true" onClick={() =>
                                this.deleteFucntion(row.skill_category_id)
                        }
                    ></i>
                  </div>
        },
        sortable: true,
        filterable: true,
      }
    ];

    const data = this.state.values;
    const tableData = {
      columns,
      data
    };


    return (
      <div id="content" className="main-content">
        <div className="layout-px-spacing">
          <div className="row layout-top-spacing2 layout-top-spacing">
            <div className="col-xl-12 col-lg-12 col-sm-12 layout-spacing2  layout-spacing">
              <div className="widget-content widget-content-area pg-top">
                <div className="table-responsive mb-4 mt-1">
                  <div className="top_heading_sty">
                    <p className="table-head">Skills Master</p>
                    <div class="d-flex Fixes_addbtn justify-content-sm-end justify-content-center add_btn_sty">
                      <Link to="/AddSkills" className="add_btn_icon_style">
                        Add New{" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-plus-square"
                        >
                          <rect
                            x="3"
                            y="3"
                            width="18"
                            height="18"
                            rx="2"
                            ry="2"
                          ></rect>
                          <line x1="12" y1="8" x2="12" y2="16"></line>
                          <line x1="8" y1="12" x2="16" y2="12"></line>
                        </svg>
                      </Link>
                    </div>
                  </div>

                  {/* <table
                    id="default-ordering"
                    className="table1 table table-hover dataNewTable"
                  >
                    <thead>
                      <tr className="">
                        <th>Skill Category Name</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody id="tableBodyd">
                      {this.state.values.map((value) => (
                        <tr
                          className=""
                          role="row"
                          key={value.skill_category_id}
                        >
                          <td>{value.skill_category}</td>
                          <td>
                            <Link
                              to={{
                                pathname: "/EditSkills",
                                search: "skillId=" + value.skill_category_id,
                              }}
                            >
                              <i
                                class="fa fa-pencil text-success font-18"
                                aria-hidden="true"
                              ></i>
                            </Link>

                            <i
                              class="fa fa-trash text-danger font-18"
                              aria-hidden="true"
                              onClick={() =>
                                this.deleteFucntion(value.skill_category_id)
                              }
                            ></i>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table> */}

                            <DataTableExtensions {...tableData}
                                print={false}
                                exportHeaders={false}
                                pdf={false}
                                export={false}
                                >
                                <DataTable
                                    columns={columns}
                                    data={data}
                                    noHeader
                                    defaultSortAsc={false}
                                    pagination
                                    highlightOnHover
                                    // expandableRows={true}
                                    // expandableRowsComponent={<SampleExpandedComponent />}
                                    // expandOnRowClicked
                                />
                            </DataTableExtensions>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-wrapper">
          <div className="footer-section f-section-1">
            <p className="">
              Copyright © 2020{" "}
              <a target="_blank" href="javascript:void(0);">
                TSAS
              </a>
              , All rights reserved.
            </p>
          </div>
        </div>
        <div className={this.state.overlay}>
          <div className={this.state.spinner}></div>
        </div>
      </div>
    );
  }
}
export default CompSkills;
