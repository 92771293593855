// import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-dt/js/dataTables.dataTables";
import $ from "jquery";
import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Link } from "react-router-dom";
import swal from 'sweetalert2';
import { axiosInstance } from "../../../Services/PostData";
import "./SuperAdmin.css";
import DataTable from 'react-data-table-component';
import DataTableExtensions from "react-data-table-component-extensions";


class SuperAdmin extends Component {
  constructor() {
    super();
    this.state = {
      values: [],
      typeTSASAdminCompany: window.localStorage.getItem('typeTSASAdminCompany'),
    };
    this.getSuperAdminn_details = this.getSuperAdminn_details.bind(this);
  }

  componentDidMount() {
    //show spinner
    let spinner = "loadercustumspinner";
    let overlay = "loadingcustumspinner";
    this.setState({ spinner, overlay });
    this.getSuperAdminn_details();
    $(document).ready(function () {
      setTimeout(
        function() {
          $('#default-ordering').DataTable( {
              "oLanguage": {
                  "oPaginate": { "sPrevious": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-left"><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg>', "sNext": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>' },
                  "sInfo": "Showing page _PAGE_ of _PAGES_",
                  "sSearch": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>',
                  "sSearchPlaceholder": "Search...",
                 "sLengthMenu": "Results :  _MENU_",
              },
              "order": [[ 0, "asc" ]],
              "stripeClasses": [],
              "lengthMenu": [10, 20, 50],
              "pageLength": 10,
              drawCallback: function () { $('.dataTables_paginate > .pagination').addClass(' pagination-style-13 pagination-bordered mb-5'); }
          } );
  
        }.bind(this),100);
  });
    
  }

  getSuperAdminn_details() {
//    axiosInstance
//      .get(`getSuperAdminn_details`)
//      .then((res) => {
//        if (res.data.status === "success" && res.data.statusCode === "200") {
//          let spinner = "loadercustumspinner";
//          let overlay = "loadingcustumspinner hide__container";
//          this.setState({ spinner, overlay });
//          let response = res.data.user_details;
//          if (response.length != 0 || response.length != "0") {
//            setTimeout(function () {
//              $(".dataTables_empty").hide();
//            }, 100);
//          }
//          let response_Details = response.map((value) => {
//            return value;
//          });
//          this.setState({ values: response_Details });
//        }
//      })
//      .catch((err) => console.log("Login: ", err));
      
      const json = { company_id: this.state.typeTSASAdminCompany };
        axiosInstance
          .post(`getSuperAdminn_details`, json)
          .then((res) => {
            if (res.data.status === "success" && res.data.statusCode === "200") {

              let spinner = "loadercustumspinner";
              let overlay = "loadingcustumspinner hide__container";
              this.setState({ spinner, overlay });
              let response = res.data.user_details;
              if (response.length !== 0 || response.length !== "0") {
                setTimeout(function () {
                  $(".dataTables_empty").hide();
                }, 100);
              }

              let response_Details = response.map((value) => {
                return value;
              });
              this.setState({ values: response_Details });
            }
            else {
              let spinner = "loadercustumspinner";
              let overlay = "loadingcustumspinner hide__container";
              this.setState({ spinner, overlay });
            }
          })
          .catch((err) => console.log("Login: ", err));
  }

  deleteFucntion(id) {
    confirmAlert({
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            //show spinner
            let spinner = "loadercustumspinner";
            let overlay = "loadingcustumspinner";
            this.setState({ spinner, overlay });
            const user = { id: id };
            axiosInstance
              .post(`deleteUsers`, user)
              .then((res) => {
                if (
                  res.data.status === "success" &&
                  res.data.statusCode === "200"
                ) {
                  let spinner = "loadercustumspinner";
                  let overlay = "loadingcustumspinner hide__container";
                  this.setState({ spinner, overlay });
                  window.location.reload();
                }
              })
              .catch((err) => console.log("Login: ", err));
          },
        },
        {
          label: "No",
        },
      ],
    });
  }

  suspendFucntion(id) {
    confirmAlert({
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            //show spinner
            let spinner = "loadercustumspinner";
            let overlay = "loadingcustumspinner";
            this.setState({ spinner, overlay });
            const user = { id: id };
            axiosInstance
              .post(`Suspend_user`, user)
              .then((res) => {
                if (
                  res.data.status === "success" &&
                  res.data.statusCode === "200"
                ) {
                  let spinner = "loadercustumspinner";
                  let overlay = "loadingcustumspinner hide__container";
                  this.setState({ spinner, overlay });
                  window.location.reload();
                }
              })
              .catch((err) => console.log("Login: ", err));
          },
        },
        {
          label: "No",
        },
      ],
    });
  }

  copyCodeToClipboard(id,email,username) {
    confirmAlert({
      message: "Are you sure to do you want to send the Resetpassword Link as Email to this user.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            //show spinner
            let spinner = "loadercustumspinner";
            let overlay = "loadingcustumspinner";
            this.setState({ spinner, overlay });
            const user = { id: id ,email:email,username:username};
            axiosInstance
              .post(`Resetpassword_Mail`, user)
              .then((res) => {
                if (
                  res.data.status === "success" &&
                  res.data.statusCode === "200"
                ) {
                  let spinner = "loadercustumspinner";
                  let overlay = "loadingcustumspinner hide__container";
                  this.setState({ spinner, overlay });
                  swal
                  .fire({
                    type: "info",
                    title: "Reset Password sent Successfuly",
                    // text: 'do you want to continuew',
                    confirmButtonText: "ok",
                    confirmButtonColor: "#D82B2A",
                  })
                }
              })
              .catch((err) => console.log("Login: ", err));
          },
        },
        {
          label: "No",
        },
      ],
    });
  }

  unsuspendFucntion(id) {
    confirmAlert({
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            //show spinner
            let spinner = "loadercustumspinner";
            let overlay = "loadingcustumspinner";
            this.setState({ spinner, overlay });
            const user = { id: id };
            axiosInstance
              .post(`UnSuspend_user`, user)
              .then((res) => {
                if (
                  res.data.status === "success" &&
                  res.data.statusCode === "200"
                ) {
                  let spinner = "loadercustumspinner";
                  let overlay = "loadingcustumspinner hide__container";
                  this.setState({ spinner, overlay });
                  window.location.reload();
                }
              })
              .catch((err) => console.log("Login: ", err));
          },
        },
        {
          label: "No",
        },
      ],
    });
  }

  render() {

    // const SampleExpandedComponent = ({ data }) => (
    //   <div style={{padding: '1% 5%'}}>
    //     <div className="expandable-container-col-one col-md-4 pull-left">
    //     <p>
    //       <span className="tc_user_lbl">Name</span> 
    //       <span className="tc_lbl_colon">: </span>
    //       <span className="tc_lbl_content">{data.user_name}</span>
    //     </p>
        
    //     <p>
    //       <span className="tc_user_lbl">Email</span> 
    //       <span className="tc_lbl_colon">: </span>
    //       <span className="tc_lbl_content">{data.user_email}</span>
    //     </p>
        
    //     <p>
    //       <span className="tc_user_lbl">Phone</span> 
    //       <span className="tc_lbl_colon">: </span>
    //       <span className="tc_lbl_content">{data.phone_prefix} - {data.user_phone}</span>
    //     </p>
    //   </div>
    //   <div className="expandable-container-col-two col-md-4 pull-left">
    //     <a href={'mailto:' + data.user_email} class="btn btn-primary btn-rounded m-l-10" >Send Email</a>
    //   </div>
    // </div>
    // );
    

    const columns = [
      {
        name: 'S.No',
        selector: 'index_value',
        sortable: true,
        filterable: true,
      },
      {
        name: 'Name',
        selector: 'admin_user_name',
        sortable: true,
        filterable: true,
      },
      {
        name: 'Email',
        selector: 'admin_user_email',
        sortable: true,
        filterable: true,
      },
      {
        name: 'Phonenumber',
        selector: 'admin_user_phone',
        sortable: true,
        filterable: true,
      },
      {
        name: 'Reset Password',
        selector: (row, index) => {
          return <i class="fa fa-lock text-lock font-18 cursorpointer" aria-hidden="true"
                    onClick={() => this.copyCodeToClipboard(row.admin_user_id,row.admin_user_email,row.admin_user_name)} ></i>
        },
        sortable: true,
        filterable: true,
      },
      {
        name: 'Suspend / Un-suspend',
        selector: (row, index) => {
          if(row.flag == 1) {
            return <p class="Suspend_user" onClick={() => this.suspendFucntion(row.admin_user_id) } > Suspend </p>
          }
          else {
            return <p class="unSuspend_user" onClick={() => this.unsuspendFucntion(row.admin_user_id) } >  Un-Suspend </p>
          }
        },
        sortable: true,
        filterable: true,
      },
      {
        name: 'Action',
        selector: (row, index) => {
            return  <div>
                      <Link to={{ pathname: '/EditUsers', search: 'Id='+row.admin_user_id  }} >
                        <i class="fa fa-pencil text-success font-18" aria-hidden="true"></i>
                      </Link>
                      <i class="fa fa-trash text-danger font-18 cursorpointer m-l-10" aria-hidden="true"
                      onClick={() => this.deleteFucntion(row.admin_user_id) } ></i>
                    </div>;
          
        },
        sortable: true,
        filterable: true,
      }
    ];

    const data = this.state.values;
    const tableData = {
      columns,
      data
    };



    return (
      <div id="content" class="main-content">
        <div class="layout-px-spacing">
          <div class="row layout-top-spacing2 layout-top-spacing">
            <div class="col-xl-12 col-lg-12 col-sm-12 layout-spacing2  layout-spacing">
              <div class="widget-content widget-content-area pg-top">
                <div class="table-responsive mb-4 mt-1">
                  <p className="table-head table-head-no-add">Supper Admin</p>
                  
                  {/* <table id="default-ordering" class="table1 table table-hover">
                    <thead>
                      <tr class="">
                        <th>S.No</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Phonenumber</th>
                        <th>Reset Password</th>
                        <th>view</th>
                        <th>Suspend / Un-suspend</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.values.map((value) => (
                        <tr className="" role="row" key={value.admin_user_id}>
                          <td> {value.index_value} </td>
                          <td>{value.admin_user_name}</td>
                          <td>{value.admin_user_email}</td>
                          <td>{value.admin_user_phone}</td>
                          <td>

                            <i
                              class="fa fa-lock text-lock font-18 cursorpointer"
                              aria-hidden="true"
                              onClick={() => this.copyCodeToClipboard(value.admin_user_id,value.admin_user_email,value.admin_user_name)}
                            ></i>
                          </td>
                          <td>
                            <i
                              class="fa fa-eye text-primary font-18"
                              aria-hidden="true"
                            ></i>
                          </td>
                          <td>
                            {value.flag == 1 ? (
                              <p
                                class="Suspend_user"
                                onClick={() =>
                                  this.suspendFucntion(value.admin_user_id)
                                }
                              >
                                {" "}
                                Suspend{" "}
                              </p>
                            ) : (
                              <p
                                class="unSuspend_user"
                                onClick={() =>
                                  this.unsuspendFucntion(value.admin_user_id)
                                }
                              >
                                {" "}
                                Un-Suspend{" "}
                              </p>
                            )}
                          </td>
                          <td>
                            <Link
                              to={{
                                pathname: "/EditUsers",
                                search: "Id=" + value.admin_user_id,
                              }}
                            >
                              <i
                                class="fa fa-pencil text-success font-18"
                                aria-hidden="true"
                              ></i>
                            </Link>

                            <i
                              class="fa fa-trash text-danger font-18 cursorpointer"
                              aria-hidden="true"
                              onClick={() =>
                                this.deleteFucntion(value.admin_user_id)
                              }
                            ></i>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table> */}

                <DataTableExtensions {...tableData}
                    print={false}
                    exportHeaders={false}
                    pdf={false}
                    export={false}
                    >
                    <DataTable
                        columns={columns}
                        data={data}
                        noHeader
                        defaultSortAsc={false}
                        pagination
                        highlightOnHover
                        // expandableRows={true}
                        // expandableRowsComponent={<SampleExpandedComponent />}
                        // expandOnRowClicked
                    />
                </DataTableExtensions>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          id="faderightModal"
          class="modal animated fadeInRight custo-fadeInRight show"
          role="dialog"
          aria-modal="true"
        >
          <div class="modal-dialog-content modal-dialog">
            <div class="modal-background-color modal-content">
              <div class="modal-header">
                <h5 class="modal-title">User Details</h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <svg
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-x"
                  >
                    <line x1="18" y1="6" x2="6" y2="18"></line>
                    <line x1="6" y1="6" x2="18" y2="18"></line>
                  </svg>
                </button>
              </div>
              <div class="modal-body">
                <div>
                  <div class="">
                    <div class="row">
                      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 layout-top-spacing">
                        <div class="user-profile layout-spacing">
                          <div class="widget-content widget-content-area widget-content-area-res">
                            <div class="d-flex justify-content-between">
                              <h3 class="">Profile</h3>
                              <a
                                href="user_account_setting.html"
                                class="mt-2 edit-profile edit-profile-back"
                              ></a>
                            </div>
                            <div class="text-center user-info">
                              <img
                                class="profile_image_res"
                                src="assets/img/user.png"
                                alt="avatar"
                              />
                              <p class="">User Name</p>
                            </div>
                            <div class="user-info-list">
                              <div class="">
                                <ul class="contacts-block list-unstyled">
                                  <li class="contacts-block__item">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      class="feather feather-coffee"
                                    >
                                      <path d="M18 8h1a4 4 0 0 1 0 8h-1"></path>
                                      <path d="M2 8h16v9a4 4 0 0 1-4 4H6a4 4 0 0 1-4-4V8z"></path>
                                      <line x1="6" y1="1" x2="6" y2="4"></line>
                                      <line
                                        x1="10"
                                        y1="1"
                                        x2="10"
                                        y2="4"
                                      ></line>
                                      <line
                                        x1="14"
                                        y1="1"
                                        x2="14"
                                        y2="4"
                                      ></line>
                                    </svg>
                                    Super Admin
                                  </li>
                                  <li class="contacts-block__item">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      class="feather feather-mail"
                                    >
                                      <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
                                      <polyline points="22,6 12,13 2,6"></polyline>
                                    </svg>
                                    Superadmin@gmail.com
                                  </li>
                                  <li class="contacts-block__item">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      class="feather feather-phone"
                                    >
                                      <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path>
                                    </svg>
                                    +91 98675 76593
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-xl-6 col-lg-6 col-md-7 col-sm-12 layout-top-spacing">
                        <div class="skills layout-spacing ">
                          <div class="widget-content widget-content-area widget-content-area-res">
                            <h3 class="">Skills</h3>

                            <div>
                              <div class="tags-input-wrapper">
                                <span class="tag">
                                  macOS<a>×</a>
                                </span>
                                <span class="tag">
                                  Linux<a>×</a>
                                </span>
                                <span class="tag">
                                  Unix<a>×</a>
                                </span>
                                <span class="tag">
                                  Windows7<a>×</a>
                                </span>
                                <span class="tag">
                                  Ubuntu<a>×</a>
                                </span>
                                <span class="tag">
                                  Windows8<a>×</a>
                                </span>
                                <span class="tag">
                                  iOS<a>×</a>
                                </span>
                                <span class="tag">
                                  WindowsXP<a>×</a>
                                </span>
                                <span class="tag">
                                  BeOS<a>×</a>
                                </span>
                                <span class="tag">
                                  WatchOS<a>×</a>
                                </span>
                                <span class="tag">
                                  Windows10<a>×</a>
                                </span>
                                <span class="tag">
                                  Fedora<a>×</a>
                                </span>
                                <span class="tag">
                                  Windows8.1<a>×</a>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer md-button">
                <button class="btn" data-dismiss="modal">
                  <i class="flaticon-cancel-12"></i> Close
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="footer-wrapper">
          <div class="footer-section f-section-1">
            <p class="">
              Copyright © 2020{" "}
              <a target="_blank" href="javascript:void(0);">
                TSAS
              </a>
              , All rights reserved.
            </p>
          </div>
        </div>

        <div className={this.state.overlay}>
          <div className={this.state.spinner}></div>
        </div>
      </div>
    );
  }
}

export default SuperAdmin;
