import React from 'react';
import CompFixesDetails from '../../Component/Fixes/CompFixesDetails';
import LeftSideBar from '../../Component/LeftSideBar/LeftSideBar';
import TopBar from '../../Component/TopBar/TopBar';

export default function OpenFixes() {
 return (
        <div >
            <TopBar />
            <LeftSideBar />
            <div class="main-container" id="container">

                <div class="overlay"></div>
                <div class="search-overlay"></div>

                <CompFixesDetails />
            </div>
       </div>
    )
}