import React, { Component } from "react";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Link, Redirect } from "react-router-dom";
import Cookies from "universal-cookie";
import { axiosInstance } from "../../Services/PostData";
import "./CompProfile.css";
import swal from 'sweetalert2';
const cookies = new Cookies();

class CompProfileChangePassword extends Component {
  constructor() {
    super();
    this.state = {
      values: [],
      current_password: "",
      new_password: "",
      confirm_new_password: ""
    };
    this.onChange = this.onChange.bind(this)
    this.submitFormHandler = this.submitFormHandler.bind(this)
  }

  componentDidMount() {
    let adminUserId = cookies.get("adminUserId");
  }

  validate = () => {
    let currentPasswordError = "";
    let passwordError = "";
    let confirmPasswordError = "";

    // password
    const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");

    // if( this.state.current_password.length < 8) {
    //     currentPasswordError = "Password should be 8 characters";
    // }
    // else if( ! strongRegex.test(this.state.current_password)) {
    //     passwordError = "use special character, number and capital letter";
    // }

    if( this.state.new_password.length < 8) {
        passwordError = "Password should be 8 characters";
    }
    else if( ! strongRegex.test(this.state.new_password)) {
        passwordError = "use special character, number and capital letter";
    }

    //confirm password
    if(this.state.new_password !== this.state.confirm_new_password)
    {
        confirmPasswordError = "Wrong password";
    }

    if (currentPasswordError || passwordError || confirmPasswordError) {
      this.setState({ currentPasswordError, passwordError, confirmPasswordError});
      return false;
    }

    return true;
  };

  submitFormHandler() {
        
        const body = {
            user_id: cookies.get("adminUserId"),
            new_password: this.state.new_password,
            confirm_new_password: this.state.confirm_new_password
        };

        //calling signup API
        let errorMessageresponse = '';
        const isValid = this.validate();
        if (isValid) {

            let spinner = 'lds-dual-ring custom-screen-middle';
            let overlay = 'overlay';
            this.setState({ spinner, overlay });

            axiosInstance.post(`updateAdminUserPassword`, body)
            .then(res => {
            if(res.data.status === 'success' && res.data.statusCode ==='200' )
            {
                let spinner = 'lds-dual-ring custom-screen-middle hide__container';
                let overlay = 'overlay hide__container';
                this.setState({ spinner, overlay });

                swal.fire({
                    title: 'Updated Successfuly',
                    confirmButtonText: 'ok',
                    confirmButtonColor: '#D82B2A'
                }).then ((result) => {
                    this.setState({dashboardRedirect: true});
                });
            }
            else if(res.data.statusCode ==='400') 
            {
                let spinner = 'lds-dual-ring custom-screen-middle hide__container';
                let overlay = 'overlay hide__container';
                this.setState({ spinner, overlay });

                errorMessageresponse = res.data.msg;
                this.setState({ errorMessageresponse});
            }

                

            })
            .catch(err => console.log('SignUp: ', err));
        }
    }

    onChange(e) {
        this.setState({[e.target.name]: e.target.value})
    }	



  render() {
    if (this.state.dashboardRedirect) {
        return <Redirect to={{ pathname: "/Dashboard" }} />;
    }

    let adminUserId = cookies.get("adminUserId");
    return (
      <div id="content" className="main-content">
        <div className="layout-px-spacing">
          <div className="row layout-top-spacing2 layout-top-spacing">
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 layout-top-spacing profile_padd_left">
              <div class="user-profile layout-spacing">
                <div class="widget-content widget-content-area widget-content-area-res">
                <div class="d-flex justify-content-between">
                    <h3 class="">Change Password</h3>
                    <a class="mt-2 edit-profile edit-profile-back"></a>
                </div>
                  
                  <div class="form" id="formid">
                      
                    {/* <div class="row">
                      <div class="col-sm-12">
                        <div class="form-group">
                          <label for="current_password">Current Password</label>
                          <input name="current_password" type="text" class="form-control" id="current_password" placeholder="current password" onChange={this.onChange} />
                        </div>
                      </div>
                      <span className="col-sm-12 frm-err-msg">{this.state.currentPasswordError}</span>
                    </div> */}
                    

                    <div class="row">
                      <div class="col-sm-12">
                        <div class="form-group">
                          <label for="new_password">New Password</label>
                          <input name="new_password" type="text" class="form-control" id="new_password" placeholder="New password" onChange={this.onChange}  />
                        </div>
                      </div>
                      <span className="col-sm-12 frm-err-msg">{this.state.passwordError}</span>
                    </div>
                    

                    <div class="row">
                    <div class="col-sm-12">
                        <div class="form-group">
                          <label for="confirm_new_password">Confirm Password</label>
                            <input name="confirm_new_password" type="text" class="form-control" id="confirm_new_password" placeholder="confirm password" onChange={this.onChange}  />
                        </div>
                    </div>
                    <span className="col-sm-12 frm-err-msg">{this.state.confirmPasswordError}</span>
                    </div>
                    

                    <span className="col-sm-12" style={{ fontSize: 12, fontWeight: "bold", color: "#d82b2a", }} >
                      {this.state.errorMessageresponse}
                    </span>

                    <div class="col-sm-6">
                      <Link onClick={() => this.submitFormHandler()} className="btn btn-primary" >
                        Submit
                      </Link>
                      &nbsp; &nbsp;
                      <Link
                        to={{ pathname: "/Dashboard" }}
                        className="btn btn-danger"
                      >
                        Cancel
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={this.state.overlay}>
            <div className={this.state.spinner}></div>
        </div>

      </div>
    );
  }
}

export default CompProfileChangePassword;
