import React from 'react';

import TopBar from '../../../Component/TopBar/TopBar'
import LeftSideBar from '../../../Component/LeftSideBar/LeftSideBar'
import CompCountry from '../../../Component/Master/Country/CompCountry'

export default function Country() {
    return (
        <div >
            <TopBar />
            <LeftSideBar />
            <div class="main-container" id="container">

                <div class="overlay"></div>
                <div class="search-overlay"></div>

                <CompCountry />
            </div>
       </div>
    )
}