import "datatables.net-dt/js/dataTables.dataTables";
import $ from 'jquery';
import React, { Component } from 'react';
import { confirmAlert } from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Link } from 'react-router-dom';
import { axiosInstance } from '../../Services/PostData';
import { SocketConnection } from '../../Services/SocketConnection';
import io from "socket.io-client";
let socket = io.connect(SocketConnection)

class FixesAbort extends Component {
    constructor(){
		super();
		this.state = {
            responseValue: [],
            user_responseValue: [],
            chat_responseValue: [],
            description: '',
            chatboxActive: false
        };
		this.getSupportUserDetails = this.getSupportUserDetails.bind(this);
		this.getIndividualChat = this.getIndividualChat.bind(this);
		this.onChangeHandler = this.onChangeHandler.bind(this);
		this.submitSupportChatMessage = this.submitSupportChatMessage.bind(this);
		this.endSupportChatMessage = this.endSupportChatMessage.bind(this);
    }
    
    componentDidMount() {

        this.getSupportUserDetails();

        socket.emit('join room', "tsasTouchToFixSupportAdminSanyaa");
        let spinner = "loadercustumspinner";
        let overlay = "loadingcustumspinner";
        // this.setState({ spinner, overlay });
        // this.getFixesDetails();

        //***get socket chat details ***
        socket.on('getSupportRoomDetails_Connection', (name, data) => {
            let response = data;
            let chat_time = response[0].timestamp;
            if(chat_time !== undefined) {
                let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                let timestamp_convert = this.convertTZ(chat_time, timeZone);
                let chat_time_response = this.getNowTimeDate(timestamp_convert);
                response[0].timestamp = chat_time_response;
            }
            let responseValue_arr = this.state.chat_responseValue
            responseValue_arr.push(response[0]);
            
            let responseDetails = responseValue_arr.map(detail_values => { return detail_values });
            this.setState({ chat_responseValue: responseDetails });

            //***scroll to top ***
            // this.scrollToBottom();

        }) 
        
        //***get socket chat details ***
        socket.on('loadSupportChatUserDetails', (name, data) => {
            this.getSupportUserDetails();
        }) 
    }
    
    onChangeHandler(e) {
        this.setState({[e.target.name]: e.target.value});
        this.setState({chatMessageError: ""})
      }

    async getSupportUserDetails() {
        this.setState({ showSpinner: true });
        axiosInstance.post(`getSupportUserDetails`)
        .then(res => {
            if(res.data.status === 'success' && res.data.statusCode ==='200' )
            {
                let user_responseValue = res.data.chat_user_details;
                let responseDetails = user_responseValue.map(detail_values => { return detail_values });
                this.setState({ user_responseValue: responseDetails });
            }
            else {
                this.setState({ showSpinner: false });
            }
        })
        .catch(err => console.log(err));
    }
    
    getNowTimeDate(timestamp) {

        //get date
        let dd = timestamp.getDate();
        let mm = timestamp.getMonth()+1; 
        let yyyy = timestamp.getFullYear();
        if(dd<10)  {
            dd='0'+dd;
        } 
        if(mm<10)  {
            mm='0'+mm;
        } 
        let today = dd+'/'+mm+'/'+yyyy;

        //get timing
        let now_hour = timestamp.getHours();
        let now_min = timestamp.getMinutes();
        let now_ampm = now_hour >= 12 ? 'pm' : 'am';
        now_hour = now_hour % 12;
        now_hour = now_hour ? now_hour : 12; // the hour '0' should be '12'
        now_min = now_min < 10 ? '0'+now_min : now_min;
        let strTime = now_hour + ':' + now_min + ' ' + now_ampm;
        
        let date_and_time = today +" - " + strTime;
        return date_and_time;
  
    }

    convertTZ(date, tzString) {
        return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {timeZone: tzString}));   
    }
    
    async getIndividualChat(userId, chat_id) {
        //open chat box
        this.setState({chatboxActive: true})

        this.setState({socket_user_id: userId});
        this.setState({send_btn_chat_id: chat_id});

        this.updateSupportChatReadStatus(chat_id);
        
        const body = { support_chat_room_id: chat_id };

        axiosInstance.post(`getSupportChatMessages`, body)
        .then(res => {
            if(res.data.status === 'success' && res.data.statusCode ==='200' )
            {
                let chat_responseValue = res.data.chat_details;
                for(let i=0; i < chat_responseValue.length; i++) {

                    let chat_time = chat_responseValue[i].timestamp;
                    if(chat_time != '') {
                      let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                      let timestamp_convert = this.convertTZ(chat_time, timeZone);
                      let chat_time_response = this.getNowTimeDate(timestamp_convert);
                      chat_responseValue[i].timestamp = chat_time_response;
                    }
                }
                let responseDetails = chat_responseValue.map(detail_values => { return detail_values });
                this.setState({ chat_responseValue: responseDetails });
            }
            else {
                this.setState({ showSpinner: false });
            }
        })
        .catch(err => console.log(err));

        this.getSupportUserDetails();
    }

    submitSupportChatMessage(chat_room_id) {
        
        let nowDateee = new Date();
        let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        let timestamp = this.convertTZ(nowDateee, timeZone);

          this.setState({ showSpinner: true });
          const body = {
            support_chat_room_id: chat_room_id,
            message: this.state.description,
            timestamp: timestamp
           };


        if(this.state.description == '' || this.state.description == undefined) 
        {
          let chatMessageError = "Please type something";
          this.setState({chatMessageError})
        }
        else 
        {
            axiosInstance.post(`insertSupportChatMessages`, body)
            .then(res => {

                let socket_message = this.state.description;
                this.state.description = '';

                if(res.data.status === 'success' && res.data.statusCode ==='200' )
                {
                    this.setState({ showSpinner: false });
                    this.sendSupportSocketMessage(this.state.socket_user_id, socket_message, timestamp);
                    this.updateSupportChatReadStatus(chat_room_id);
                }
                else {
                    this.setState({ showSpinner: false });
                }
            })
            .catch(err => console.log(err));
        }
    }

    sendSupportSocketMessage(user_id, message, timestamp) {
        let to_connect = user_id+'userSupportSubscribeId';
        let type = 'A';
        let customer_id = user_id;
        socket.emit('getSupportRoomDetails_Connection',to_connect, message, type, customer_id, timestamp);
        to_connect = 'tsasTouchToFixSupportAdminSanyaa';
        socket.emit('getSupportRoomDetails_Connection',to_connect, message, type, customer_id, timestamp);
    }

    updateSupportChatReadStatus(id) {

        const body = { support_chat_room_id: id };

        axiosInstance.post(`updateSupportChatReadStatus`, body)
        .then(res => {
            if(res.data.status === 'success' && res.data.statusCode ==='200' )
            {
                this.setState({ showSpinner: false });
            }
            else {
                this.setState({ showSpinner: false });
            }
        })
        .catch(err => console.log(err));
    }
    
    endSupportChatMessage(id) {
        // const body = { support_chat_room_id: id };
        // axiosInstance.post(`endSupportChatMessage`, body)
        // .then(res => {
        //     if(res.data.status === 'success' && res.data.statusCode ==='200' )
        //     {
        //         window.location.reload(false);
        //     }
        //     else {
        //         window.location.reload(false);
        //     }
        // })
        // .catch(err => console.log(err));

        confirmAlert({
            message: "Are you sure you want to end chat.",
            buttons: [
              {
                label: "Yes",
                onClick: () => {
                 const body = { support_chat_room_id: id };
                axiosInstance.post(`endSupportChatMessage`, body)
                .then(res => {
                    if(res.data.status === 'success' && res.data.statusCode ==='200' )
                    {
                        window.location.reload(false);
                    }
                    else {
                        window.location.reload(false);
                    }
                })
                .catch(err => console.log(err));
                },
              },
              {
                label: "No",
              },
            ],
          });

    }


render() {
    return (
    <div id="content" className="main-content">
    <div class="layout-px-spacing">

    <div class="chat-section layout-top-spacing">
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12">

                <div class="chat-system">
                    <div class="hamburger"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-menu mail-menu d-lg-none">
                            <line x1="3" y1="12" x2="21" y2="12"></line>
                            <line x1="3" y1="6" x2="21" y2="6"></line>
                            <line x1="3" y1="18" x2="21" y2="18"></line>
                        </svg>
                    </div>
                    <div class="user-list-box">
                        {/* People starts here */}
                        <div class="people">

                        {this.state.user_responseValue.map(values => (
                            <>
                            {(values.user_id === this.state.socket_user_id) ? 

                            <div class="person activePerson" data-chat="person1"  key={values.user_id} 
                                 onClick={() => this.getIndividualChat(values.user_id, values.support_chat_room_id)}>
                                <div class="user-info">
                                    <div class="f-body">
                                        <div class="meta-info">
                                            <span class="user-name" data-name={values.user_name}>{values.user_name}</span>
                                            { values.notification_active == '1' ?
                                                <span class="message-data-name pull-right">
                                                    <i class="fa fa-circle online"></i>
                                                </span>
                                                :
                                                null
                                            }
                                        </div>
                                        <span class="preview">{values.display_message} </span>
                                    </div>
                                </div>
                            </div>

                            :

                            <div class="person" data-chat="person1"  key={values.user_id}
                                 onClick={() => this.getIndividualChat(values.user_id, values.support_chat_room_id)}>
                                <div class="user-info">
                                    <div class="f-body">
                                        <div class="meta-info">
                                            <span class="user-name" data-name={values.user_name}>{values.user_name}</span>
                                            { values.notification_active == '1' ?
                                                <span class="message-data-name pull-right">
                                                    <i class="fa fa-circle online"></i>
                                                </span>
                                                :
                                                null
                                            }
                                            

                                        </div>
                                        <span class="preview">{values.display_message} </span>
                                    </div>
                                </div>
                            </div>

                            }
                            </>

                        ))}
                            
                            
                        {/* <div class="person" data-chat="person1">
                            <div class="user-info">
                                <div class="f-body">
                                    <div class="meta-info">
                                        <span class="user-name" data-name="Elango">Elango Raj</span>
                                    </div>
                                    <span class="preview">How to create a ticket here ? </span>
                                </div>
                            </div>
                        </div> */}

                        </div>
                        {/* People ends here */}
                    </div>

                    <div class="chat-box">

                        { !this.state.chatboxActive ?

                        <div class="chat-not-selected">
                            <p> 
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-message-square">
                                <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path>
                            </svg> 
                            Select user to start chat
                            </p>
                        </div>

                        :

                        <div class="chat-box-inner">
                        {/* <div class="chat-meta-user chat-active">
                            <div class="current-chat-user-name"><span>
                            <span class="name"></span><b>Elango</b></span></div>
                        </div> */}

                        <div class="chat-conversation-box">
                            <div id="chat-conversation-box-scroll" class="chat-conversation-box-scroll">
                                <div class="chat active-chat" data-chat="person1">
                                    {/* <div class="conversation-start">
                                        <span>Today</span>
                                    </div> */}


                                    {this.state.chat_responseValue.map(values => (
                                        <>
                                        {(values.customer_user_id === this.state.socket_user_id) ? 
                                            <span>
                                                {(values.type === 'U') ? 
                                                <>
                                                    <div>{values.timestamp}</div>
                                                    <div class="bubble you">
                                                        {values.message} 
                                                    </div>
                                                </>
                                                :
                                                <>
                                                    <div className="pull-right">{values.timestamp}</div>
                                                    <div class="bubble me">
                                                        {values.message}
                                                    </div>
                                                </>
                                                }
                                            </span>
                                        :
                                        null
                                        }

                                        </>
                                    ))}


                                    {/* {this.state.chat_responseValue.map(values => (
                                        <span>
                                        {(values.type === 'U') ? 

                                        <div class="bubble you">
                                            {values.message} 
                                        </div>
                                        :
                                        <div class="bubble me">
                                            {values.message}
                                        </div>
                                        }
                                        </span>
                                    ))}  */}

                                        {/* <div class="bubble you">
                                            I need a support from you
                                        </div>
                                        <div class="bubble me">
                                            Welcome to TouchToFix, How can i help you ?
                                        </div>
                                        <div class="bubble you">
                                            How to create a ticket here ?
                                        </div> */}
                                </div>
                            </div>
                        </div>

                        <div class="chat-footer chat-active custom-chat-footer">
                            <div class="chat-input">
                                <form class="chat-form" action="javascript:void(0);">
                                    <textarea style={{resize: 'none'}} rows="1" 
                                            class="mail-write-box form-control" 
                                            placeholder="Message" onChange={this.onChangeHandler} 
                                            value = {this.state.description}
                                            name="description"></textarea>
                                </form>

                                {this.state.socket_user_id != undefined && this.state.send_btn_chat_id != undefined ?
                                    <>
                                    <button class="btn btn-danger btn-rounded mt-2 pull-left"
                                            onClick={() => this.endSupportChatMessage(this.state.send_btn_chat_id)} >
                                        End Chat <i class="fa fa fa-close" aria-hidden="true"></i>
                                    </button>
                                    <button class="btn btn-primary btn-rounded mt-2" 
                                            onClick={() => this.submitSupportChatMessage(this.state.send_btn_chat_id) } >
                                    Send <i class="fa fa-paper-plane" aria-hidden="true"></i>
                                    </button>
                                    </>
                                    :
                                    null
                                }

                            </div>
                        </div>
                        </div>

                        }

                        

                    

                    </div>
                </div>

            </div>
        </div>
    </div>

    </div>

        <div className={this.state.overlay}>
          <div className={this.state.spinner}></div>
        </div>
    </div>
    )
}
}
export default FixesAbort