import $ from 'jquery';
import React, { Component } from 'react';
// import {as} from '../../../js/datatables'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Link } from 'react-router-dom';
import swal from 'sweetalert2';
import { axiosInstance } from '../../../Services/PostData';
import './UserTechSupport.css';
import DataTable from 'react-data-table-component';
import DataTableExtensions from "react-data-table-component-extensions";

class IncompleteTechSupport extends Component {

    constructor(){
    super();
    this.state = {
      techies: []
    }
		this.getIncompleteTechieDetails = this.getIncompleteTechieDetails.bind(this);
    }
    
    componentDidMount() {

      const baseUrl = window.$baseUrl;
      this.setState({baseUrl})

        //show spinner
        let spinner = "loadercustumspinner";
        let overlay = "loadingcustumspinner";
        this.setState({ spinner, overlay });

        this.getIncompleteTechieDetails();
        // window.onload = function() {
        //     if(!window.location.hash) {
        //         window.location = window.location + '#loaded';
        //         window.location.reload();
        //     }
        // }
        $(document).ready(function () {
            setTimeout(
              function() {
                $('#default-ordering').DataTable( {
                  responsive: true,
                    "oLanguage": {
                        "oPaginate": { "sPrevious": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-left"><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg>', "sNext": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>' },
                        "sInfo": "Showing page _PAGE_ of _PAGES_",
                        "sSearch": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>',
                        "sSearchPlaceholder": "Search...",
                       "sLengthMenu": "Results :  _MENU_",
                    },
                    "order": [[ 0, "asc" ]],
                    "stripeClasses": [],
                    "lengthMenu": [10, 20, 50],
                    "pageLength": 10,
                    drawCallback: function () { $('.dataTables_paginate > .pagination').addClass(' pagination-style-13 pagination-bordered mb-5'); }
                } );
        
              }.bind(this),100);
        });
	}
    
    async getIncompleteTechieDetails() {
        await axiosInstance.get(`getIncompleteTechieDetails`)
            .then(res => {
                if(res.data.status === 'success' && res.data.statusCode ==='200' )
                {
                    let incomtechie_res = res.data.user_details;
                    if(incomtechie_res.length != 0 || incomtechie_res.length != '0')
                    {
                        setTimeout(function(){
                            $( ".dataTables_empty" ).hide();
                        }, 100);

                        
                    }
                    let tech_Details = incomtechie_res.map(techies => { return techies });
                    this.setState({ techies: tech_Details });
                    
                    let remaining_fixers = res.data.remaining_fixers;
                    let spinner = "loadercustumspinner";
                    let overlay = "loadingcustumspinner hide__container";
                    this.setState({ spinner, overlay, remaining_fixers });
                }		 
            })
            .catch(err => console.log('Login: ', err));
    }

      logoutUser(id) {
        swal.fire({
            text: "Are you sure you want to logout this user ?",
            showCancelButton: true,
            confirmButtonColor: '#D90429',
            cancelButtonText: "No",
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.value) {

                //show spinner
                let spinner = "loadercustumspinner";
                let overlay = "loadingcustumspinner";
                this.setState({ spinner, overlay });

                const user = { tech_user_id: id };
                axiosInstance
                  .post(`logoutTechUser`, user)
                  .then((res) => {
                    if ( res.data.status === "success" && res.data.statusCode === "200" )
                    {
                      let spinner = "loadercustumspinner";
                      let overlay = "loadingcustumspinner hide__container";
                      this.setState({ spinner, overlay });

                      let message = res.data.msg;
                      swal.fire({ text: message });
                    }
                    else {
                      let message = res.data.msg;
                      console.log(message);
                      swal.fire({ text: message });
                    }
                  })
                  .catch((err) => console.log("Login: ", err));
            }
        })
      }
render() {

  const SampleExpandedComponent = ({ data }) => (
    <div style={{padding: '1% 5%'}}>
      <div className="expandable-container-col-one col-md-4 pull-left">
        <p>
          <span className="tc_user_lbl">Name</span> 
          <span className="tc_lbl_colon">: </span>
          <span className="tc_lbl_content">{data.user_name}</span>
        </p>
        
        <p>
          <span className="tc_user_lbl">Email</span> 
          <span className="tc_lbl_colon">: </span>
          <span className="tc_lbl_content">{data.user_email}</span>
        </p>
        
        <p>
          <span className="tc_user_lbl">Phone</span> 
          <span className="tc_lbl_colon">: </span>
          <span className="tc_lbl_content">{data.phone_prefix} - {data.user_phone}</span>
        </p>
      
        <p>
          <span className="tc_user_lbl">Company</span> 
          <span className="tc_lbl_colon">: </span>
          <span className="tc_lbl_content">{data.company_name}</span>
        </p>
      </div>
      <div className="expandable-container-col-two col-md-4 pull-left">
        <a class="btn btn-primary btn-rounded" onClick={() => this.logoutUser(data.user_id)}>Logout User</a>
        <a href={'mailto:' + data.user_email} class="btn btn-primary btn-rounded m-l-10" >Send Email</a>
      </div>


    </div>
  );
  
  const columns = [
    {
      name: 'Name',
      selector: 'user_name',
      sortable: true,
      filterable: true,
    },
    {
      name: 'Phone',
      selector: 'user_phone',
      sortable: true,
      filterable: true,
      style: {
        minWidth: '135px'
      }
    },
    {
      name: 'Email',
      // selector: 'rate',
      selector: 'user_email',
      sortable: true,
      filterable: true,
    },
  ];

  const data = this.state.techies;
  const tableData = {
    columns,
    data
  };



    return (
        <div id="content" className="main-content">
            <div className="layout-px-spacing">
                <div className="row layout-top-spacing2 layout-top-spacing">
                    <div className="col-xl-12 col-lg-12 col-sm-12 layout-spacing2  layout-spacing">
                       <div className="widget-content widget-content-area pg-top">
                            <div className="table-responsive mb-4 mt-1">
                                <p className="table-head table-head-no-add">Incomplete Tech Support</p>
                                {/* <table id="default-ordering" className="table-responsive table1 table table-hover dataNewTable">
                                    <thead>
                                        <tr className="">
                                            <th>Name</th>
                                            <th>Phone</th>
                                            <th>Rate</th>
                                            <th>Approval</th>
                                            <th>Negotiate</th>
                                            <th>Nego. Status</th>
                                            <th>Edit Nego.</th>
                                            <th>Edit Commission</th>
                                            <th>Details</th>
                                            <th>Action</th>
                                            <th>Status</th>
                                            <th>Suspend</th>
                                        </tr>
                                    </thead>
                                    <tbody id="tableBodyd">

                                    {this.state.techies.map(techie_details => (
                                    <tr className="" role="row" key={techie_details.user_id}>
                                        <td>{techie_details.user_name}</td>
                                        <td>{techie_details.user_phone}</td>
                                        <td>{techie_details.rate}</td>
                                        <td>
                                            <input type="checkbox" className="form-check-input-switchery-primary"
                                             checked="" data-fouc="" data-switchery="true" style={{display: 'none'}} />
                                            <span className={techie_details.approvalClassName}
                                                  onClick={() => this.changeApprovalStatus(techie_details.user_id, techie_details.approval_status)} >
                                            <small className="smallClassname"></small>
                                            </span>
                                        </td>
                                        
                                        <td>{techie_details.negotiation_amount}</td>
                                        <td>{techie_details.negotiation_status}</td>
                                        <td>
                                            <i className="fa fa-pencil text-success font-18 cursor-pointer" aria-hidden="true" 
                                            onClick={() => this.approveFunction(techie_details.user_id, techie_details.rate, techie_details.tech_user_details_id)}></i>
                                        </td>
                                        <td>
                                            <i className="fa fa-pencil text-success font-18 cursor-pointer" aria-hidden="true" 
                                            onClick={() => this.Commission_amount(techie_details.user_id, techie_details.commission, techie_details.tech_user_details_id)}></i>
                                        </td>
                                        <td className="cursorpointer" onClick={() => this.openTechUserDetailsModal(techie_details.user_id) }>
                                              View
                                        </td>
                                        <td>
                                            <Link to={{ pathname: '/EditTechSupport', search: 'Id='+techie_details.user_id  }} >
                                                <i class="fa fa-pencil text-success font-18" aria-hidden="true"></i>
                                            </Link>
                                            
                                            <i
                                            class="fa fa-trash text-danger font-18 cursorpointer"
                                            aria-hidden="true"
                                            onClick={() =>
                                                this.deleteFucntion(techie_details.user_id)
                                            }
                                            ></i>
                                        </td>
                                        <td>{techie_details.user_active_status}</td>
                                        <td>
                                            {techie_details.suspend_flag == 0 ? (
                                            <p
                                                class="Suspend_user Suspend_user_tech"
                                                onClick={() =>
                                                this.suspendFucntion(techie_details.user_id)
                                                }
                                            >
                                                {" "}
                                                Suspend{" "}
                                            </p>
                                            ) : (
                                            <p
                                                class="unSuspend_user unSuspend_user_tech"
                                                onClick={() =>
                                                this.unsuspendFucntion(techie_details.user_id)
                                                }
                                            >
                                                {" "}
                                                Un-Suspend{" "}
                                            </p>
                                            )}
                                        </td>
                                        
                                    </tr>
                            
                                    ))}
                                        
                                    </tbody>
                                </table> */}
                                
                        <DataTableExtensions {...tableData}
                          print={false}
                          exportHeaders={false}
                          pdf={false}
                          export={false}
                          >
                          <DataTable
                              columns={columns}
                              data={data}
                              noHeader
                              defaultSortAsc={false}
                              pagination
                              highlightOnHover
                              expandableRows={true}
                              expandableRowsComponent={<SampleExpandedComponent />}
                              expandOnRowClicked
                          />
                        </DataTableExtensions>

                            </div>
                        </div>
                    </div>

                </div>

            </div>

            <div className="footer-wrapper">
                <div className="footer-section f-section-1">
                    <p className="">Copyright © 2020 <a target="_blank" href="javascript:void(0);">TSAS</a>, All rights reserved.</p>
                </div>
            </div>
            <div className={this.state.overlay}>
          <div className={this.state.spinner}></div>
        </div>
        </div>
    )
}
}
export default IncompleteTechSupport