import React, {Component} from 'react';
import {axiosInstance} from '../Services/PostData'
import {Link, Redirect} from 'react-router-dom'
import TopBar from '../Component/TopBar/TopBar'
import LeftSideBar from '../Component/LeftSideBar/LeftSideBar'


  class Dashboard extends Component {

    constructor(){
      super();
      this.state = {
            values: [],
          };
      }

    componentDidMount() {
        
        let adminlocalid = window.localStorage.getItem('adminUserLocalIdTSAS');
      if(adminlocalid == undefined || adminlocalid == null) {
         this.setState({loginRedirect: true});
      }
        
       axiosInstance.get(`getDashboard`)
            .then(res => {
                if(res.data.status === 'success' && res.data.statusCode ==='200' )
                {
                  let customerCount = res.data.customer_count;
                  let techSupportCount = res.data.techsupport_count;
                  let superadmin_count = res.data.superadmin_count;
                  let Closedtickets_count = res.data.Closedtickets_count;
                  let activetickets_count = res.data.activetickets_count;
                  let aborttickets_count = res.data.aborttickets_count;
                  let opentickets_count = res.data.opentickets_count;
                    
                  let online_tech_count = res.data.online_tech_count;
                  let active_tech_count = res.data.active_tech_count;
                  let active_customer_count = res.data.active_customer_count;
                  let active_super_admin_count = res.data.active_super_admin_count;
                    
                  this.setState({ customerCount, techSupportCount, superadmin_count, Closedtickets_count, activetickets_count, aborttickets_count, opentickets_count, online_tech_count, active_tech_count, active_customer_count, active_super_admin_count });

                }		 
            })
            .catch(err => console.log('Login: ', err));
 
       axiosInstance.get(`gettoptennotifications`)
            .then(res => {
                if(res.data.status === 'success' && res.data.statusCode ==='200' )
                {
                  let response = res.data.notification_details;
                  let response_Details = response.map((value) => {
                     return value;
                   });
                   this.setState({ values: response_Details });
                }		 
            })
            .catch(err => console.log('Login: ', err));
    }

    render() {
      
        if (this.state.loginRedirect) {
         return <Redirect to="/"/>;
     }
        
    return (
       <div >

         <TopBar />
         <LeftSideBar />
         <div className="main-container" id="container">

            <div className="overlay"></div>
            <div className="search-overlay"></div>
            <div id="content" className="main-content">
            <div className="layout-px-spacing">
               <div className="layout-top-spacing">
        {/* top row start here */}
                  <div className="row">

                  <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12 layout-spacing">
                     <Link to="/TechSupport">
                     <div className="widget widget-five">
                        <div className="widget-content">
                           <div className="header">
                              <div className="header-body">
                                 <h6 className="meta-date">Online Fixers</h6>
                              </div>
                              <div className="task-action">
                                 <div className="dropdown  custom-dropdown">
                                    <a className="dropdown-toggle" href="#" role="button" id="pendingTask" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                       <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-more-horizontal">
                                          <circle cx="12" cy="12" r="1"></circle>
                                          <circle cx="19" cy="12" r="1"></circle>
                                          <circle cx="5" cy="12" r="1"></circle>
                                       </svg>
                                    </a>
                                 </div>
                              </div>
                           </div>
                           <div className="w-content">
                              <div className="">
                                 <p className="task-left">{this.state.online_tech_count}</p>
                                    <div className="row">
                                    <div className="col-md-12 my_pad"><p className="task-completed"><span>Total Fixers<br /> {this.state.techSupportCount} </span></p></div>
                                 </div> 
                              </div>
                           </div>
                        </div>
                     </div>
                     </Link>
                  </div>

                  <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12 layout-spacing">
                     <Link to="/TechSupport">
                     <div className="widget widget-five">
                        <div className="widget-content">
                           <div className="header">
                              <div className="header-body">
                                 <h6 className="meta-date">Active Fixers</h6>
                              </div>
                              <div className="task-action">
                                 <div className="dropdown  custom-dropdown">
                                    <a className="dropdown-toggle" href="#" role="button" id="pendingTask" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                       <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-more-horizontal">
                                          <circle cx="12" cy="12" r="1"></circle>
                                          <circle cx="19" cy="12" r="1"></circle>
                                          <circle cx="5" cy="12" r="1"></circle>
                                       </svg>
                                    </a>
                                 </div>
                              </div>
                           </div>
                           <div className="w-content">
                              <div className="">
                                 <p className="task-left">{this.state.active_tech_count}</p>
                                    <div className="row">
                                    <div className="col-md-12 my_pad"><p className="task-completed"><span>Total Fixers<br /> {this.state.techSupportCount} </span></p></div>
                                 </div> 
                              </div>
                           </div>
                        </div>
                     </div>
                     </Link>
                  </div>

                  <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12 layout-spacing">
                     <Link to="/Customer">
                     <div className="widget widget-five">
                        <div className="widget-content">
                           <div className="header">
                              <div className="header-body">
                                 <h6 className="meta-date">Customer</h6>
                              </div>
                              <div className="task-action">
                                 <div className="dropdown  custom-dropdown">
                                    <a className="dropdown-toggle" href="#" role="button" id="pendingTask" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                       <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-more-horizontal">
                                          <circle cx="12" cy="12" r="1"></circle>
                                          <circle cx="19" cy="12" r="1"></circle>
                                          <circle cx="5" cy="12" r="1"></circle>
                                       </svg>
                                    </a>
                                    {/* <div className="dropdown-menu dropdown-menu-right" aria-labelledby="pendingTask">
                                       <a className="dropdown-item" href="javascript:void(0);">View</a>
                                    </div> */}
                                 </div>
                              </div>
                           </div>
                           <div className="w-content">
                              <div className="">
                                 <p className="task-left">{this.state.customerCount}</p>
                                    <div className="row">
                                    <div className="col-md-12 my_pad"><p className="task-completed"><span>Online User<br /> {this.state.active_customer_count} </span></p></div>
                                    {/* <div className="col-md-6 my_pad"><p className="task-hight-priority"><span>Inactive User<br /> 2</span></p></div>*/}
                                 </div> 
                              </div>
                           </div>
                        </div>
                     </div>
                     </Link>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12 layout-spacing">
                     <Link to="/PrivateFixes">
                     <div className="widget widget-five">
                        <div className="widget-content">
                           <div className="header">
                              <div className="header-body">
                                 <h6 className="meta-date">Active Fixes</h6>
                              </div>
                              <div className="task-action">
                                 <div className="dropdown  custom-dropdown">
                                    <a className="dropdown-toggle" href="#" role="button" id="pendingTask" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                       <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-more-horizontal">
                                          <circle cx="12" cy="12" r="1"></circle>
                                          <circle cx="19" cy="12" r="1"></circle>
                                          <circle cx="5" cy="12" r="1"></circle>
                                       </svg>
                                    </a>
                                    {/* <div className="dropdown-menu dropdown-menu-right" aria-labelledby="pendingTask">
                                       <a className="dropdown-item" href="javascript:void(0);">View</a>
                                    </div> */}
                                 </div>
                              </div>
                           </div>
                           <div className="w-content">
                              <div className="">
                                 <p className="task-left">{this.state.activetickets_count}</p>
                                    {/* <div className="row">
                                    <div className="col-md-12 my_pad"><p className="task-completed"><span>Online User<br /> {this.state.active_super_admin_count} </span></p></div>
                                 </div>  */}
                              </div>
                           </div>
                        </div>
                     </div>
                     </Link>
                  </div>
                     
                  </div>
        
                  <div className="row">
                     <div className="col-md-8">
                        <div className="row">
                        
                        
                           
                        
                        </div>



                        {/* fixes start here */}

                     <div class="col-xl-12 col-lg-12 col-md-6 col-sm-12 col-12 layout-spacing layout-spacing-cust-style ">
                        <div class="widget widget-table-one">
                            <div class="widget-heading">
                                <h5 class="">Fixes Details</h5>
                            </div>

                            <div class="widget-content">
                                <div class="transactions-list">
                                    <div class="t-item">
                                        <div class="t-company-name">
                                            <div class="t-icon">
                                                <div class="avatar avatar-xl">
                                                    <span class="avatar-title rounded-circle">OF</span>
                                                </div>
                                            </div>
                                            <div class="t-name tname-custum">
                                                <h4>Open  Fixes</h4>
                                            </div>
                                            <div class="t-name">
                                                <h4>Count : {this.state.opentickets_count}</h4>
                                            </div>
                                        </div>
                                        <div class="t-rate rate-inc">
                                          <div className="action-btn  align_center">
                                             <Link to="/OpenFixes" className="usr-location cusorPointer">View All</Link>
                                          </div>
                                            {/* <p><span>+$66.44</span> <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-up"><line x1="12" y1="19" x2="12" y2="5"></line><polyline points="5 12 12 5 19 12"></polyline></svg></p> */}
                                        </div>
                                    </div>
                                </div>


                                <div class="transactions-list">
                                    <div class="t-item">
                                        <div class="t-company-name">
                                            <div class="t-icon">
                                                <div class="avatar avatar-xl">
                                                    <span class="avatar-title rounded-circle">AF</span>
                                                </div>
                                            </div>
                                            <div class="t-name tname-custum">
                                                <h4>Active  Fixes</h4>
                                            </div>
                                            <div class="t-name">
                                                <h4>Count : {this.state.activetickets_count}</h4>
                                            </div>
                                        </div>
                                        <div class="t-rate rate-inc">
                                          <div className="action-btn align_center">
                                             <Link to="/PrivateFixes" className="usr-location cusorPointer">View All</Link>
                                          </div>
                                            {/* <p><span>+$66.44</span> <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-up"><line x1="12" y1="19" x2="12" y2="5"></line><polyline points="5 12 12 5 19 12"></polyline></svg></p> */}
                                        </div>
                                    </div>
                                </div>

                                <div class="transactions-list">
                                    <div class="t-item">
                                        <div class="t-company-name">
                                            <div class="t-icon">
                                                <div class="avatar avatar-xl">
                                                    <span class="avatar-title rounded-circle">CF</span>
                                                </div>
                                            </div>
                                            <div class="t-name tname-custum">
                                                <h4>Closed  Fixes</h4>
                                            </div>
                                            <div class="t-name ">
                                                <h4>Count : {this.state.Closedtickets_count}</h4>
                                            </div>
                                        </div>
                                        <div class="t-rate rate-inc">
                                          <div className="action-btn  align_center">
                                             <Link to="/ClosedFixes" className="usr-location cusorPointer">View All</Link>
                                          </div>
                                            {/* <p><span>+$66.44</span> <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-up"><line x1="12" y1="19" x2="12" y2="5"></line><polyline points="5 12 12 5 19 12"></polyline></svg></p> */}
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="transactions-list">
                                    <div class="t-item">
                                        <div class="t-company-name">
                                            <div class="t-icon">
                                                <div class="avatar avatar-xl">
                                                    <span class="avatar-title rounded-circle">AF</span>
                                                </div>
                                            </div>
                                            <div class="t-name tname-custum">
                                                <h4>Aborted  Fixes</h4>
                                            </div>
                                            <div class="t-name ">
                                             <h4>Count : {this.state.aborttickets_count}</h4>
                                            </div>
                                        </div>
                                        <div class="t-rate rate-inc">
                                          <div className="action-btn align_center">
                                             <Link to="/AbortedFixes" className="usr-location cusorPointer">View All</Link>
                                          </div>
                                            {/* <p><span>+$66.44</span> <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-up"><line x1="12" y1="19" x2="12" y2="5"></line><polyline points="5 12 12 5 19 12"></polyline></svg></p> */}
                                        </div>
                                    </div>
                                </div>
                            </div>                            
                        </div>
                    </div>

                      
                      </div>

                      <div className="col-md-4">
                        <div className="widget widget-activity-four">
                           <div className="widget-heading">
                              <h5 className="pt-4">Notitfication</h5>
                           </div>

                           <div className="widget-content">
                           <div className="mt-container mx-auto ps ps--active-y">
                           {this.state.values.map((value) => (
                              <div className="timeline-line">
                                 <div className="item-timeline timeline-primary">
                                    <div className="t-dot" data-original-title="" title="">
                                    </div>
                                    <div className="t-text">
                                       <p class="cut-text-two">{value.message}</p>                                                
                                       <p className="t-time">{value.date} {value.time}</p>
                                    </div>
                                 </div>
                              </div> 

                            ))}

                              <div className="noti_btn_center tm-action-btn mb-2 pb-4">
                              <Link to="/Notification"> <button className="btn mb-3">View All <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-chevron-down"><polyline points="6 9 12 15 18 9"></polyline></svg></button></Link>
                              </div> 
                           </div>
                           </div>
                        </div>
                     </div>
                    </div>
                </div>
            </div>
            </div>
          </div>
       </div>
    )
}
  }

  
export default Dashboard