import React, { Component } from "react";
import $ from "jquery";
import "react-confirm-alert/src/react-confirm-alert.css";
import { axiosInstance } from "../../Services/PostData";
import DataTable from 'react-data-table-component';
import { Link } from "react-router-dom";
import DataTableExtensions from "react-data-table-component-extensions";
import { confirmAlert } from "react-confirm-alert";
import swal from 'sweetalert2';

class CompTechSupportFeedback extends Component {
  constructor() {
    super();
    this.state = {
      values: [],
    };
    this.getFeedbackDetails = this.getFeedbackDetails.bind(this);
    this.onChange = this.onChange.bind(this);
    this.submitFeedback = this.submitFeedback.bind(this);
  }

  componentDidMount() {
    this.getFeedbackDetails();
  }

  onChange(e) {
		this.setState({[e.target.name]: e.target.value});
    }

    submitFeedback()
    {
      if(this.state.feedback == "") {
        this.setState({negotiationError: 'Feedback should not be blank'})
      }
      else {
        this.setState({negotiationError: ''})
        let spinner = "loadercustumspinner";
        let overlay = "loadingcustumspinner";
        this.setState({ spinner, overlay });

        const feedback = {
            feedback_id: this.state.feedback_id,
            feedback: this.state.feedback
          };
         // console.log(feedback);

       axiosInstance.post(`updatetechsupportFeedback`, feedback)
        .then(res => {
        if(res.data.status === 'success' && res.data.statusCode ==='200' )
        {
          let spinner = "loadercustumspinner";
          let overlay = "loadingcustumspinner hide__container";
          this.setState({ spinner, overlay });          
            $('#faderightModal').hide();
            window.location.reload();

        }
        })
        .catch(err => console.log('Login: ', err));
    }     
   
    }

    deleteFucntion(id) {
      confirmAlert({
        message: "Are you sure to do this.",
        buttons: [
          {
            label: "Yes",
            onClick: () => {
  
              //show spinner 
              let spinner = 'lds-dual-ring custom-screen-middle';
              let overlay = 'overlay';
              this.setState({ spinner, overlay });
  
              const feedback = { feedback_id: id };
              axiosInstance
                .post(`deletetechsupportFeedback`, feedback)
                .then((res) => {
                  if (res.data.status === "success" && res.data.statusCode === "200" ) {
  
                    let spinner = 'lds-dual-ring custom-screen-middle hide__container';
                      let overlay = 'overlay hide__container';
                      this.setState({ spinner, overlay });
  
                      swal.fire({
                          type: "info",
                          text: 'Deleted Successfuly',
                          confirmButtonText: "ok",
                          confirmButtonColor: "#D82B2A",
                      })
                      .then((result) => {
                          window.location.reload(false);
                      });
                  }
                })
                .catch((err) => console.log("Login: ", err));
            },
          },
          {
            label: "No",
          },
        ],
      });
    }  

  async getFeedbackDetails() {

    //show spinner
    let spinner = "loadercustumspinner";
    let overlay = "loadingcustumspinner";
    this.setState({ spinner, overlay });

    await axiosInstance
      .get(`getTechSupportFeedback`)
      .then((res) => {
        if(res.data.status === "success" && res.data.statusCode === "200") {

            //HIDE SPINNER
          let spinner = "loadercustumspinner";
          let overlay = "loadingcustumspinner hide__container";
          this.setState({ spinner, overlay });

            let response = res.data.feedback_details;
            for(let i=0; i < response.length; i++) {

                let chat_time = response[i].timestamp;
                if(chat_time != '') {
                let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                let timestamp_convert = this.convertTZ(chat_time, timeZone);
                let chat_time_response = this.getNowTimeDate(timestamp_convert);
                response[i].timestamp = chat_time_response;
                }
            }

          let response_Details = response.map((value) => { return value; });
          this.setState({ values: response_Details });

          
        }
        else if(res.data.status === "fail" && res.data.statusCode === "400") {
            //HIDE SPINNER
            let spinner = "loadercustumspinner";
            let overlay = "loadingcustumspinner hide__container";
            this.setState({ spinner, overlay });
        }
      })
      .catch((err) => console.log("Login: ", err));
  }

  
  editFunction(id,feedbacki)
  {
    this.state.feedback_id = id;
    this.setState({negotiationError: ''})
    let feedback = feedbacki;
    this.setState({ feedback });
    $('#faderightModal').show();
  }

  getNowTimeDate(timestamp) {

    //get date
    let dd = timestamp.getDate();
    let mm = timestamp.getMonth()+1; 
    let yyyy = timestamp.getFullYear();
    if(dd<10)  {
        dd='0'+dd;
    } 
    if(mm<10)  {
        mm='0'+mm;
    } 
    let today = dd+'/'+mm+'/'+yyyy;
    return today;

    // //get timing
    // let now_hour = timestamp.getHours();
    // let now_min = timestamp.getMinutes();
    // let now_ampm = now_hour >= 12 ? 'pm' : 'am';
    // now_hour = now_hour % 12;
    // now_hour = now_hour ? now_hour : 12; // the hour '0' should be '12'
    // now_min = now_min < 10 ? '0'+now_min : now_min;
    // let strTime = now_hour + ':' + now_min + ' ' + now_ampm;
    
    // let date_and_time = today +" - " + strTime;
    // return date_and_time;

}

closeModalHere() {
  $('#faderightModal').hide();
}


convertTZ(date, tzString) {
    return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {timeZone: tzString}));   
}


  render() {

    const columns = [
      {
        name: 'Tech Name',
        selector: 'user_name',
        sortable: true,
        filterable: true,
      },
      {
        name: 'Date',
        selector: 'timestamp',
        sortable: true,
        filterable: true,
      },
      {
        name: 'Feedback Message',
        selector: 'feedback',
        sortable: true,
        filterable: true,
      },
      {
        name: 'Action',
        selector: (row, index) => {
          return <>
 
                      <i class="fa fa-pencil text-success font-18 cusorPointer" aria-hidden="true" onClick={() => this.editFunction(row.feedback_id, row.feedback)} ></i>&nbsp;&nbsp;

                      <i class="fa fa-trash text-danger font-18 cusorPointer" aria-hidden="true" onClick={() => this.deleteFucntion(row.feedback_id)}  ></i>

                </>
        }
      }
      
    ];

    const data = this.state.values;
    const tableData = {
      columns,
      data
    };


    return (
      <div id="content" class="main-content">
        <div class="layout-px-spacing">
          <div class="row layout-top-spacing2 layout-top-spacing">
            <div class="col-xl-12 col-lg-12 col-sm-12 layout-spacing2  layout-spacing">
              <div class="widget-content widget-content-area pg-top">
                <div class="table-responsive mb-4 mt-1">
                  <div className="top_heading_sty">
                    <p className="table-head w-30-p">Tech Support Feedback</p>
                  </div>

                <DataTableExtensions {...tableData}
                    print={false}
                    exportHeaders={false}
                    pdf={false}
                    export={false}
                    >
                    <DataTable
                        columns={columns}
                        data={data}
                        noHeader
                        defaultSortAsc={false}
                        pagination
                        highlightOnHover
                        // expandableRows={true}
                        // expandableRowsComponent={<SampleExpandedComponent />}
                        // expandOnRowClicked
                    />
                </DataTableExtensions>

                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={this.state.overlay}>
          <div className={this.state.spinner}></div>
        </div>
        <div id="faderightModal" className="modal animated fadeInRight custo-fadeInRight show" role="dialog" aria-modal="true">
                <div className="modal-dialog-content modal-dialog" style={{width: '35%'}}>
                    <div className="modal-background-color modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">FeedBack</h5>
                    </div>
                        <div className="modal-body">
                            <div>
                                <div className="">
                                    <div className="row">
                                        <form style={{width: '100%'}}>
                                        <div className="form-group">
                                            <label for="exampleFormControlInput1">Feedback Details</label>
                                            <textarea className="form-control" id="" name="feedback" value={this.state.feedback} onChange={this.onChange}> </textarea>
                                        </div> 
                                        <div  className="m-b-5 m-t-15" style={{ fontSize: 12, fontWeight: "bold", color: "#d82b2a" }}>
                                            {this.state.negotiationError}
                                        </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer md-button">
                            <button className="btn" data-dismiss="modal" onClick={this.closeModalHere}><i className="flaticon-cancel-12"></i> Close</button>
                            <button className="btn" data-dismiss="modal" onClick={this.submitFeedback}><i className="flaticon-cancel-12"></i> Submit</button>
                        </div>
                    </div>
                </div>
            </div>        
      </div>
    );
  }
}

export default CompTechSupportFeedback;
