import $ from "jquery";
import React, { Component } from "react";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Link } from "react-router-dom";
import { axiosInstance } from "../../Services/PostData";
import './CompBanner.css';
import DataTable from 'react-data-table-component';
import DataTableExtensions from "react-data-table-component-extensions";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import swal from 'sweetalert2';

class CompBanner extends Component {

    constructor() {
        super();
        this.state = {
          values: [],
        };
        this.getAvatarDetails = this.getAvatarDetails.bind(this);
      }
    
      componentDidMount() {
        //show spinner
        let spinner = "loadercustumspinner";
        let overlay = "loadingcustumspinner";
        this.setState({ spinner, overlay });
        this.getAvatarDetails();
        
      }
    
      async getAvatarDetails() {
        await axiosInstance
          .get(`getBanner`)
          .then((res) => {
            if (res.data.status === "success" && res.data.statusCode === "200") {
              let response = res.data.banner_details;
              
              let response_Details = response.map((value) => {
                return value;
              });
              this.setState({ values: response_Details });
    
              //HIDE SPINNER
              let spinner = "loadercustumspinner";
              let overlay = "loadingcustumspinner hide__container";
              this.setState({ spinner, overlay });
            }
          })
          .catch((err) => console.log("Login: ", err));
      }
    
        deleteFucntion(id) {
            confirmAlert({
              message: "Are you sure you want to delete this banner.",
              buttons: [
                {
                  label: "Yes",
                  onClick: () => {

                    //show spinner 
                    let spinner = 'lds-dual-ring custom-screen-middle';
                    let overlay = 'overlay';
                    this.setState({ spinner, overlay });

                    const user = { id: id };
                    axiosInstance
                      .post(`deleteBanner`, user)
                      .then((res) => {
                        if (res.data.status === "success" && res.data.statusCode === "200" ) {

                          let spinner = 'lds-dual-ring custom-screen-middle hide__container';
                            let overlay = 'overlay hide__container';
                            this.setState({ spinner, overlay });

                            swal.fire({
                                type: "info",
                                text: 'Deleted Successfuly',
                                confirmButtonText: "ok",
                                confirmButtonColor: "#D82B2A",
                            })
                            .then((result) => {
                                window.location.reload(false);
                            });
                        }
                      })
                      .catch((err) => console.log("Login: ", err));
                  },
                },
                {
                  label: "No",
                },
              ],
            });
      }
    
      render() {

        const columns = [
          {
            name: 'Banner Title',
            selector: 'cus_banner_title',
            sortable: true,
            filterable: true,
          },
          {
            name: 'Image',
            selector: (row, index) => {
              return <img src={ "https://api.touchtofix.com/demo/sanyaa-api/uploads/banner/" + row.cus_banner_url }
                          class="navbar-logooo" alt="" />
            },
            sortable: true,
            filterable: true,
          },
          {
            name: 'Action',
            selector: (row, index) => {
              return <>
                        <Link to={{ pathname: "/AddBanner", search: "cus_banner_id=" + row.cus_banner_id, }} >
                          <i class="fa fa-pencil text-success font-18" aria-hidden="true" ></i>
                        </Link>
                        <Link onClick={() => this.deleteFucntion(row.cus_banner_id)} className="mar-l-20">
                            <i class="fa fa-trash text-danger font-18" aria-hidden="true" ></i>
                        </Link>
                      </>
            },
            sortable: true,
            filterable: true,
          }
        ];
    
        const data = this.state.values;
        const tableData = {
          columns,
          data
        };

        
        return (
          <div id="content" class="main-content">
            <div class="layout-px-spacing">
              <div class="row layout-top-spacing2 layout-top-spacing">
                <div class="col-xl-12 col-lg-12 col-sm-12 layout-spacing2  layout-spacing">
                  <div class="widget-content widget-content-area pg-top">
                    <div class="table-responsive mb-4 mt-1">
                      <div className="top_heading_sty">
                        <p className="table-head">Banner Master</p>
                        <div class="d-flex Fixes_addbtn justify-content-sm-end justify-content-center add_btn_sty">
                          <Link to="/AddBanner" className="add_btn_icon_style">
                            Add New
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              class="feather feather-plus-square"
                            >
                              <rect
                                x="3"
                                y="3"
                                width="18"
                                height="18"
                                rx="2"
                                ry="2"
                              ></rect>
                              <line x1="12" y1="8" x2="12" y2="16"></line>
                              <line x1="8" y1="12" x2="16" y2="12"></line>
                            </svg>
                            <a></a>
                          </Link>
                        </div>
                      </div>
    
                      {/* <table id="default-ordering" class="table1 table table-hover">
                        <thead>
                          <tr class="">
                            <th>Banner Title</th>
                            <th>Image</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.values.map((value) => (
                            <tr key={value.cus_banner_id}>
                              <td>{value.cus_banner_title}</td>
                              <td>
                               <img
                                  src={
                                    "https://api.touchtofix.com/demo/sanyaa-api/uploads/banner/" +
                                    value.cus_banner_url
                                  }
                                  class="navbar-logooo"
                                  alt=""
                                />
                              </td>
                              <td>
                                <Link
                                  to={{
                                    pathname: "/AddBanner",
                                    search: "cus_banner_id=" + value.cus_banner_id,
                                  }}
                                >
                                  <i
                                    class="fa fa-pencil text-success font-18"
                                    aria-hidden="true"
                                  ></i>
                                </Link>
    
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table> */}

                            <DataTableExtensions {...tableData}
                                print={false}
                                exportHeaders={false}
                                pdf={false}
                                export={false}
                                >
                                <DataTable
                                    columns={columns}
                                    data={data}
                                    noHeader
                                    defaultSortAsc={false}
                                    pagination
                                    highlightOnHover
                                    // expandableRows={true}
                                    // expandableRowsComponent={<SampleExpandedComponent />}
                                    // expandOnRowClicked
                                />
                            </DataTableExtensions>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div class="footer-wrapper">
                <div class="footer-section f-section-1">
                    <p class="">Copyright © 2020 <a target="_blank" href="javascript:void(0);">TSAS</a>, All rights reserved.</p>
                </div>
            </div> */}
    
            <div className={this.state.overlay}>
              <div className={this.state.spinner}></div>
            </div>
          </div>
        );
      }
}

export default CompBanner