import React, { useEffect, useState } from 'react';
import { useLocation, Redirect} from 'react-router-dom';
import AddCompany from './AddCompany';
import swal from 'sweetalert2';

// # axiosInstance
import { axiosInstance } from '../../../Services/PostData';

export default function EditCompany(props){

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const companyId = atob(params.get('id'));
    let [error,setError] = useState(false);
    let [companyDetails,setCompanyDetails] = useState({});

    const suggestionMessage = (type, title, message, mode) => {
        swal.fire({
            type: type,
            title: title,
            text: message,
            confirmButtonText: "ok",
            confirmButtonColor: "#D82B2A",
        })
    }

    useEffect(() => {
        axiosInstance.post(`getCompanyDetails`, {companyId})
        .then(res => {
            if(res.data.statusCode ==='200')
            {
                setCompanyDetails(res.data.company_details[0]);
            }
            else if(res.data.statusCode ==='404')
            {
                suggestionMessage('info', 'error', res.data.msg, '');
                setTimeout(() => {
                    setError(true);
                }, 2000);
            }
            else if(res.data.statusCode ==='400'){
                suggestionMessage('info', 'error', res.data.msg, '');
                setTimeout(() => {
                    setError(true);
                }, 2000);
            }
        })
        .catch(err => { console.log(err); });
    },[]);

    if(error) {
        return <Redirect to="/Company" />;
    }else{
        return (
            <>
                <div className="loadingcustumspinner"></div>
                <AddCompany pageTitle="Edit" companyData={companyDetails || []}/>
            </>
        );
    }
}