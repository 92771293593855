import $ from "jquery";
import React, { Component } from "react";
import { confirmAlert } from 'react-confirm-alert';
import "react-confirm-alert/src/react-confirm-alert.css";
import { Link } from 'react-router-dom';
import { axiosInstance } from "../../../Services/PostData";
import "./UserCustomer.css";
import DataTable from 'react-data-table-component';
import DataTableExtensions from "react-data-table-component-extensions";
import swal from 'sweetalert2';

class UserCustomer extends Component {
  constructor() {
    super();
    this.state = {
      techies: [],
      CustomerDetails: [],
      aprovalUserId: "",
      amount: "",
      techie_details_id: "",
    };
    this.getTechieDetails = this.getTechieDetails.bind(this);
    // this.submitNegotiateRate = this.submitNegotiateRate.bind(this);
    // this.onChange = this.onChange.bind(this);
    // this.approveFunction = this.approveFunction.bind(this);
    this.openUserDetailsModal = this.openUserDetailsModal.bind(this);
    this.payForCustomer = this.payForCustomer.bind(this);
    this.submitCustomerPendingAmount = this.submitCustomerPendingAmount.bind(this);
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.closePaymentModal = this.closePaymentModal.bind(this);
  }

  componentDidMount() {
    //show spinner
    let spinner = "loadercustumspinner";
    let overlay = "loadingcustumspinner";
    this.setState({ spinner, overlay });
    this.getTechieDetails();
    $(document).ready(function () {
      setTimeout(
        function() {
          $('#default-ordering').DataTable( {
              "oLanguage": {
                  "oPaginate": { "sPrevious": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-left"><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg>', "sNext": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>' },
                  "sInfo": "Showing page _PAGE_ of _PAGES_",
                  "sSearch": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>',
                  "sSearchPlaceholder": "Search...",
                 "sLengthMenu": "Results :  _MENU_",
              },
              "order": [[ 0, "asc" ]],
              "stripeClasses": [],
              "lengthMenu": [10, 20, 50],
              "pageLength": 10,
              drawCallback: function () { $('.dataTables_paginate > .pagination').addClass(' pagination-style-13 pagination-bordered mb-5'); }
          } );
  
        }.bind(this),100);
  });
  }

  async getTechieDetails() {
    await axiosInstance
      .get(`getCustomers`)
      .then((res) => {
        if (res.data.status === "success" && res.data.statusCode === "200") {
          let techie_res = res.data.user_details;
          if (techie_res.length != 0 || techie_res.length != "0") {
            setTimeout(function () {
              $(".dataTables_empty").hide();
            }, 100);
          }
          let tech_Details = techie_res.map((techies) => {
            return techies;
          });
          this.setState({ techies: tech_Details });
          let spinner = "loadercustumspinner";
          let overlay = "loadingcustumspinner hide__container";
          this.setState({ spinner, overlay });
        }
      })
      .catch((err) => console.log("Login: ", err));
  }

  deleteFucntion(id) {
    confirmAlert({
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            const user = { user_id: id };
            axiosInstance
              .post(`deletetechsupport`, user)
              .then((res) => {
                if (
                  res.data.status === "success" &&
                  res.data.statusCode === "200"
                ) {
                  window.location.reload();
                }
              })
              .catch((err) => console.log("Login: ", err));
          },
        },
        {
          label: "No",
        },
      ],
    });
  }

  suspendFucntion(id) {
    confirmAlert({
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            //show spinner
            let spinner = "loadercustumspinner";
            let overlay = "loadingcustumspinner";
            this.setState({ spinner, overlay });
            const user = { user_id: id };
            axiosInstance
              .post(`Suspendtechsupport`, user)
              .then((res) => {
                if (
                  res.data.status === "success" &&
                  res.data.statusCode === "200"
                ) {
                  let spinner = "loadercustumspinner";
                  let overlay = "loadingcustumspinner hide__container";
                  this.setState({ spinner, overlay });
                  window.location.reload();
                }
              })
              .catch((err) => console.log("Login: ", err));
          },
        },
        {
          label: "No",
        },
      ],
    });
  }

  unsuspendFucntion(id) {
    confirmAlert({
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            //show spinner
            let spinner = "loadercustumspinner";
            let overlay = "loadingcustumspinner";
            this.setState({ spinner, overlay });
            const user = { user_id: id };
            axiosInstance
              .post(`UnSuspendtechsupport`, user)
              .then((res) => {
                if (
                  res.data.status === "success" &&
                  res.data.statusCode === "200"
                ) {
                  let spinner = "loadercustumspinner";
                  let overlay = "loadingcustumspinner hide__container";
                  this.setState({ spinner, overlay });
                  window.location.reload();
                }
              })
              .catch((err) => console.log("Login: ", err));
          },
        },
        {
          label: "No",
        },
      ],
    });
  }


  openUserDetailsModal(id) {
    $('#UserDetails').show();
    const user = { user_id: id };
    axiosInstance.post(`getCustomerUserDetails`, user)
    .then(res => {
    if(res.data.status === 'success' && res.data.statusCode ==='200' )
    {
          this.setState({CustomerDetailsAvailable: true})
          let customer_res = res.data.user_details;
          let customer_user_Details = customer_res.map(user_details => { return user_details });
          this.setState({ CustomerDetails: customer_user_Details });

          let spinner = "loadercustumspinner";
          let overlay = "loadingcustumspinner hide__container";
          this.setState({ spinner, overlay });
    }
    else {
      this.setState({CustomerDetailsAvailable: false})
    }

    })
    .catch(err => console.log('Login: ', err));

  }
  
  closeUserDetailsModal() {
    $('#UserDetails').hide();
  }

    
  payForCustomer(user_id, payment_id, amount) {

    let spinner = "loadercustumspinner";
    let overlay = "loadingcustumspinner";
    this.setState({ spinner, overlay });

    $('#amountPayableModal').show();
    this.setState({customer_user_id: user_id, customer_payment_id: payment_id, customer_pending_amount: amount});

    spinner = "loadercustumspinner";
    overlay = "loadingcustumspinner hide__container";
    this.setState({ spinner, overlay });
  }

  onChangeHandler(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  submitCustomerPendingAmount() {

    if(this.state.customer_paying_amount == "") {
        this.setState({pendingAmountError: 'Amount should not be blank'});
    }
    else if(parseInt(this.state.customer_paying_amount) > parseInt(this.state.customer_pending_amount)) {
        this.setState({pendingAmountError: 'Amount is greater than pending amount'});
    }
    else {

      this.setState({pendingAmountError: ''})

      let spinner = "loadercustumspinner";
      let overlay = "loadingcustumspinner";
      this.setState({ spinner, overlay });

      const jsonValue = { 
        customer_user_id: this.state.customer_user_id,
        amount: this.state.customer_paying_amount
      };
      axiosInstance.post(`updateCustomerPendingAamount`, jsonValue)
      .then(res => {
      if(res.data.status === 'success' && res.data.statusCode ==='200' )
      {
            let spinner = "loadercustumspinner";
            let overlay = "loadingcustumspinner hide__container";
            this.setState({ spinner, overlay });

            swal.fire({
              type: "info",
              text: res.data.msg,
              confirmButtonText: "ok",
              confirmButtonColor: "#D82B2A",
            })
            .then((result) => {
                $('#amountPayableModal').hide();
                window.location.reload();
            });
      }
      else {
            let spinner = "loadercustumspinner";
            let overlay = "loadingcustumspinner hide__container";
            this.setState({ spinner, overlay });

            swal.fire({
              type: "info",
              text: res.data.msg,
              confirmButtonText: "ok",
              confirmButtonColor: "#D82B2A",
            })
            .then((result) => {
              $('#amountPayableModal').hide();
                window.location.reload();
            });
      }

      })
      .catch(err => console.log('Login: ', err));
    }
  }

  closePaymentModal() {
    this.setState({pendingAmountError: "", customer_paying_amount: ""});
    $('#amountPayableModal').hide();
    
  }
    
  render() {

    const SampleExpandedComponent = ({ data }) => (
      <div style={{padding: '1% 5%'}}>
        <div className="expandable-container-col-one col-md-4 pull-left">
        <p>
          <span className="tc_user_lbl">Name</span> 
          <span className="tc_lbl_colon">: </span>
          <span className="tc_lbl_content">{data.user_name}</span>
        </p>
        
        <p>
          <span className="tc_user_lbl">Email</span> 
          <span className="tc_lbl_colon">: </span>
          <span className="tc_lbl_content">{data.user_email}</span>
        </p>
        
        <p>
          <span className="tc_user_lbl">Phone</span> 
          <span className="tc_lbl_colon">: </span>
          <span className="tc_lbl_content">{data.phone_prefix} - {data.user_phone}</span>
        </p>
      </div>
        
      <div className="expandable-container-col-one col-md-4 pull-left">
        <p>
          <span className="tc_user_lbl w-30-p">Pending Amount</span> 
          <span className="tc_lbl_colon">: </span>
          <span className="tc_lbl_content">{data.pending_amount}</span>
        </p>
      </div>
        
      <div className="expandable-container-col-two col-md-4 pull-left">
        {data.pending_amount === "0.00" || data.pending_amount === "0" ?
          null
          :
          <a class="btn btn-primary btn-rounded m-l-10" 
            onClick={() => this.payForCustomer(data.user_id, data.payment_user_id, data.pending_amount) } >Pay Now</a>
        }
        <a href={'mailto:' + data.user_email} class="btn btn-primary btn-rounded m-l-10" >Send Email</a>
      </div>
    </div>
    );
    

    const columns = [
      {
        name: 'Name',
        selector: 'user_name',
        sortable: true,
        filterable: true,
      },
      {
        name: 'Email',
        selector: 'user_email',
        sortable: true,
        filterable: true,
      },
      {
        name: 'Phone Number',
        selector: 'user_full_phone',
        sortable: true,
        filterable: true,
      },
      {
        name: 'Gender',
        selector: 'user_gender',
        sortable: true,
        filterable: true,
      },
      {
        name: 'Status',
        selector: 'user_active_status',
        sortable: true,
        filterable: true,
      },
      {
        name: 'Details',
        selector: (row, index) => {
            return <p class="cursorpointer" onClick={() => this.openUserDetailsModal(row.user_id) } >  View </p>
        },
        sortable: true,
        filterable: true,
      },
      {
        name: 'Suspend / Un-suspend',
        selector: (row, index) => {
          if(row.suspend_flag == 0) {
            return <p class="Suspend_user" onClick={() => this.suspendFucntion(row.user_id) } > Suspend </p>
          }
          else {
            return <p class="unSuspend_user" onClick={() => this.unsuspendFucntion(row.user_id) } >  Un-Suspend </p>
          }
        },
        sortable: true,
        filterable: true,
      },
      {
        name: 'Action',
        selector: (row, index) => {
            return  <div>
                      <Link to={{ pathname: '/EditCustomer', search: 'Id='+row.user_id  }} >
                        <i class="fa fa-pencil text-success font-18" aria-hidden="true"></i>
                      </Link>
                      <i class="fa fa-trash text-danger font-18 cursorpointer m-l-10" aria-hidden="true"
                      onClick={() => this.deleteFucntion(row.user_id) } ></i>
                    </div>;
          
        },
        sortable: true,
        filterable: true,
      }
    ];

    const data = this.state.techies;
    const tableData = {
      columns,
      data
    };

    return (
      <div id="content" className="main-content">
        <div className="layout-px-spacing">
          <div className="row layout-top-spacing2 layout-top-spacing">
            <div className="col-xl-12 col-lg-12 col-sm-12 layout-spacing2  layout-spacing">
              <div className="widget-content widget-content-area pg-top">
                <div className="table-responsive mb-4 mt-1">
                  <p className="table-head table-head-no-add">Customer</p>
                  
                  {/* <table
                    id="default-ordering"
                    className="table1 table table-hover dataNewTable"
                  >
                    <thead>
                      <tr className="">
                        <th>Name</th>
                        <th>Email</th>
                        <th>Phone Number</th>
                        <th>Gender</th>
                        <th>Suspend / Un-suspend</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody id="tableBodyd">
                      {this.state.techies.map((techie_details) => (
                        <tr
                          className=""
                          role="row"
                          key={techie_details.user_id}
                        >
                          <td>{techie_details.user_name}</td>
                          <td>{techie_details.user_email}</td>
                          <td>{techie_details.user_phone}</td>
                          <td>{techie_details.user_gender}</td>
                          <td>
                              {techie_details.suspend_flag == 0 ? (
                              <p
                                  class="Suspend_user"
                                  onClick={() =>
                                  this.suspendFucntion(techie_details.user_id)
                                  }
                              >
                                  {" "}
                                  Suspend{" "}
                              </p>
                              ) : (
                              <p
                                  class="unSuspend_user"
                                  onClick={() =>
                                  this.unsuspendFucntion(techie_details.user_id)
                                  }
                              >
                                  {" "}
                                  Un-Suspend{" "}
                              </p>
                              )}
                          </td>
                          <td>
                              <Link to={{ pathname: '/EditCustomer', search: 'Id='+techie_details.user_id  }} >
                                  <i class="fa fa-pencil text-success font-18" aria-hidden="true"></i>
                              </Link>
                              <i
                              class="fa fa-trash text-danger font-18 cursorpointer"
                              aria-hidden="true"
                              onClick={() =>
                                  this.deleteFucntion(techie_details.user_id)
                              }
                              ></i>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table> */}

                <DataTableExtensions {...tableData}
                    print={false}
                    exportHeaders={false}
                    pdf={false}
                    export={false}
                    >
                    <DataTable
                        columns={columns}
                        data={data}
                        noHeader
                        defaultSortAsc={false}
                        pagination
                        highlightOnHover
                        expandableRows={true}
                        expandableRowsComponent={<SampleExpandedComponent />}
                        expandOnRowClicked
                    />
                </DataTableExtensions>

                </div>
              </div>
            </div>
          </div>
        </div>

       
       
        <div id="UserDetails" className="modal animated fadeInRight custo-fadeInRight show" role="dialog" aria-modal="true">
                <div className="modal-dialog-content modal-dialog" style={{width: '55%'}}>
                    <div className="modal-background-color modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">FixCustomer Details</h5>
                    </div>
                        <div className="modal-body">

                        {this.state.CustomerDetailsAvailable ? 
                        <div>
                        {this.state.CustomerDetails.map(user_details => ( 
                        <div>
                            <p>
                              <span className="tc_user_lbl">Name</span> 
                              <span className="tc_lbl_colon">: </span>
                              <span className="tc_lbl_content">{user_details.user_name}</span>
                            </p>
                            <p>
                              <span className="tc_user_lbl">Email</span> 
                              <span className="tc_lbl_colon">: </span>
                              <span className="tc_lbl_content">{user_details.user_email}</span>
                            </p>
                            <p>
                              <span className="tc_user_lbl">Phone Number</span> 
                              <span className="tc_lbl_colon">: </span>
                              <span className="tc_lbl_content">{user_details.user_full_phone}</span>
                            </p>
                            <p>
                              <span className="tc_user_lbl">Gender</span> 
                              <span className="tc_lbl_colon">: </span>
                              <span className="tc_lbl_content">{user_details.user_gender}</span>
                            </p>
                            <p>
                              <span className="tc_user_lbl">Country</span> 
                              <span className="tc_lbl_colon">: </span>
                              <span className="tc_lbl_content">{user_details.user_country}</span>
                            </p>
                            <p>
                              <span className="tc_user_lbl">Suspend Status</span> 
                              <span className="tc_lbl_colon">: </span>
                              <span className="tc_lbl_content">{user_details.user_suspend_status}</span>
                            </p>
                        </div> 
                        ))}
                        </div>
                        :
                        <p>Some details not available for this Customer.</p>
                        }
                            
                        </div>
                        <div className="modal-footer md-button">
                            <button className="btn" data-dismiss="modal" onClick={this.closeUserDetailsModal}>
                              <i className="flaticon-cancel-12"></i> Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>

<div id="amountPayableModal" className="modal animated fadeInRight custo-fadeInRight show" role="dialog" aria-modal="true">
  <div className="modal-dialog-content modal-dialog" style={{width: '35%'}}>
      <div className="modal-background-color modal-content">
      <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">Pay Amount</h5>
      </div>
          <div className="modal-body">
              <div>
                  <div className="">
                      <div className="row">
                          <form style={{width: '100%'}}>
                          <div className="form-group">
                              <label for="exampleFormControlInput1">
                                  Pending Amount : {this.state.customer_pending_amount} 
                              </label>
                          </div> 
                          <div className="form-group">
                              <label for="exampleFormControlInput1">Enter amount to pay </label>
                              <input type="text" className="form-control" id="" name="customer_paying_amount" placeholder="Enter Amount" onChange={this.onChangeHandler} />
                          </div> 
                          <div  className="m-b-5 m-t-15" style={{ fontSize: 12, fontWeight: "bold", color: "#d82b2a" }}>
                              {this.state.pendingAmountError}
                          </div>
                          </form>
                      </div>
                  </div>
              </div>
          </div>
          <div className="modal-footer md-button">
              <button className="btn" data-dismiss="modal" onClick={this.closePaymentModal}><i className="flaticon-cancel-12"></i> Close</button>
              <button className="btn" data-dismiss="modal" onClick={this.submitCustomerPendingAmount}><i className="flaticon-cancel-12"></i> Submit</button>
          </div>
      </div>
  </div>
</div>



        <div className="footer-wrapper">
          <div className="footer-section f-section-1">
            <p className="">
              Copyright © 2020{" "}
              <a target="_blank" href="javascript:void(0);">
                TSAS
              </a>
              , All rights reserved.
            </p>
          </div>
        </div>
        <div className={this.state.overlay}>
          <div className={this.state.spinner}></div>
        </div>
      </div>
    );
  }
}
export default UserCustomer;
