import React from 'react';

import TopBar from '../../Component/TopBar/TopBar'
import LeftSideBar from '../../Component/LeftSideBar/LeftSideBar'
import UserTechSupport from '../../Component/Users/TechSupport/UserTechSupport'

export default function TechSupport() {
  return (
        <div >
            <TopBar />
            <LeftSideBar />
            <div class="main-container" id="container">

                <div class="overlay"></div>
                <div class="search-overlay"></div>

                <UserTechSupport />
            </div>
       </div>
    )
}