import React, { Component } from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Link, Redirect } from 'react-router-dom';
import swal from 'sweetalert2';
import { axiosInstance } from '../../../Services/PostData';
import './AvatarAdd.css';

class CompSkills extends Component {

    constructor(){
		super();
		this.state = {
            gender: '',
            country_values: [],
            hiddenClassName: '',
            image_file: null,
            image_preview: '',
        };
		this.submitFormHandler = this.submitFormHandler.bind(this);
		this.onChangeHandler = this.onChangeHandler.bind(this);
    }
    
    componentDidMount() {

        let query = window.location.search.substring(1);
        if(query != '') {
            let pair = query.split("=");
            var queryString = pair[0];
            var queryValue = pair[1];
            this.getUserDetailss(queryString, queryValue);
        }
     }

     

    getUserDetailss(value, id) {
    //show spinner
    let spinner = "loadercustumspinner";
    let overlay = "loadingcustumspinner";
    this.setState({ spinner, overlay });
        const jsonValue = { id: id};

        axiosInstance.post(`getAvatarDetails`, jsonValue)
        .then(res => {
        if(res.data.status === 'success' && res.data.statusCode ==='200' )
        {
            let gender   = res.data.avatar_details[0].gender;
            let image_file = res.data.avatar_details[0].image_name;
            let avatar_id = res.data.avatar_details[0].avatar_id;

            // alert('radioRoleBtn'+admin_role_id)
            this.setState({gender, image_file, avatar_id, edit_status: true});
            //HIDE SPINNER
            let spinner = "loadercustumspinner";
            let overlay = "loadingcustumspinner hide__container";
            this.setState({ spinner, overlay });
        }
        })
        .catch(err => console.log('Login: ', err));
    }

    onChangeHandler(e) {
		this.setState({[e.target.name]: e.target.value})
	}

    validateFunction = () => {
        let genderError = "";
        let imageError = "";

        if (!this.state.gender) {
			genderError = "Choose Gender";
		}
        
        if (this.state.image_file == null) {
			imageError = "Select Image";
		}

		if (genderError, imageError ) {
		  this.setState({genderError, imageError});
		  return false;
		}
	
		return true;
    }
    
    // Image Preview Handler
    handleImagePreview = (e) => {
        let image_as_base64 = URL.createObjectURL(e.target.files[0])
        let image_as_files = e.target.files[0];

        this.setState({
            image_preview: image_as_base64,
            image_file: image_as_files,
        })
    }

    submitFormHandler() {
        const isValid = this.validateFunction();

        if(isValid) {
            //show spinner
            let spinner = "loadercustumspinner";
            let overlay = "loadingcustumspinner";
            this.setState({ spinner, overlay });

            let query = window.location.search.substring(1);

             //New submit (Mode = Update )
            if(query != '') {
                let formData = new FormData();
                if(this.state.image_file == null)
                {
                    this.state.image_file = '';
                }
                formData.append('gender', this.state.gender);
                formData.append('imageFile', this.state.image_file);
                formData.append('id', this.state.avatar_id);

                axiosInstance.post(`editAvatar`, formData)
                .then(res => {
                if(res.data.status === 'success' && res.data.statusCode ==='200' )
                {
                    //HIDE SPINNER
                    let spinner = "loadercustumspinner";
                    let overlay = "loadingcustumspinner hide__container";
                    this.setState({ spinner, overlay });
                    
                    swal
                    .fire({
                      type: "info",
                      title: "Updated Successfuly",
                      // text: 'do you want to continuew',
                      confirmButtonText: "ok",
                      confirmButtonColor: "#D82B2A",
                    })
                    .then((result) => {
                      this.setState({ AvatarRedirect: true });
                    });
                }
                })
                .catch(err => console.log('Login: ', err));
            }
            //New submit (Mode = New )
            else {
                let formData = new FormData();
                if(this.state.image_file == null)
                {
                    this.state.image_file = '';
                }
                formData.append('gender', this.state.gender);
                formData.append('imageFile', this.state.image_file);

                axiosInstance.post(`addAvatar`, formData)
                .then(res => {
                if(res.data.status === 'success' && res.data.statusCode ==='200' )
                {
                    //HIDE SPINNER
                    let spinner = "loadercustumspinner";
                    let overlay = "loadingcustumspinner hide__container";
                    this.setState({ spinner, overlay });
                    
                    swal
                    .fire({
                      type: "info",
                      title: "Inserted Successfuly",
                      // text: 'do you want to continuew',
                      confirmButtonText: "ok",
                      confirmButtonColor: "#D82B2A",
                    })
                    .then((result) => {
                      this.setState({ AvatarRedirect: true });
                    });
                    


                    //this.setState({AvatarRedirect: true});
                }
                })
                .catch(err => console.log('Login: ', err));
            }

        }
    }

render() {

    if (this.state.AvatarRedirect) {
        return <Redirect to="/Avatar"/>;
    }
    return (
        <div id="content" className="main-content">
            
            <div className="container formcustom" >
                <div className="container">
                    <div className="row layout-top-spacing" id="cancel-row">
                        <div  id="ftFormArray" className="col-lg-12 layout-spacing">
                            <div className="statbox widget box box-shadow">
                                <div className="widget-header">                                
                                    <div className="row">
                                        <div className="col-xl-12 col-md-12 col-sm-12 col-12">
                                            <h4>Avatar</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="widget-content widget-content-area custom-autocomplete h-100">
                                <form id="formid">
                                    
                                    <div className="form-group">
                                        <label style={{marginBottom: '0px'}} for="country"><b>Gender</b></label>
                                        <div style={{padding: '10px'}} className="widget-content widget-content-area">
                                            <div className="custom-control custom-radio custom-control-inline">
                                                <input type="radio" value="male"
                                                       id="male"
                                                       onChange={this.onChangeHandler}
                                                       name="gender" 
                                                       checked={this.state.gender === 'male'}
                                                       className="custom-control-input" />
                                                <label className="custom-control-label" for="male">
                                                    Male
                                                </label>
                                            </div>

                                            <div className="custom-control custom-radio custom-control-inline">
                                                <input type="radio" value="female"
                                                       id="female"
                                                       onChange={this.onChangeHandler}
                                                       name="gender" 
                                                       checked={this.state.gender === 'female'}
                                                       className="custom-control-input" />
                                                <label className="custom-control-label" for="female">
                                                    Female
                                                </label>
                                            </div>
                                            
                                            <div className="custom-control custom-radio custom-control-inline">
                                                <input type="radio" value="others"
                                                       id="others"
                                                       onChange={this.onChangeHandler}
                                                       name="gender" 
                                                       checked={this.state.gender === 'others'}
                                                       className="custom-control-input" />
                                                <label className="custom-control-label" for="others">
                                                Others
                                                </label>
                                            </div>
                                        </div>
                                        <span className="m-b-5 m-t-15 frm-err-msg">{this.state.genderError}</span>
                                    </div>

                                    <div className="form-group">
                                        <label style={{marginBottom: '0px'}} for="country"><b>Choose Avatar</b></label>
                                            <br />
                                            <input type="file" 
                                                    name="image_url" 
                                                    onChange={this.handleImagePreview} />
                                            <br />
                                            {this.state.edit_status ? 
                                                // <img src={'http://localhost/main-folder/projects/tsas-sanyaa/uploads/avatar/'+ this.state.image_file}
                                                //     class="navbar-logo" alt="" />
                                                     <img src={'https://api.touchtofix.com/demo/sanyaa-api/uploads/avatar/'+ this.state.image_file}
                                                                                        class="navbar-logo" alt="" /> 
                                                :
                                                null
                                            }
                                        
                                    </div>
                                    <span className="m-b-5 m-t-15 frm-err-msg">{this.state.imageError}</span>
                                    
                                 
                                    <div className="m-b-5 m-t-15 frm-err-msg text-center">{this.state.messageError}</div>
                                    <p onClick={() => this.submitFormHandler()} className="ml-1 fr btn btn-primary">Submit</p>
                                    <Link to="/Avatar"className="fr btn btn-danger">Cancel</Link>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className="footer-wrapper">
                <div className="footer-section f-section-1">
                    <p className="">Copyright © 2020 <Link target="_blank" href="javascript:void(0);">TSAS</Link>, All rights reserved.</p>
                </div>
            </div> */}

            <div className={this.state.overlay}>
              <div className={this.state.spinner}></div>
            </div>

        </div>
    )
}
}
export default CompSkills