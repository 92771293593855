import $ from 'jquery';
import React, { Component } from "react";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Link, Redirect } from "react-router-dom";
import swal from 'sweetalert2';
import { axiosInstance } from "../../../Services/PostData";
import "./EditTechForm.css";
import Select from 'react-select';
import Switch from "react-switch";

const colourOptions = [];

class EditTechSupport extends Component {
  constructor() {
    super();
    this.state = {
      user_name: "",
      user_phone: "",
      user_email: "",
      skillset: "",
      admin_password: "",
      confirm_password: "",
      optionsState: "",
      role_values: [],
      country_values: [],
      proof_fixers: [],
      certificate_fixers: [],
      hiddenClassName: "",
      user_id:"",
      redirecturl: "/TechSupport",
      imageFile: '',
      image_file: null,
      proof_name: ''
    };
    this.submitFormHandler = this.submitFormHandler.bind(this);
    this.getCountryPrefix = this.getCountryPrefix.bind(this);
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.getSkillDetails = this.getSkillDetails.bind(this);
    this.getFixerSkills = this.getFixerSkills.bind(this);
    this.submitSkillEdit = this.submitSkillEdit.bind(this);
    this.OpenProofDetailsFunction = this.OpenProofDetailsFunction.bind(this);
    this.handleImagePreview = this.handleImagePreview.bind(this);
    this.handleCertificateImagePreview = this.handleCertificateImagePreview.bind(this);
    this.submitProfileEdit = this.submitProfileEdit.bind(this);
    this.OpenCertificateDetailsFunction = this.OpenCertificateDetailsFunction.bind(this);
    this.deleteCertificateFunction = this.deleteCertificateFunction.bind(this);
    this.submitCertificate = this.submitCertificate.bind(this);
    this.handleSwitchChangee = this.handleSwitchChangee.bind(this);
  }

  componentDidMount() {

    const baseUrl = window.$baseUrl;
    this.setState({baseUrl})


    //show spinner
    let spinner = "loadercustumspinner";
    let overlay = "loadingcustumspinner";
    this.setState({ spinner, overlay });
    let query = window.location.search.substring(1);
    if (query != "") {
      let pair = query.split("=");
      var queryString = pair[0];
      var queryValue = pair[1];
      this.getUserDetailss(queryString, queryValue);
      this.getSkillCategoryy(queryString, queryValue);
    }
    
    this.getCountryDetails();
  }

  // getSkillCategoryy() {
  //   axiosInstance
  //     .get(`getSkillCategory_byid`)
  //     .then((res) => {
  //       if (res.data.status === "success" && res.data.statusCode === "200") {
  //         let roleResponse = res.data.skill_details;
  //         let role_response_Details = roleResponse.map((value) => {
  //           return value;
  //         });
  //         this.setState({ role_values: role_response_Details });
  //       }
  //     })
  //     .catch((err) => console.log(err));
  // }


  getSkillCategoryy(value, id) {
    //show spinner
    let spinner = "loadercustumspinner";
    let overlay = "loadingcustumspinner";
    this.setState({ spinner, overlay });
    const jsonValue = { user_id: id };
    axiosInstance
      .post(`getSkillCategory_byid`, jsonValue)
      .then((res) => {
        if (res.data.status === "success" && res.data.statusCode === "200") {
          
          let roleResponse = res.data.user_details;
          let role_response_Details = roleResponse.map((value) => {
            return value;
          });
          this.setState({ role_values: role_response_Details });
        }
      })
      .catch((err) => console.log("Login: ", err));
  }




  getCountryDetails() {
    axiosInstance
      .get(`getCountry`)
      .then((res) => {
        if (res.data.status === "success" && res.data.statusCode === "200") {
          let countryResponse = res.data.country_details;
          let country_response_Details = countryResponse.map((value) => {
            return value;
          });
          this.setState({ country_values: country_response_Details });
          this.state.mobile_number_count = country_response_Details[0].mobile_length;
          this.state.optionsState = country_response_Details[0].prefix;
          let spinner = "loadercustumspinner";
          let overlay = "loadingcustumspinner hide__container";
          this.setState({ spinner, overlay });
        }
      })
      .catch((err) => console.log(err));
  }

  getCountryPrefix(e) {
    this.state.optionsState = e.target.value;
    this.state.mobile_number_count = e.target.selectedOptions[0].getAttribute(
      "data-length"
    );

    // alert(this.state.mobile_number_count);
    // console.log(this.state.optionsState);
  }

  getUserDetailss(value, id) {
    //show spinner
    let spinner = "loadercustumspinner";
    let overlay = "loadingcustumspinner";

    this.setState({ spinner, overlay });
    const jsonValue = { user_id: id };
    axiosInstance
      .post(`gtetechsupportdetails`, jsonValue)
      .then((res) => {
        if (res.data.status === "success" && res.data.statusCode === "200") {
          let hiddenClassName = "hide__container";
          let user_id = res.data.user_details[0].user_id;
          let user_name = res.data.user_details[0].user_name;
          let user_email = res.data.user_details[0].user_email;
          let user_phone = res.data.user_details[0].user_phone;
          let optionsState = res.data.user_details[0].phone_prefix;
          let skillset = res.data.user_details[0].skillset;
          let skills_category_name = res.data.user_details[0].skills_category_name;
          let mobile_number_count = res.data.user_details[0].mobile_length;
          let company_enable_status = res.data.user_details[0].company_enable_status;
          let redirecturl = "/TechSupport";
            
          if(company_enable_status == 1){
              this.setState({ checked: true});
              document.getElementById("cpmnyStatus").classList.add('activeswitchclass');
          }
          else {
              this.setState({ checked: false});
              document.getElementById("cpmnyStatus").classList.remove('activeswitchclass');
          }

          // alert('radioRoleBtn'+admin_role_id)
          this.setState({
            hiddenClassName,
            user_id,
            user_name,
            user_email,
            user_phone,
            optionsState,
            redirecturl,
            skillset,
            skills_category_name,
            mobile_number_count
          });

          let spinner = "loadercustumspinner";
          let overlay = "loadingcustumspinner hide__container";
          this.setState({ spinner, overlay });
          
        }
      })
      .catch((err) => console.log("Login: ", err));
  }
  onChangeHandler(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  validateFunction() {
    let nameError = "";
    let phoneError = "";
    let emailError = "";
    let passwordError = "";
    let confirmPasswordError = "";
    let roleError = "";

    if (!this.state.user_name) {
      nameError = "Name cannot be blank";
    }
    if (!this.state.user_phone) {
      phoneError = "Enter Mobile Number";
    } else if (
      this.state.user_phone.length != this.state.mobile_number_count
    ) {
      phoneError =
        "Your mobile number should be " +
        this.state.mobile_number_count +
        " Digit";
    }

    if (!this.state.user_email) {
      emailError = "Email cannot be blank";
    }

    // password
    const strongRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
    );
    if (this.state.admin_password.length < 8) {
      passwordError = "Password should be 8 characters";
    } else if (!strongRegex.test(this.state.admin_password)) {
      passwordError = "use special character, number and capital letter";
    }
    //confirm password
    if (this.state.admin_password !== this.state.confirm_password) {
      confirmPasswordError = "Wrong password";
    }


    if (
      nameError ||
      phoneError ||
      emailError ||
      passwordError ||
      confirmPasswordError ||
      roleError
    ) {
      this.setState({
        nameError,
        phoneError,
        emailError,
        passwordError,
        confirmPasswordError,
        roleError,
      });
      return false;
    }

    return true;
  }

  editValidateFunction() {
    let nameError = "";
    let phoneError = "";
    let emailError = "";
    let roleError = "";
    let skillsetError = "";

    if (!this.state.user_name) {
      nameError = "Name cannot be blank";
    }
    if (!this.state.user_phone) {
      phoneError = "Enter Mobile Number";
    } 
    else if (this.state.user_phone.length != this.state.mobile_number_count ) {
      phoneError =
        "Your mobile number should be " +
        this.state.mobile_number_count +
        " Digit";
    }

    if (!this.state.user_email) {
      emailError = "Email cannot be blank";
    }

    if (!this.state.skillset) {
      skillsetError = "Skill set cannot be blank";
    }


    if (nameError || phoneError || emailError || roleError || skillsetError) {
      this.setState({ nameError, phoneError, emailError, roleError, skillsetError });
      return false;
    }

    return true;
  }

  submitFormHandler() {
    let query = window.location.search.substring(1);

    if (query != "") {
      const isValid = this.editValidateFunction();
      if (isValid) {
        //show spinner
        let spinner = "loadercustumspinner";
        let overlay = "loadingcustumspinner";
        this.setState({ spinner, overlay });

        //  update submit (Mode = Update )
        const jsonValue = {
          user_id: this.state.user_id,
          name: this.state.user_name,
          email: this.state.user_email,
          phone: this.state.user_phone,
          prefix: this.state.optionsState,
          skillset: this.state.skillset,
        };

        // console.log(jsonValue);
        axiosInstance
          .post(`updateTechSupportDetails`, jsonValue)
          .then((res) => {
            if (
              res.data.status === "success" &&
              res.data.statusCode === "200"
            ) {
              let spinner = "loadercustumspinner";
              let overlay = "loadingcustumspinner hide__container";
              this.setState({ spinner, overlay });
              swal
                .fire({
                  type: "info",
                  title: "Updated Successfuly",
                  // text: 'do you want to continuew',
                  confirmButtonText: "ok",
                  confirmButtonColor: "#D82B2A",
                })
                .then((result) => {
                  this.setState({ userRedirect: true });
                });
            } else if (
              res.data.status === "success" &&
              res.data.statusCode === "201"
            ) {
              let messageError = res.data.msg;
              this.setState({ messageError });
            }
          })
          .catch((err) => console.log("Login: ", err));
      }
    } 
    
  }

  //  Skill details starts here 
  openTechUserDetailsModal(id) {
    $('#TechUserDetails').show();

    this.getSkillDetails(id);
    this.getFixerSkills(id);
        
  }
  
  closeTechUserDetailsModal() {
    $('#TechUserDetails').hide();
  }

  async getSkillDetails(id) {
    const body = { user_id: id };
    axiosInstance.post(`getUserSkillsDetails`, body)
    .then(res => {
       if(res.data.status == 'success' && res.data.statusCode =='200' )
       {	

        let skill_details = res.data.skill_details;
        let skillDetails = skill_details.map(skill_category => { return skill_category });
        this.setState({ skills: skillDetails });

        colourOptions.push(skill_details);

        this.setState({dropdownloaded: true})
       }
    })
    .catch(err => console.log(err));
  }

  getFixerSkills(id) {
    const body = { user_id: id };
        
    axiosInstance.post(`getFixerSkills`, body)
    .then(res => {
       if(res.data.status == 'success' && res.data.statusCode =='200' )
       {	
            let default_values = res.data.skill_details[0].skill;
            this.setState({default_values})
            this.setState({skill: default_values})
       }
    })
    .catch(err => console.log('Signup: ', err));

    this.setState({techDetailsAvailable: true})
  }

  handleChange = (selectedOptions) => {
    // console.log(selectedOptions)
    //console.log(selectedOptions[0].skill_category)

    const options_1 = [];
    for(var i=0; i < selectedOptions.length; i++) {
        options_1.push(selectedOptions[i].value)
    }

    let def_value = this.state.default_values;
    let deflt_arr = def_value.split(',')

    for(var j=0; j < deflt_arr.length; j++) {
        options_1.push(deflt_arr[j])
    }

    var x_skills = options_1.toString();

    //console.log(deflt_arr)
    this.setState({skill: x_skills})

  }
    

  submitSkillEdit() {

    //show spinner 
    let spinner = 'lds-dual-ring custom-screen-middle';
    let overlay = 'overlay';
    this.setState({ spinner, overlay });

    const body = { user_id: this.state.user_id, skill: this.state.skill };

    axiosInstance.post(`updateUserSkills`, body)
    .then(res => {
    if(res.data.status === 'success' && res.data.statusCode ==='200' )
    {
        //hide spinner
        let spinner = 'lds-dual-ring custom-screen-middle hide__container';
        let overlay = 'overlay hide__container';
        this.setState({ spinner, overlay, userRedirect: true });

    }
    else if(res.data.statusCode ==='400' ) {
        
    }
    })
    .catch(err => console.log('Login: ', err));
    
}


// Proof details starts here ******************************

OpenProofDetailsFunction(id) {
  $("#proofDetailsModal").show();

      const body = { tech_user_id: id };
      axiosInstance.post(`getFixerProof`, body)
      .then(res => {
         if(res.data.status == 'success' && res.data.statusCode =='200' )
         {	
              if(res.data.proof_details.length == 0) {
                  this.setState({noCertificateClass: true})
              }
              let certificate_details = res.data.proof_details;
              let CertificateDetails = certificate_details.map(certificate => {
                  return certificate
              });
              this.setState({ proof_fixers: CertificateDetails });
              this.setState({ proofDetailsAvailable: true });
         }
         else {
          if(res.data.proof_details.length == 0) {
              this.setState({proofDetailsAvailable: false})
          }
         }
      })
      .catch(err => console.log('Signup: ', err));;
}

deleteFunction(id) {
  swal.fire({
      text: "Are you sure delete this Proof",
      showCancelButton: true,
      confirmButtonColor: '#D90429',
      confirmButtonText: 'Yes',
      cancelButtonText: "Cancel"
  }).then((result) => {
      if (result.value) {

           //show spinner 
          let spinner = 'lds-dual-ring custom-screen-middle';
          let overlay = 'overlay';
          this.setState({ spinner, overlay });

          const body = { fixer_proof_id: id };
          axiosInstance.post(`deleteFixerProof`, body)
          .then(res => {
          if(res.data.status === 'success' && res.data.statusCode ==='200' )
          {
              //hide spinner
              let spinner = 'lds-dual-ring custom-screen-middle hide__container';
              let overlay = 'overlay hide__container';
              this.setState({ spinner, overlay });
              this.setState({ userRedirect: true  });
          }
          else {

          }
          
          })
          .catch(err => console.log('Login: ', err));
      }
  });
}

addProofDetails() {
  this.CloseProofDetailsFunction();
  this.openAddProofDetailsModal();
}

openAddProofDetailsModal() {
  $("#addProofDetailsModal").show();
}

closeAddProofDetailsModal() {
  $("#addProofDetailsModal").hide();
}

CloseProofDetailsFunction() {
  $("#proofDetailsModal").hide();
}

// Image Preview Handler (proof)
handleImagePreview = (e) => {
  let image_as_base64 = URL.createObjectURL(e.target.files[0])
  let image_as_files = e.target.files[0];

  this.setState({
      image_preview: image_as_base64,
      image_file: image_as_files,
  })
}


submitProfileEdit() {

if(this.state.proof_name == '') {
  this.setState({prrof_name_error: "Proof name should not be blank"})
}
else if (this.state.image_file !== '' && this.state.imageFile !== undefined && this.state.image_file !== null) {
 
  this.setState({imageError: "", prrof_name_error: ""})

  let errorMessageresponse = '';
  let formData = new FormData();
  formData.append('imageFile', this.state.image_file);
  formData.append('proof_name', this.state.proof_name);
  formData.append('tech_user_id',  this.state.user_id);

  //show spinner 
  let spinner = 'lds-dual-ring custom-screen-middle';
  let overlay = 'overlay';
  this.setState({ spinner, overlay });

  axiosInstance.post(`addFixerProof`, formData)
  .then(res => {
  if(res.data.status === 'success' && res.data.statusCode ==='200' )
  {
      //hide spinner
      let spinner = 'lds-dual-ring custom-screen-middle hide__container';
      let overlay = 'overlay hide__container';
      this.setState({ spinner, overlay });
      this.setState({ userRedirect: true  });
  }
  else if(res.data.statusCode ==='400' ) {
      errorMessageresponse = res.data.msg
      this.setState({ errorMessageresponse});
  }
  })
  .catch(err => console.log('Login: ', err));
}
else {
  this.setState({imageError: "Please Choose Proof", prrof_name_error: ""})
}

}


// Certificate details of tech user *********************************

addCertificateDetails() {
  this.CloseCertificateDetailsFunction();
  this.openAddCertificateDetailsModal();
}

openAddCertificateDetailsModal() {
  $("#addCertificateDetailsModal").show();
}

closeAddCertificateDetailsModal() {
  $("#addCertificateDetailsModal").hide();
}

CloseCertificateDetailsFunction() {
  $("#certificateDetailsModal").hide();
}

OpenCertificateDetailsFunction(id) {
  $("#certificateDetailsModal").show();

      const body = { tech_user_id: id };
      axiosInstance.post(`getFixerCertificate`, body)
      .then(res => {
         if(res.data.status == 'success' && res.data.statusCode =='200' )
         {	
              let certificate_details = res.data.certificate_details;
              let CertificateDetails = certificate_details.map(certificate => {
                  return certificate
              });
              this.setState({ certificate_fixers: CertificateDetails });
              this.setState({ certificateDetailsAvailable: true });
         }
         else {
          if(res.data.certificate_details.length == 0) {
              this.setState({certificateDetailsAvailable: false})
          }
         }
      })
      .catch(err => console.log(err));;
}

deleteCertificateFunction(id) {
  swal.fire({
      text: "Are you sure delete this certificate",
      showCancelButton: true,
      confirmButtonColor: '#D90429',
      confirmButtonText: 'Yes',
      cancelButtonText: "Cancel"
  }).then((result) => {
      if (result.value) {

           //show spinner 
          let spinner = 'lds-dual-ring custom-screen-middle';
          let overlay = 'overlay';
          this.setState({ spinner, overlay });

          const body = { tech_certificate_id: id };
          axiosInstance.post(`deleteFixerCertificate`, body)
          .then(res => {
          if(res.data.status === 'success' && res.data.statusCode ==='200' )
          {
              //hide spinner
              let spinner = 'lds-dual-ring custom-screen-middle hide__container';
              let overlay = 'overlay hide__container';
              this.setState({ spinner, overlay });
              this.setState({ userRedirect: true  });
          }
          else {

          }
          
          })
          .catch(err => console.log('Login: ', err));
      }
  });
}
// imageCertificateError

// Image Preview Handler (Certificate)
handleCertificateImagePreview = (e) => {
  let image_as_base64 = URL.createObjectURL(e.target.files[0])
  let image_as_files = e.target.files[0];

  this.setState({
      image_preview_certificate: image_as_base64,
      image_file_certificate: image_as_files,
  })
}


submitCertificate() {

  if (this.state.image_file_certificate !== '' && this.state.image_file_certificate !== undefined && this.state.image_file_certificate !== null) {
   
    this.setState({imageCertificateError: ""})
  
    let errorMessageresponse = '';
    let formData = new FormData();
    formData.append('imageFile', this.state.image_file_certificate);
    formData.append('tech_user_id',  this.state.user_id);
  
    //show spinner 
    let spinner = 'lds-dual-ring custom-screen-middle';
    let overlay = 'overlay';
    this.setState({ spinner, overlay });
  
    axiosInstance.post(`addFixerCertificate`, formData)
    .then(res => {
    if(res.data.status === 'success' && res.data.statusCode ==='200' )
    {
        //hide spinner
        let spinner = 'lds-dual-ring custom-screen-middle hide__container';
        let overlay = 'overlay hide__container';
        this.setState({ spinner, overlay });
        this.setState({ userRedirect: true  });
    }
    else if(res.data.statusCode ==='400' ) {
        errorMessageresponse = res.data.msg
        this.setState({ errorMessageresponse});
    }
    })
    .catch(err => console.log('Login: ', err));
  }
  else {
    this.setState({imageCertificateError: "Please Choose Proof"})
  }
  
  }

handleSwitchChangee(checked) {
    this.setState({ checked });
    let enable_status = 0;
    if(checked == true) {
        enable_status = 1;
        document.getElementById("cpmnyStatus").classList.add('activeswitchclass');
    }
    else {
        enable_status = 0;
        document.getElementById("cpmnyStatus").classList.remove('activeswitchclass');
    }

    const body = {
      tech_user_id: this.state.user_id,
      company_enable_status: enable_status
    };

    this.setState({ showSpinner: true });
    axiosInstance.post(`updateCompanyEnableStatus`, body)
    .then(res => {
    if(res.data.status === 'success' && res.data.statusCode ==='200' )
    {
        this.setState({ showSpinner: false });
    }
    else {
        //this.setState({ showSpinner: false });
    }
    })
    .catch(err => console.log(err));
  }


  render() {
    if (this.state.userRedirect) {
      return <Redirect to={this.state.redirecturl} />;
    }

    // console.log(this.state.admin_role_id);
    return (
      <div id="content" className="main-content">
        <div className="container formcustom">
          <div className="container">
            <div className="row layout-top-spacing" id="cancel-row">
              <div id="ftFormArray" className="col-lg-12 layout-spacing">
                <div className="statbox widget box box-shadow">
                  <div className="widget-header">
                    <div className="row">
                      <div className="col-xl-12 col-md-12 col-sm-12 col-12">
                      <h4>Edit Tech Support User</h4>
                      <div class="col-md-12 col-12 mb-2">
                          <label class="mb-1">Enable Company for Fixer search</label>
                          <div className="customSwitchBtn" id="cpmnyStatus" style={{float: 'right'}}>
                          <Switch onChange={this.handleSwitchChangee} 
                                  checked={this.state.checked} 
                                  handleDiameter={14} />
                          </div>
                      </div>
                      </div>
                    </div>
                  </div>
                  <div className="widget-content widget-content-area custom-autocomplete h-100">
                    <form id="formid">
                      <div className="form-group mb-4">
                        <label for="user_name">Name</label>
                        <input
                          type="text"
                          onChange={this.onChangeHandler}
                          className="form-control"
                          id="user_name"
                          value={this.state.user_name}
                          name="user_name"
                          placeholder="Name"
                        />
                        <span className="m-b-5 m-t-15 frm-err-msg">
                          {this.state.nameError}
                        </span>
                      </div>

                      <div className="form-group">
                        <label for="country">Phone Number</label>
                        <div className="input-group mb-4">
                          <div className="selected-flag">
                            {/* <select onChange={this.getCountryPrefix}>
                                            {this.state.country_values.map(team => (
                                                <option key={team.country_id} data-length={team.mobile_length} value={team.prefix} > {team.prefix} </option>
                                            ))}
                                            </select> */}

                            <select
                              value={this.state.optionsState}
                              name="e_country"
                              onChange={(e) =>
                                this.setState({ optionsState: e.target.value , mobile_number_count : e.target.selectedOptions[0].getAttribute("data-length")})
                              }
                              autoComplete="off"
                            >
                              {this.state.country_values.map((team) => (
                                <option
                                  key={team.country_id}
                                  data-length={team.mobile_length}
                                  value={team.prefix}
                                >
                                  {" "}
                                  {team.prefix}{" "}
                                </option>
                              ))}
                            </select>
                          </div>
                          <input
                            type="text"
                            onChange={this.onChangeHandler}
                            className="form-control"
                            id="user_phone"
                            name="user_phone"
                            value={this.state.user_phone}
                            placeholder="Phone Number"
                          />
                        </div>
                        <span className="m-b-5 m-t-15 frm-err-msg">
                          {this.state.phoneError}
                        </span>
                      </div>

                      <div className="form-group mb-4">
                        <label for="email1">Email address</label>
                        <input
                          type="email"
                          onChange={this.onChangeHandler}
                          className="form-control"
                          name="user_email"
                          value={this.state.user_email}
                          id="email"
                          aria-describedby="emailHelp1"
                          placeholder="Email"
                        />
                        <span className="m-b-5 m-t-15 frm-err-msg">
                          {this.state.emailError}
                        </span>
                      </div>

                      <div className="form-group mb-4">
                        <label for="skillset">Skill Sets</label>
                        <input
                          type="text"
                          onChange={this.onChangeHandler}
                          className="form-control"
                          id="skillset"
                          value={this.state.skillset}
                          name="skillset"
                          placeholder="skillset"
                        />
                        <span className="m-b-5 m-t-15 frm-err-msg">
                          {this.state.skillsetError}
                        </span>
                      </div>

                      <div className="form-group mb-4">
                        <label for="skillset">Skills
                         {/* <span onClick={() => this.openTechUserDetailsModal(14) }>Edit</span> */}
                         <i className="fa fa-pencil text-success font-18 cursor-pointer m-l-10" aria-hidden="true" 
                            onClick={() => this.openTechUserDetailsModal(this.state.user_id) }></i>
                        </label>
                        <input type="text" className="form-control" id="" 
                               value={this.state.skills_category_name} name="" placeholder="skills" disabled />
                        <span className="m-b-5 m-t-15 frm-err-msg">
                          {this.state.SkillCategoryError}
                        </span>
                      </div>

                      <div className="form-group mb-4">
                        <label for="skillset">Proof Details : 
                         <span className="cursor-pointer"  onClick={() => this.OpenProofDetailsFunction(this.state.user_id) }> Click to view proof</span>
                        </label>
                      </div>
                      
                      <div className="form-group mb-4">
                        <label for="skillset">Certificate Details : 
                         <span className="cursor-pointer"  onClick={() => this.OpenCertificateDetailsFunction(this.state.user_id) }> Click to view Certificate</span>
                        </label>
                      </div>


                      {/* <div className="form-group mb-4">
                        <label style={{ marginBottom: "0px" }} for="country">
                          <b>Role</b>
                        </label>

                        <div class="row form-group mb-4 checkbox_cust_style">
                        {this.state.role_values.map((value_details) => (
                            <div class="col-lg-6 col-12 padding_sty">
                                <div class="custom-control custom-checkbox custom-control-inline">

                                {value_details.checked_flag == 'true' ? (
                                      <input type="checkbox" checked id={value_details.skill_category_id} name={value_details.skill_category} class="custom-control-input" />
                                 ) : (
                                     <input type="checkbox"  id={value_details.skill_category_id} name={value_details.skill_category} class="custom-control-input" />
                                )}
                                
                                    <label class="custom-control-label" for={value_details.skill_category_id}>{value_details.skill_category}</label>
                                </div>
                            </div>
                        ))}
                        </div>

                        <span className="m-b-5 m-t-15 frm-err-msg">
                          {this.state.roleError}
                        </span>
                      </div> */}

                      <div className="m-b-5 m-t-15 frm-err-msg text-center">
                        {this.state.messageError}
                      </div>

                      <p
                        onClick={() => this.submitFormHandler()}
                        className="ml-1 fr btn btn-primary"
                      >
                        Submit
                      </p>
                      <Link
                        to={this.state.redirecturl}
                        className="fr btn btn-danger"
                      >
                        Cancel
                      </Link>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

{/* skill details modal starts here */}
        <div id="TechUserDetails" className="modal animated fadeInRight custo-fadeInRight show" role="dialog" aria-modal="true">
          <div className="modal-dialog-content modal-dialog" style={{width: '55%'}}>
              <div className="modal-background-color modal-content">
              <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">Skill Details</h5>
              </div>
                  <div className="modal-body">

                  {this.state.techDetailsAvailable ? 
                  <div>
                      <Select 
                          className="select__skill_category"
                          defaultValue={[colourOptions[2], colourOptions[3]]} 
                          isMulti
                          name="colors"
                          options={this.state.skills}
                          className="basic-multi-select"
                          classNamePrefix="select" 
                          onChange={this.handleChange}
                      />
                  </div>
                  :
                  <p>Some details not available for this Fixer.</p>
                  }
                      
                  </div>
                  <div className="modal-footer md-button">
                      <button className="btn" onClick={this.submitSkillEdit}>
                        <i className="flaticon-cancel-12"></i> Submit
                      </button>
                      <button className="btn" data-dismiss="modal" onClick={this.closeTechUserDetailsModal}>
                        <i className="flaticon-cancel-12"></i> Close
                      </button>
                  </div>
              </div>
          </div>
        </div>
{/* skill details modal starts here */}

{/* Proof Details modal starts here */}
        <div id="proofDetailsModal" className="modal animated fadeInRight custo-fadeInRight show" role="dialog" aria-modal="true">
          <div className="modal-dialog-content modal-dialog" style={{width: '55%'}}>
              <div className="modal-background-color modal-content">
              <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">Proof Details Details</h5>
                  <p className="cursor-pointer" onClick={() => this.addProofDetails(this.state.user_id) } >Add Proof details</p>
              </div>
                  <div className="modal-body">

                  {this.state.proofDetailsAvailable ? 
                  <div>
                    {this.state.proof_fixers.map(certificates => (
                      <div class="right__menu_cr text-center m-b-5">
                          {/* <img src={this.state.baseUrl +`uploads/proof/` + certificates.file_name} />  */}
                          {certificates.file_extension == 'png' || certificates.file_extension == 'PNG' ||
                          certificates.file_extension == 'jpg' || certificates.file_extension == 'JPG' ||
                          certificates.file_extension == 'jpeg' || certificates.file_extension == 'JPEG' ?

                          <a href={this.state.baseUrl +`uploads/proof/` + certificates.file_name} target="_blank" >
                            <img src={this.state.baseUrl +`uploads/proof/` + certificates.file_name} />
                          </a>
                          :
                          certificates.file_extension == 'pdf' || certificates.file_extension == 'PDF' ?

                          <a href={this.state.baseUrl +`uploads/proof/` + certificates.file_name} target="_blank" >
                            <img src={this.state.baseUrl +`assets/pdf_icon.svg`} />
                          </a>
                          : 
                          <a href={this.state.baseUrl +`uploads/proof/` + certificates.file_name} target="_blank" >
                            <img src={this.state.baseUrl +`assets/other-file.png`} />
                          </a>
                          
                          }
                          <p>{certificates.proof_name}</p>
                          <p className="text-center color-tmp-5 cursorPointer" onClick={() => this.deleteFunction(certificates.fixer_proof_id) }>
                              <i class="fa fa-trash"></i> 
                          </p>
                      </div>
                    ))}
                  </div>
                  :
                  <p>Proof details not available for this Fixer.</p>
                  }
                      
                  </div>
                  <div className="modal-footer md-button">
                      <button className="btn" data-dismiss="modal" onClick={this.CloseProofDetailsFunction}>
                        <i className="flaticon-cancel-12"></i> Close
                      </button>
                  </div>
              </div>
          </div>
        </div>
{/* Proof details modal starts here */}


{/* Proof Details modal starts here */}
        <div id="addProofDetailsModal" className="modal animated fadeInRight custo-fadeInRight show" role="dialog" aria-modal="true">
          <div className="modal-dialog-content modal-dialog" style={{width: '55%'}}>
              <div className="modal-background-color modal-content">
              <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">Add Fixer Proof </h5>
              </div>
                  <div className="modal-body">
                  <div className="row" style={{width: '100%', paddingTop: '20px'}}>
                    <div class="col-lg-4 col-md-4 col-4 col-xs-4 ad__tk__lft">
                            <p>Proof Name : </p>
                        </div>
                        <div class="col-lg-8 col-md-8 col-8 col-xs-8" style={{padding: '0'}}>
                            <input type="text" class="form-control borderRadius-20" name='proof_name' onChange={this.onChangeHandler} />
                            <span className="m-b-5" style={{ fontSize: 12, color: "#D90429" }}>{this.state.prrof_name_error}</span>
                        </div>
                    </div>
                    <div class="row" style={{width: '100%', paddingTop: '20px'}}>
                        <div class="col-lg-4 col-md-4 col-4 col-xs-4 ad__tk__lft">
                            <p>Choose File :</p>
                        </div>
                        <div class="col-lg-8 col-md-8 col-8 col-xs-8 ad__tk__rgt" style={{padding: '0'}}>
                            <input type="file" class="form-control" name='image_url' onChange={this.handleImagePreview} />
                            <span className="m-b-5" style={{ fontSize: 12, color: "#D90429" }}>{this.state.imageError}</span>
                        </div>
                    </div>
                  </div>
                  <div className="modal-footer md-button">
                      <button className="btn" onClick={this.submitProfileEdit}>
                        <i className="flaticon-cancel-12"></i> Submit
                      </button>
                      <button className="btn" data-dismiss="modal" onClick={this.closeAddProofDetailsModal}>
                        <i className="flaticon-cancel-12"></i> Close
                      </button>
                  </div>
              </div>
          </div>
        </div>
{/* Proof details modal starts here */}

{/* Certificate Details modal starts here */}
        <div id="certificateDetailsModal" className="modal animated fadeInRight custo-fadeInRight show" role="dialog" aria-modal="true">
          <div className="modal-dialog-content modal-dialog" style={{width: '55%'}}>
              <div className="modal-background-color modal-content">
              <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">Certificate  Details</h5>
                  <p className="cursor-pointer" onClick={() => this.addCertificateDetails(this.state.user_id) } >Add Certificate</p>
              </div>
                  <div className="modal-body">
                  {this.state.certificateDetailsAvailable ? 
                  <div>
                    {this.state.certificate_fixers.map(certificates => (
                      <div class="right__menu_cr text-center m-b-5" key={certificates.tech_certificate_id}>
                          
                          {certificates.file_extension == 'png' || certificates.file_extension == 'PNG' ||
                          certificates.file_extension == 'jpg' || certificates.file_extension == 'JPG' ||
                          certificates.file_extension == 'jpeg' || certificates.file_extension == 'JPEG' ?

                          <a href={this.state.baseUrl +`uploads/certificate/` + certificates.file_name} target="_blank" >
                            <img src={this.state.baseUrl +`uploads/certificate/` + certificates.file_name} />
                          </a>
                          :
                          certificates.file_extension == 'pdf' || certificates.file_extension == 'PDF' ?

                          <a href={this.state.baseUrl +`uploads/certificate/` + certificates.file_name} target="_blank" >
                            <img src={this.state.baseUrl +`assets/pdf_icon.svg`} />
                          </a>
                          : 
                          <a href={this.state.baseUrl +`uploads/certificate/` + certificates.file_name} target="_blank" >
                            <img src={this.state.baseUrl +`assets/other-file.png`} />
                          </a>
                          
                          }
                          {/* <a href={this.state.baseUrl +`uploads/certificate/` + certificates.file_name} target="_blank" >
                            <img src={this.state.baseUrl +`uploads/certificate/` + certificates.file_name} />
                          </a> */}

                          <p>{certificates.proof_name}</p>
                          <p className="text-center color-tmp-5 cursorPointer" onClick={() => this.deleteCertificateFunction(certificates.tech_certificate_id) }>
                              <i class="fa fa-trash"></i> 
                          </p>
                      </div>
                    ))}
                  </div>
                  :
                  <p>Certificate details not available for this Fixer.</p>
                  }
                      
                  </div>
                  <div className="modal-footer md-button">
                      <button className="btn" data-dismiss="modal" onClick={this.CloseCertificateDetailsFunction}>
                        <i className="flaticon-cancel-12"></i> Close
                      </button>
                  </div>
              </div>
          </div>
        </div>
{/* Certificate details modal starts here */}

{/* Proof Details modal starts here */}
        <div id="addCertificateDetailsModal" className="modal animated fadeInRight custo-fadeInRight show" role="dialog" aria-modal="true">
          <div className="modal-dialog-content modal-dialog" style={{width: '55%'}}>
              <div className="modal-background-color modal-content">
              <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">Add Fixer Certificate </h5>
              </div>
                  <div className="modal-body">
                  <div className="row" style={{width: '100%', paddingTop: '20px'}}>
                    <div class="row" style={{width: '100%', paddingTop: '20px'}}>
                        <div class="col-lg-4 col-md-4 col-4 col-xs-4 ad__tk__lft">
                            <p>Choose File :</p>
                        </div>
                        <div class="col-lg-8 col-md-8 col-8 col-xs-8 ad__tk__rgt" style={{padding: '0'}}>
                            <input type="file" class="form-control" name='image_url' onChange={this.handleCertificateImagePreview} />
                            <span className="m-b-5" style={{ fontSize: 12, color: "#D90429" }}>{this.state.imageCertificateError}</span>
                        </div>
                    </div>
                  </div>
                  <div className="modal-footer md-button">
                      <button className="btn" onClick={this.submitCertificate}>
                        <i className="flaticon-cancel-12"></i> Submit
                      </button>
                      <button className="btn" data-dismiss="modal" onClick={this.closeAddCertificateDetailsModal}>
                        <i className="flaticon-cancel-12"></i> Close
                      </button>
                  </div>
              </div>
          </div>
        </div>
        </div>
{/* Proof details modal starts here */}

        <div className={this.state.overlay}>
          <div className={this.state.spinner}></div>
        </div>
      </div>
    );
  }
}
export default EditTechSupport;
