import $ from 'jquery';
import React, { Component } from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Link } from 'react-router-dom';
import { axiosInstance } from '../../Services/PostData';
import './chat.css';
import './fixesdetails.css';
import swal from 'sweetalert2';

class CompFixesDetails extends Component {
    constructor(){
		super();
		this.state = {
            responseValue: [],
            chat_responseValue: []
        };
		this.fixesDetails = this.fixesDetails.bind(this);
		this.closeChat = this.closeChat.bind(this);
		this.submitcloseChat = this.submitcloseChat.bind(this);
    }
    
    componentDidMount() {
        let spinner = "loadercustumspinner";
        let overlay = "loadingcustumspinner";
        this.setState({ spinner, overlay });
        this.fixesDetails();

    }
    
    async fixesDetails() {
        //const baseUrl = window.$baseUrl;
        const baseUrl = "https://api.touchtofix.com/demo/sanyaa-api/";
        this.setState({baseUrl})
        
        let query = window.location.search.substring(2);


        let pair = query.split("=");
        var queryString = pair[1];
        var queryValue = queryString.split(",");

        var queryValue1 = queryValue[0];
        var queryValue2 = queryValue[1];
        // var queryValue3 = queryValue[2];
        this.setState({ queryValue2 });

        if (queryValue2=='Closedticket' || queryValue2=='Activeticket')
        {
            const jsonValue = { fixesDetailsId: queryValue1 };
            await axiosInstance.post(`fixesDetails`, jsonValue)
                .then(res => {
                    if(res.data.status === 'success' && res.data.statusCode ==='200' )
                    {
                        let response = res.data.fixes_details;
                        if(response.length != 0 || response.length != '0')
                        {
                            setTimeout(function(){
                                $( ".dataTables_empty" ).hide();
                            }, 100);          
                        }
    
                        let Details = response.map(values_response => { return values_response });
                        this.setState({ responseValue: Details });
                        
                        let chat_response = res.data.chat_history_details;
                        for(let i=0; i < chat_response.length; i++) {
                            let chat_time = chat_response[i].timestamp;
                            if(chat_time != '') {
                              let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                              let timestamp_convert = this.convertTZ(chat_time, timeZone);
                              let chat_time_response = this.getNowTimeDate(timestamp_convert);
                              chat_response[i].timestamp = chat_time_response;
                            }
                        }
                        let chat_Details = chat_response.map(chat_values_response => { return chat_values_response });
                        this.setState({ chat_responseValue: chat_Details });
    
                        let chatRoomId = res.data.chat_room_id;
                        this.setState({chatRoomId});
                        
                        let spinner = "loadercustumspinner";
                        let overlay = "loadingcustumspinner hide__container";
                        this.setState({ spinner, overlay });
                    }		 
                })
                .catch(err => console.log('Login: ', err));
        }
        else if (queryValue2=='Openticket' || queryValue2=='Abortticket')
        {
            const jsonValue = { fixes_request_id: queryValue1 };
            await axiosInstance.post(`fixes_open_abort_Details`, jsonValue)
                .then(res => {
                    if(res.data.status === 'success' && res.data.statusCode ==='200' )
                    {
                        let response = res.data.fixes_details;
                        if(response.length != 0 || response.length != '0')
                        {
                            setTimeout(function(){
                                $( ".dataTables_empty" ).hide();
                            }, 100);          
                        } 
                        let Details = response.map(values_response => { return values_response });
                        this.setState({ responseValue: Details });

                        let spinner = "loadercustumspinner";
                        let overlay = "loadingcustumspinner hide__container";
                        this.setState({ spinner, overlay });
                    }		 
                })
                .catch(err => console.log('Login: ', err));
        }

        }
    
    getNowTimeDate(timestamp) {

        //get date
        let dd = timestamp.getDate();
        let mm = timestamp.getMonth()+1; 
        let yyyy = timestamp.getFullYear();
        if(dd<10)  {
            dd='0'+dd;
        } 
        if(mm<10)  {
            mm='0'+mm;
        } 
        let today = dd+'/'+mm+'/'+yyyy;

        //get timing
        let now_hour = timestamp.getHours();
        let now_min = timestamp.getMinutes();
        let now_ampm = now_hour >= 12 ? 'pm' : 'am';
        now_hour = now_hour % 12;
        now_hour = now_hour ? now_hour : 12; // the hour '0' should be '12'
        now_min = now_min < 10 ? '0'+now_min : now_min;
        let strTime = now_hour + ':' + now_min + ' ' + now_ampm;
        
        let date_and_time = today +" - " + strTime;
        return date_and_time;
    
    }

    convertTZ(date, tzString) {
        return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {timeZone: tzString}));   
    }
    
    closeChat() {
        swal.fire({
            type: "info",
            text: "Are you sure you want to close this ticket",
            showCancelButton: true,
            confirmButtonColor: '#D90429',
            cancelButtonText: "No",
            confirmButtonText: 'Yes'
        })
        .then((result) => {
            if (result.value) {
                this.submitcloseChat();
            }
        });
    }

    submitcloseChat() {

        let spinner = "loadercustumspinner";
        let overlay = "loadingcustumspinner";
        this.setState({ spinner, overlay });

        const jsonValue = { chat_room_id: this.state.chatRoomId };
        axiosInstance.post(`endCustomerChat`, jsonValue)
        .then(res => {
            if(res.data.status === 'success' && res.data.statusCode ==='200' )
            {
                let spinner = "loadercustumspinner";
                let overlay = "loadingcustumspinner hide__container";
                this.setState({ spinner, overlay });

                swal.fire({
                    type: "info",
                    text: 'Chat closed successfully',
                    confirmButtonText: "ok",
                    confirmButtonColor: "#D82B2A",
                })
                .then((result) => {
                    window.location.reload();
                });
            }
            else {
                swal.fire({
                    type: "info",
                    text: 'Error in closing chat please try again',
                    confirmButtonText: "ok",
                    confirmButtonColor: "#D82B2A",
                })
                .then((result) => {
                    window.location.reload();
                });
            }		 
        })
        .catch(err => console.log('Login: ', err));
    }

render() {
return (
    <div id="content" className="main-content">
        <div className="layout-px-spacing">
            <div className="row layout-top-spacing2 layout-top-spacing">
                <div className="col-xl-12 col-lg-12 col-sm-12 layout-spacing2  layout-spacing">
                   <div className="widget-content widget-content-area pg-top">
                        <div className="table-responsive mb-4 mt-1">
                        {/* <p className="table-head table-head-no-add">Fixes Details</p> */}

                        {this.state.responseValue.map(values => (

                        <div className="p-10 col-md-6 flt-lft">
                        <p className="table-head table-head-no-add">Fixes Details</p>
                            {values.cus_ticket_assign_status == 'Closed' ? (
                                <div>
                                    <div className="lbl-fix-lbl-section">
                                        <label className="col-md-4 lbl-fix-lbl"><b>Ticket Number</b> <span className="lb-flt-rg">:</span> </label>
                                        <p className="col-md-7 custom-control-inline">TKT000{values.cus_ticket_id}</p>
                                    </div>
            
                                    <div className="lbl-fix-lbl-section">
                                        <label className="col-md-4 lbl-fix-lbl"><b>Status</b> <span className="lb-flt-rg">:</span> </label>
                                        <p className="col-md-7 custom-control-inline">
                                        <span className="activestatus_color">{values.cus_ticket_assign_status}</span>
                                        </p>
                                    </div>
                                    
                                    <div className="lbl-fix-lbl-section">
                                        <label className="col-md-4 lbl-fix-lbl"><b>Ticket Created By</b> <span className="lb-flt-rg">:</span> </label>
                                        <p className="col-md-7 custom-control-inline">{values.cus_user_name}</p>
                                    </div>
            
                                    <div className="lbl-fix-lbl-section">
                                        <label className="col-md-4 lbl-fix-lbl"><b>Ticket Created To</b> <span className="lb-flt-rg">:</span> </label>
                                        <p className="col-md-7 custom-control-inline">{values.tech_user_name}</p>
                                    </div>
                                    
                                    <div className="lbl-fix-lbl-section">
                                        <label className="col-md-4 lbl-fix-lbl"><b>Created Date</b> <span className="lb-flt-rg">:</span> </label>
                                        <p className="col-md-7 custom-control-inline">{values.cus_ticket_created_date}</p>
                                    </div>
                                    
                                    <div className="lbl-fix-lbl-section">
                                        <label className="col-md-4 lbl-fix-lbl"><b>Created Time</b> <span className="lb-flt-rg">:</span> </label>
                                        <p className="col-md-7 custom-control-inline">{values.cus_ticket_created_time}</p>
                                    </div>
                                    <div className="lbl-fix-lbl-section distrue_close">
                                        <label className="col-md-4 lbl-fix-lbl"><b>Closed Date</b> <span className="lb-flt-rg">:</span> </label>
                                        <p className="col-md-7 custom-control-inline">{values.end_date}</p>
                                    </div>
                                    
                                    <div className="lbl-fix-lbl-section distrue_close">
                                        <label className="col-md-4 lbl-fix-lbl"><b>Closed Time</b> <span className="lb-flt-rg">:</span> </label>
                                        <p className="col-md-7 custom-control-inline">{values.end_time}</p>
                                    </div>
                                    
                                    <div className="lbl-fix-lbl-section distrue_close">
                                        <label className="col-md-4 lbl-fix-lbl"><b>Duration</b> <span className="lb-flt-rg">:</span> </label>
                                        <p className="col-md-7 custom-control-inline">{values.duration_time}</p>
                                    </div>
            
                                    <div className="lbl-fix-lbl-section distrue_close">
                                        <label className="col-md-4 lbl-fix-lbl"><b>Customer Amount</b> <span className="lb-flt-rg">:</span> </label>
                                        <p className="col-md-7 custom-control-inline">{values.cus_ticket_amount}</p>
                                    </div>
            
                                    <div className="lbl-fix-lbl-section distrue_close">
                                        <label className="col-md-4 lbl-fix-lbl"><b>Fixer Amount</b> <span className="lb-flt-rg">:</span> </label>
                                        <p className="col-md-7 custom-control-inline">{values.tech_ticket_amount}</p>
                                    </div>
            
                                    <div className="lbl-fix-lbl-section distrue_close">
                                        <label className="col-md-4 lbl-fix-lbl"><b>Sanyaa Amount</b> <span className="lb-flt-rg">:</span> </label>
                                        <p className="col-md-7 custom-control-inline">{values.tech_ticket_amount}</p>
                                    </div>
                                    <br /> 
                                    <Link to={{ pathname: '/ReFixesDetails', search: 'cus_user_id='+values.cus_user_id+',refiextickts,'+values.cus_ticket_id }}  className="fr btn btn-danger flt-lft col-md-6"> Refix </Link>
                                </div>
                            ) : values.cus_ticket_assign_status == 'Active' || values.cus_ticket_assign_status == 'open'  ? (
                                <div>
                                    <div className="lbl-fix-lbl-section">
                                        <label className="col-md-4 lbl-fix-lbl"><b>Ticket Number</b> <span className="lb-flt-rg">:</span> </label>
                                        <p className="col-md-7 custom-control-inline">TKT000{values.cus_ticket_id}</p>
                                    </div>
            
                                    <div className="lbl-fix-lbl-section">
                                        <label className="col-md-4 lbl-fix-lbl"><b>Status</b> <span className="lb-flt-rg">:</span> </label>
                                        <p className="col-md-7 custom-control-inline">
                                        <span className="activestatus_color">Active</span></p>
                                    </div>
                                    
                                    <div className="lbl-fix-lbl-section">
                                        <label className="col-md-4 lbl-fix-lbl"><b>Ticket Created By</b> <span className="lb-flt-rg">:</span> </label>
                                        <p className="col-md-7 custom-control-inline">{values.cus_user_name}</p>
                                    </div>
            
                                    <div className="lbl-fix-lbl-section">
                                        <label className="col-md-4 lbl-fix-lbl"><b>Ticket Created To</b> <span className="lb-flt-rg">:</span> </label>
                                        <p className="col-md-7 custom-control-inline">{values.tech_user_name}</p>
                                    </div>
                                    
                                    <div className="lbl-fix-lbl-section">
                                        <label className="col-md-4 lbl-fix-lbl"><b>Created Date</b> <span className="lb-flt-rg">:</span> </label>
                                        <p className="col-md-7 custom-control-inline">{values.cus_ticket_created_date}</p>
                                    </div>
                                    
                                    <div className="lbl-fix-lbl-section">
                                        <label className="col-md-4 lbl-fix-lbl"><b>Created Time</b> <span className="lb-flt-rg">:</span> </label>
                                        <p className="col-md-7 custom-control-inline">{values.cus_ticket_created_time}</p>
                                    </div>
                                    <br /> 
                                    <p className="fr btn btn-danger flt-lft col-md-6" onClick={this.closeChat}> Close Ticket </p>
                                    {/* <Link to={{ pathname: '/ReFixesDetails', search: 'cus_user_id='+values.cus_user_id}} className="fr btn btn-danger flt-lft col-md-6"> Refix </Link> */}
                                </div>
                            ) : values.cus_ticket_assign_status == 'Active_open' && this.state.queryValue2 == 'Openticket' ? (
                                <div>
                                    <div className="lbl-fix-lbl-section">
                                        <label className="col-md-4 lbl-fix-lbl"><b>Ticket Number</b> <span className="lb-flt-rg">:</span> </label>
                                        <p className="col-md-7 custom-control-inline">TKT000{values.cus_ticket_id}</p>
                                    </div>
            
                                    <div className="lbl-fix-lbl-section">
                                        <label className="col-md-4 lbl-fix-lbl"><b>Status</b> <span className="lb-flt-rg">:</span> </label>
                                        <p className="col-md-7 custom-control-inline"> 
                                        <span className="activestatus_color">Open</span>  </p>
                                    </div>
                                    
                                    <div className="lbl-fix-lbl-section">
                                        <label className="col-md-4 lbl-fix-lbl"><b>Ticket Created By</b> <span className="lb-flt-rg">:</span> </label>
                                        <p className="col-md-7 custom-control-inline">{values.cus_user_name}</p>
                                    </div>
            
                                    <div className="lbl-fix-lbl-section">
                                        <label className="col-md-4 lbl-fix-lbl"><b>Ticket Created To</b> <span className="lb-flt-rg">:</span> </label>
                                        <p className="col-md-7 custom-control-inline">{values.tech_user_name}</p>
                                    </div>
                                    
                                    <div className="lbl-fix-lbl-section">
                                        <label className="col-md-4 lbl-fix-lbl"><b>Created Date</b> <span className="lb-flt-rg">:</span> </label>
                                        <p className="col-md-7 custom-control-inline">{values.date}</p>
                                    </div>
                                    
                                    <div className="lbl-fix-lbl-section">
                                        <label className="col-md-4 lbl-fix-lbl"><b>Created Time</b> <span className="lb-flt-rg">:</span> </label>
                                        <p className="col-md-7 custom-control-inline">{values.time}</p>
                                    </div>
                                    <br /> 
                                    <Link 
                                    to={{ pathname: '/ReFixesDetails', search: 'cus_user_id='+values.cus_user_id+',refiextickts,0' }}  className="fr btn btn-danger flt-lft col-md-6"> Refix </Link>
                                </div>
                            ) :values.cus_ticket_assign_status == 'Active_open' && this.state.queryValue2 == 'Abortticket' ? (
                                <div>
                                    <div className="lbl-fix-lbl-section">
                                        <label className="col-md-4 lbl-fix-lbl"><b>Ticket Number</b> <span className="lb-flt-rg">:</span> </label>
                                        <p className="col-md-7 custom-control-inline">TKT000{values.cus_ticket_id}</p>
                                    </div>
            
                                    <div className="lbl-fix-lbl-section">
                                        <label className="col-md-4 lbl-fix-lbl"><b>Status</b> <span className="lb-flt-rg">:</span> </label>
                                        <p className="col-md-7 custom-control-inline "> 
                                         <span className="activestatus_color">Abort</span>    </p>
                                    </div>
                                    
                                    <div className="lbl-fix-lbl-section">
                                        <label className="col-md-4 lbl-fix-lbl"><b>Ticket Created By</b> <span className="lb-flt-rg">:</span> </label>
                                        <p className="col-md-7 custom-control-inline">{values.cus_user_name}</p>
                                    </div>
            
                                    <div className="lbl-fix-lbl-section">
                                        <label className="col-md-4 lbl-fix-lbl"><b>Ticket Created To</b> <span className="lb-flt-rg">:</span> </label>
                                        <p className="col-md-7 custom-control-inline">{values.tech_user_name}</p>
                                    </div>
                                    
                                    <div className="lbl-fix-lbl-section">
                                        <label className="col-md-4 lbl-fix-lbl"><b>Created Date</b> <span className="lb-flt-rg">:</span> </label>
                                        <p className="col-md-7 custom-control-inline">{values.date}</p>
                                    </div>
                                    
                                    <div className="lbl-fix-lbl-section">
                                        <label className="col-md-4 lbl-fix-lbl"><b>Created Time</b> <span className="lb-flt-rg">:</span> </label>
                                        <p className="col-md-7 custom-control-inline">{values.time}</p>
                                    </div>
                                    <br /> 
                                    <Link to={{ pathname: '/ReFixesDetails', search: 'cus_user_id='+values.cus_user_id+',refiextickts,0' }}  className="fr btn btn-danger flt-lft col-md-6"> Refix </Link>
                                </div>
                            ) :
                            (
                                <div></div>
                            )}
                        </div>

                        ))}
                        
                        {/* {this.state.queryValue2} */}
                        {this.state.queryValue2 == 'Closed' || this.state.queryValue2 == 'Activeticket' || this.state.queryValue2 == 'Closedticket' ? (
                            <div className="p-10 col-md-6 flt-lft">
                                <p className="table-head table-head-no-add">Chat History</p>
                                <div class="chat">
                                    <div class="chat-history">
                                        <ul>
                                        {this.state.chat_responseValue.map(chat_values => (

                                        <li className="clearfix"  key={chat_values.chat_history_id}>
                                        {(chat_values.type === 'C') ? 
                                            <span>
                                            <div className="message-data align-right"> 
                                                <span className="message-data-name">{chat_values.timestamp}</span> &nbsp;
                                                <span className="message-data-name">Customer</span> <i className="fa fa-circle me"></i>
                                            </div>
                                            <div className="message other-message float-right">
                                                {chat_values.file_extension == 'jpg' || chat_values.file_extension == 'png' || chat_values.file_extension == 'jpeg' ? 
                                                    <img src={this.state.baseUrl+ 'uploads/chatAttach/'+chat_values.file_name} 
                                                        alt=""
                                                        width="150px"
                                                        style={{float: 'right'}} />
                                                    :
                                                    chat_values.file_extension == 'mp4' || chat_values.file_extension == 'MOV'?
                                                    <video width="320" height="240" controls>
                                                    <source src={this.state.baseUrl+ 'uploads/chatAttach/'+chat_values.file_name} type="video/mp4" />
                                                    <source src={this.state.baseUrl+ 'uploads/chatAttach/'+chat_values.file_name} type="video/ogg" />
                                                    </video>  
                                                    :
                                                    null
                                                }     

                                                {chat_values.description}
                                            </div> 
                                            </span>
                                        :
                                        <span>
                                            <div className="message-data">
                                                <span className="message-data-name"><i className="fa fa-circle online"></i> {chat_values.userName}</span>
                                                &nbsp; <span className="message-data-name">{chat_values.timestamp}</span> 
                                            </div>
                                            <div className="message my-message">

                                            {chat_values.file_name != '' ?
                                            <div>
                                                <img src={this.state.baseUrl + 'uploads/chatAttach/'+chat_values.file_name} 
                                                    alt=""
                                                    width="150px" />
                                                {chat_values.description}
                                                </div>
                                                :
                                                <div>
                                                {chat_values.description}
                                            </div>
                                            }
                                            </div>
                                        </span>
                                        }
                                        
                                        </li>

                                        ))}

                                        </ul>
                                    </div> 
                                </div>
                            </div>

                        ):
                        (
                            <div></div>
                        )}
                        
                        </div>
                    </div>
                </div>

            </div>

        </div>

    <div className={this.state.overlay}>
      <div className={this.state.spinner}></div>
    </div>
    </div>
)
}
}
export default CompFixesDetails