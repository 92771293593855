import React, { Component } from "react";
import { Link } from 'react-router-dom'
import './LeftSideBar.css';
import { axiosInstance } from "../../Services/PostData";

// const LeftSideBar = props => {
class LeftSideBar extends Component {
    constructor() {
        super();
        this.state = {
            values: [],
            typeTSASAdmin: window.localStorage.getItem('typeTSASAdmin'),
        };
        this.getuserRoledetails = this.getuserRoledetails.bind(this);
    }

    componentDidMount() {
        this.getuserRoledetails();
    }

    getuserRoledetails() {

        let id = window.localStorage.getItem('adminUserLocalIdTSAS');

        const jsonValue = { id: id};
        axiosInstance.post(`getRoleDetails`, jsonValue)
        .then(res => {
        if(res.data.status === 'success' && res.data.statusCode ==='200' )
        {
            let admin_role_name = res.data.role_details[0].admin_role_name;
            let open_fixes = res.data.role_details[0].open_fixes;
            let close_fixes = res.data.role_details[0].close_fixes;
            let private_fixes = res.data.role_details[0].private_fixes;
            let resolve_fixes = res.data.role_details[0].resolve_fixes;
            let country_master = res.data.role_details[0].country_master;
            let skill_master = res.data.role_details[0].skill_master;
            let super_admin = res.data.role_details[0].super_admin;
            let site_admin = res.data.role_details[0].site_admin;
            let support_admin = res.data.role_details[0].support_admin;
            let tech_support = res.data.role_details[0].tech_support;
            let customer = res.data.role_details[0].customer;
            let reports = res.data.role_details[0].reports;
            let notification = res.data.role_details[0].notification;
            let roles = res.data.role_details[0].roles;
            let avatar = res.data.role_details[0].avatar;
            let banner = res.data.role_details[0].banner;
            let support_chat = res.data.role_details[0].support_chat;

            this.setState({admin_role_name, open_fixes, close_fixes, private_fixes, resolve_fixes, country_master, skill_master, 
                super_admin, site_admin, support_admin, tech_support, customer, reports, notification, roles, avatar, banner, support_chat })
        }
        })
        .catch(err => console.log('Login: ', err));
    }

    render() {
    return (
        <div className="sidebar-wrapper sidebar-theme">
        <nav id="sidebar">
        <ul className="list-unstyled menu-categories" id="accordionExample">
                <li className="menu">
                    <Link to="/Dashboard" className="dropdown-toggle">
                        <div className="">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-home">
                                <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                                <polyline points="9 22 9 12 15 12 15 22"></polyline>
                            </svg>
                            <span>Dashboard</span>
                        </div>
                    </Link>
                </li>
            { this.state.typeTSASAdmin === 'SA' &&
            <li className="menu">
                <Link to="/Company" className="dropdown-toggle">
                    <div className="">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-home">
                            <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                            <polyline points="9 22 9 12 15 12 15 22"></polyline>
                        </svg>
                        <span>Company</span>
                    </div>
                </Link>
            </li>
            }
            <li className="menu">
                <a href="#app" data-toggle="collapse" aria-expanded="false" className="dropdown-toggle">
                    <div className="">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-users">
                            <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                            <circle cx="9" cy="7" r="4"></circle>
                            <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                            <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                        </svg>

                        <span>User</span>
                    </div>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-chevron-right">
                            <polyline points="9 18 15 12 9 6"></polyline>
                        </svg>
                    </div>
                </a>
                <ul className="collapse submenu list-unstyled" id="app" data-parent="#accordionExample">
                    <li>
                        <Link to="/AddUser"> Add User </Link>
                    </li>

                    {this.state.super_admin === '1' ? 
                        <li>
                            <Link to="/UserSuperAdmin"> Super Admin </Link>
                        </li>    
                        :
                        null
                    } 

                    {this.state.site_admin === '1' ? 
                        <li>
                            <Link to="/SiteAdmin"> Site Admin </Link>
                        </li>    
                        :
                        null
                    } 

                    {this.state.support_admin === '1' ? 
                        <li>
                            <Link to="/SupportAdmin"> Support Admin </Link>
                        </li>    
                        :
                        null
                    } 

                    {this.state.tech_support === '1' ? 
                        <li>
                            <Link to="/TechSupport"> Tech Support </Link>
                        </li>    
                        :
                        null
                    } 

                    { this.state.typeTSASAdmin === 'SA' &&
                    <>
                    {this.state.customer === '1' ? 
                        <li>
                            <Link to="/Customer"> Customer </Link>
                        </li>    
                        :
                        null
                    } 
                    </>
                    }                    
                </ul>
            </li>
            <li className="menu">
                <a href="#components" data-toggle="collapse" aria-expanded="false" className="dropdown-toggle">
                    <div className="">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-layers">
                            <polygon points="12 2 2 7 12 12 22 7 12 2"></polygon>
                            <polyline points="2 17 12 22 22 17"></polyline>
                            <polyline points="2 12 12 17 22 12"></polyline>
                        </svg>
                        <span>Fixes</span>
                    </div>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-chevron-right">
                            <polyline points="9 18 15 12 9 6"></polyline>
                        </svg>
                    </div>
                </a>
                <ul className="collapse submenu list-unstyled" id="components" data-parent="#accordionExample">

                    {this.state.private_fixes === '1' ? 
                        <li>
                            <Link to="/PrivateFixes"> Active Fixes </Link>
                        </li>    
                        :
                        null
                    } 
                    {this.state.open_fixes === '1' ? 
                        <li>
                            <Link to="/OpenFixes"> Open Fixes </Link>
                        </li>    
                        :
                        null
                    } 
                    {this.state.close_fixes === '1' ? 
                        <li>
                            <Link to="/ClosedFixes"> Closed Fixes </Link>
                        </li>    
                        :
                        null
                    } 
                    {this.state.resolve_fixes === '1' ? 
                        <li>
                            <Link to="/AbortedFixes"> Aborted Fixes </Link>
                        </li>    
                        :
                        null
                    } 
                </ul>
            </li>
            { this.state.typeTSASAdmin === 'SA' &&
            <li className="menu">
                <a href="#elements" data-toggle="collapse" aria-expanded="false" className="dropdown-toggle">
                    <div className="">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-grid">
                            <rect x="3" y="3" width="7" height="7"></rect>
                            <rect x="14" y="3" width="7" height="7"></rect>
                            <rect x="14" y="14" width="7" height="7"></rect>
                            <rect x="3" y="14" width="7" height="7"></rect>
                        </svg>
                        <span>Master</span>
                    </div>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-chevron-right">
                            <polyline points="9 18 15 12 9 6"></polyline>
                        </svg>
                    </div>
                </a>
                <ul className="collapse submenu list-unstyled" id="elements" data-parent="#accordionExample">

                    {this.state.country_master === '1' ? 
                        <li>
                            <Link to="/Country"> Country </Link>
                        </li>    
                        :
                        null
                    } 
                    
                    {this.state.skill_master === '1' ? 
                        <li>
                            <Link to="/Skills"> Skills </Link>
                        </li>    
                        :
                        null
                    } 
                </ul>
            </li>
            }
            
            {this.state.reports === '1' ?
                <li className="menu">
                    <a href="#reports" data-toggle="collapse" aria-expanded="false" className="dropdown-toggle">
                        <div className="">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-file-text">
                                <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
                                <polyline points="14 2 14 8 20 8"></polyline>
                                <line x1="16" y1="13" x2="8" y2="13"></line>
                                <line x1="16" y1="17" x2="8" y2="17"></line>
                                <polyline points="10 9 9 9 8 9"></polyline>
                            </svg>
                            <span>Reports</span>
                        </div>
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-chevron-right">
                                <polyline points="9 18 15 12 9 6"></polyline>
                            </svg>
                        </div>
                    </a>
                    <ul className="collapse submenu list-unstyled" id="reports" data-parent="#accordionExample">
                        <li>
                            <Link to="/Reports"> Date wise Report </Link>
                        </li>
                        <li>
                            <Link to="/CustReports"> Customer Report </Link>
                        </li>
                        <li>
                            <Link to="/TechReports"> TechSupport Report </Link>
                        </li>
                    </ul>
                </li>   
                :
                null
            }
            <li className="menu">
                <a href="#feedback" data-toggle="collapse" aria-expanded="false" className="dropdown-toggle">
                    <div className="">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-list"><line x1="8" y1="6" x2="21" y2="6"></line><line x1="8" y1="12" x2="21" y2="12"></line><line x1="8" y1="18" x2="21" y2="18"></line><line x1="3" y1="6" x2="3.01" y2="6"></line><line x1="3" y1="12" x2="3.01" y2="12"></line><line x1="3" y1="18" x2="3.01" y2="18"></line></svg>
                        <span>Feedback</span>
                    </div>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-chevron-right">
                            <polyline points="9 18 15 12 9 6"></polyline>
                        </svg>
                    </div>
                </a>
                <ul className="collapse submenu list-unstyled" id="feedback" data-parent="#accordionExample">
                    <li>
                        <Link to="/CustomerFeedback"> Customer </Link>
                    </li>
                    <li>
                        <Link to="/TechSupportFeedback"> TechSupport </Link>
                    </li>
                </ul>
            </li> 

            {this.state.notification === '1' ?
            <li className="menu">
                <Link to="/Notification" aria-expanded="false" className="dropdown-toggle">
                    <div className="">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-bell">
                            <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path>
                            <path d="M13.73 21a2 2 0 0 1-3.46 0"></path>
                        </svg>
                        <span>Notification</span>
                    </div>
                </Link>
            </li>
            :
            null
            }

            

            <li className="menu">
                <Link to="/UserNotification" aria-expanded="false" className="dropdown-toggle">
                    <div className="">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-bell">
                            <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path>
                            <path d="M13.73 21a2 2 0 0 1-3.46 0"></path>
                        </svg>
                        <span>User Notification</span>
                    </div>
                </Link>
            </li>

            {this.state.roles === '1' ?
            <li className="menu">
                <Link to="/Roles" aria-expanded="false" className="dropdown-toggle">
                    <div className="">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-layout">
                            <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
                            <line x1="3" y1="9" x2="21" y2="9"></line>
                            <line x1="9" y1="21" x2="9" y2="9"></line>
                        </svg>
                        <span>Roles</span>
                    </div>
                </Link>
            </li>
            :
            null
            }

            { this.state.typeTSASAdmin === 'SA' &&
            <>
            {this.state.banner === '1' ?
            <li className="menu">
                <Link to="/Banner" aria-expanded="false" className="dropdown-toggle">
                    <div className="">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-trello">
                            <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
                            <rect x="7" y="7" width="3" height="9"></rect>
                            <rect x="14" y="7" width="3" height="5"></rect>
                        </svg>
                        <span>Banner</span>
                    </div>
                </Link>
            </li>
            :
            null
            }
            </>
            }

            { this.state.typeTSASAdmin === 'SA' &&
            <>
            {this.state.avatar === '1' ?
            <li className="menu">
                <Link to="/Avatar" aria-expanded="false" className="dropdown-toggle">
                    <div className="">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-user">
                            <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                            <circle cx="12" cy="7" r="4"></circle>
                        </svg>
                        <span>Avatar</span>
                    </div>
                </Link>
            </li>
            :
            null
            }
            </>
            }

            

            {this.state.support_chat === '1' ?
            <li className="menu">
                <Link to="/Chat" aria-expanded="false" className="dropdown-toggle">
                    <div className="">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-user">
                            <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                            <circle cx="12" cy="7" r="4"></circle>
                        </svg>
                        <span>Chat</span>
                    </div>
                </Link>
            </li>
            :
            null
            }
         
        </ul>
    </nav>
</div>

  )
}
}

export default LeftSideBar