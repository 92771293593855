import $ from "jquery";
import React, { Component,useState  } from "react";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Link } from "react-router-dom";
import { axiosInstance } from "../../Services/PostData";
import "../Reports/CompReports.css";
import DataTable from 'react-data-table-component';
import DataTableExtensions from "react-data-table-component-extensions";
import DatePicker from "react-datepicker"; 
import "react-datepicker/dist/react-datepicker.css";

class CompUserNotification extends Component {
  constructor() {
    super();
    this.state = {
      values: [],      
      country_values: [],
      optionsState:'',
      showtable:false,
      showMessageBox:false,
      from_date:new Date(),
      to_date:new Date(),
      userMessage: '',
      x_userids: ''
    };
    this.handleChange = this.handleChange.bind(this);
    this.onchangeHandler = this.onchangeHandler.bind(this);
    this.submitNotification = this.submitNotification.bind(this);
    this.getUserDetails = this.getUserDetails.bind(this);
    this.handleImagePreview = this.handleImagePreview.bind(this);
  }

  componentDidMount() { }

  async getUserDetails() {

    if(this.state.optionsState == '')
    {
      this.setState({selectUserError: "Select user type"});
    }
    else {
      this.setState({selectUserError: ""});
      const jsonValue = { type: this.state.optionsState };
      axiosInstance
        .post(`getUserNotificationDetails`, jsonValue)
        .then((res) => {
          if (res.data.status === "success" && res.data.statusCode === "200") {
            let response = res.data.user_details;
            let response_Details = response.map((value) => { return value; });
            this.setState({ values: response_Details });
            this.setState({ showtable: true });
            this.setState({ showMessageBox: true });
          }
        })
        .catch((err) => console.log("Login: ", err));
    }
    
  }

  submitFormHandler()
  {
      this.getUserDetails();
  }

  handleChange = (state) => {
    let selected_row = state.selectedRows;
    const options_1 = [];
    for(var i=0; i < selected_row.length; i++) {
        options_1.push(selected_row[i].user_id)
    }
    var x_userids = options_1.toString();
    this.setState({x_userids: x_userids})
  }

  onchangeHandler(e) {
    this.setState({ [e.target.name]: e.target.value });
    this.setState({userSelectError: '', userMessageError: ''});
  }

  submitNotification() {
    
    if(this.state.userMessage == "")
    {
      this.setState({userMessageError: 'fill message box'});
    }
    else if(this.state.x_userids == "")
    {
      this.setState({userSelectError: 'Choose user in below table to send message'});
    }
    else {
      this.setState({userSelectError: '', userMessageError: ''});
      // const jsonValue = { userids: this.state.x_userids, message: this.state.userMessage, user_type: this.state.optionsState };


      let formData = new FormData();
      if(this.state.image_file == null)
      {
          this.state.image_file = '';
      }
      formData.append('userids', this.state.x_userids);
      formData.append('message', this.state.userMessage);
      formData.append('user_type', this.state.optionsState);
      formData.append('imageFile', this.state.image_file);


      axiosInstance
        // .post(`sendUserNotification`, jsonValue)
        .post(`sendUserNotification`, formData)
        .then((res) => {
          // if (res.data.status === "success" && res.data.statusCode === "200") {
          //   alert(1)
          // }

          confirmAlert({
            message: "message sent successfully",
            buttons: [
              {
                label: "Okay",
                onClick: () => {
                  window.location.reload();
                },
              }
            ],
            closeOnClickOutside: false,
          });

          
        })
        .catch((err) => console.log("Login: ", err));
    }
    
  }

  // Image Preview Handler
  handleImagePreview (e){
      let image_as_base64 = URL.createObjectURL(e.target.files[0])
      let image_as_files = e.target.files[0];

      this.setState({
          image_preview: image_as_base64,
          image_file: image_as_files,
      });
  }
  
  render() {

    const columns = [
      {
        name: 'Username',
        selector: 'user_name',
        sortable: true,
        filterable: true,
      },
      {
        name: 'Phone Number',
        selector: 'full_phone',
        sortable: true,
        filterable: true,
      },
      {
        name: 'Email',
        selector: 'user_email',
        sortable: true,
        filterable: true,
      }
    ];

    const data = this.state.values;
    const tableData = {
      columns,
      data
    };

    
    return (
      <div id="content" className="main-content">
        <div className="layout-px-spacing">
          <div className="row layout-top-spacing2 layout-top-spacing">
            <div className="col-xl-12 col-lg-12 col-sm-12 layout-spacing2  layout-spacing">
              <div className="widget-content widget-content-area pg-top set_mini_height">
                {/* <div className="table-responsive mb-4 mt-1"> */}
                    <div className="top_heading_sty">
                        <p className="table-head">User Notification</p> 
                    </div>
                    <p className="row col-md-12 offset-md-2"><span>* Note: Select Customer or tech support and select user from table to send notification</span></p>
                    <div className="row col-md-12 offset-md-2">
                        <div class="form-group mb-0 form_cut_dtyle">
                            {/* <label for="admin_user_name">Select User Type</label> */}
                            <select
                              value={this.state.optionsState}
                              name="customer_id"
                              onChange={(e) => this.setState({ optionsState: e.target.value }) }
                              autoComplete="off" class="form-control  basic"
                            >
                              <option value=""> Select user type</option>
                              <option value="customer">Customer</option>
                              <option value="techsupport">Tech Support</option>
                            </select>
                            <span class="frm-err-msg m-t-25">{this.state.selectUserError}</span>
                        </div>
                         <div class="form-group mb-0 form_cut_dtyle">
                         {/* <p onClick={() => this.submitFormHandler()} className="ml-1 cust_top btn btn-primary">
                            Search
                        </p> */}
                         <p onClick={() => this.submitFormHandler()} className="ml-1 btn btn-primary">
                            Search
                        </p>
                        </div>
                    </div>


                    {this.state.showtable ===true ? (
                      <>
                        <div class="form-group col-xl-8 col-lg-8 col-sm-8 offset-md-2 m-t-5" id="">
                          {/* <label for="country" className="m-l-25">Message</label>
                          <textarea class="form-control m-l-25" onChange={this.onchangeHandler} name="userMessage" id="userMessage" placeholder="Message"></textarea>
                          <span class="frm-err-msg m-l-25 m-t-25">{this.state.userMessageError}</span> */}
                          
                          <label for="Image" className="m-t-15">Image</label>
                          <input type="file" class="form-control m-b-15" onChange={this.handleImagePreview} name="image_url" id="image_url" />

                          <label for="Message">Message</label>
                          <textarea class="form-control" onChange={this.onchangeHandler} name="userMessage" id="userMessage" placeholder="Message"></textarea>
                          <span class="frm-err-msg m-t-25">{this.state.userMessageError}</span>

                          <p onClick={() => this.submitNotification()} className="ml-1 btn btn-primary pull-right m-t-15"> Submit </p>
                          <p class="frm-err-msg pull-right m-t-25">{this.state.userSelectError}</p>
                        </div>
                      </>
                    ) :
                    null
                    }

                    {this.state.showtable ===true ? (
                        <div >
                            <DataTableExtensions {...tableData}
                                print={false}
                                exportHeaders={true}
                                pdf={true}>
                                <DataTable
                                    columns={columns}
                                    data={data}
                                    pagination
                                    selectableRows
                                    highlightOnHover
                                    onSelectedRowsChange={this.handleChange}
                                />
                            </DataTableExtensions>
                        </div>
                    ) :
                    (
                      null
                        // <div className="cust_style_dislaynone">
                        //   <p>Select user type to send notification</p>
                        // </div>
                    )}

                {/* </div> */}
              </div>
            </div>
          </div>
        </div>

        <div className="footer-wrapper">
          <div className="footer-section f-section-1">
            <p className="">
              Copyright © 2020{" "}
              <a target="_blank" href="javascript:void(0);">
                TSAS
              </a>
              , All rights reserved.
            </p>
          </div>
        </div>
      </div>
    );
  }
}
export default CompUserNotification;
