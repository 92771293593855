import $ from "jquery";
import React, { Component,useState  } from "react";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Link } from "react-router-dom";
import { axiosInstance } from "../../Services/PostData";
import "./CompReports.css";
import DataTable from 'react-data-table-component';
import DataTableExtensions from "react-data-table-component-extensions";
import DatePicker from "react-datepicker"; 
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';

const colourOptions = [];

class CompTechReports extends Component {
  constructor() {
    super();
    this.state = {
      values: [],      
      country_values: [],
      optionsState:'',
      showtable:false,
      from_date:new Date(),
      to_date:new Date(),
    };
    this.getNotifications = this.getNotifications.bind(this);
    // this.getCountryPrefix = this.getCountryPrefix.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    // this.getNotifications();
    this.getTechiesDetails();
  }

  async getNotifications() {

    const jsonValue = { tech_user_id: this.state.optionsState };
    axiosInstance
      .post(`techsupportwisereport`, jsonValue)
      .then((res) => {
        if (res.data.status === "success" && res.data.statusCode === "200") {
          let response = res.data.report_details;
            
          let response_Details = response.map((value) => {
            return value;
          });
          this.setState({ values: response_Details,showtable:true });
        }
      })
      .catch((err) => console.log("Login: ", err));
  }

  submitFormHandler()
  {
      this.getNotifications();
  }

  getTechiesDetails() {
    axiosInstance
      .get(`getTechiesDetails`)
      .then((res) => {
        if (res.data.status === "success" && res.data.statusCode === "200") {
          let countryResponse = res.data.user_details;
          let country_response_Details = countryResponse.map((value) => {
            return value;
          });
          this.setState({ country_values: country_response_Details });

          colourOptions.push(countryResponse);

          let spinner = "loadercustumspinner";
          let overlay = "loadingcustumspinner hide__container";
          this.setState({ spinner, overlay });
        }
      })
      .catch((err) => console.log(err));
  }

 handleChange = (selectedOptions) => {
    // console.log(selectedOptions.user_id)
    this.setState({optionsState: selectedOptions.user_id})
  }

  render() {

    const columns = [
      {
        name: 'Date',
        selector: 'cus_ticket_created_date',
        sortable: true,
        filterable: true,
      },
      {
        name: 'Fixes ID',
        selector: 'cus_ticket_id',
        sortable: true,
        filterable: true,
      },
      {
        name: 'Created By',
        selector: 'cus_user_name',
        sortable: true,
        filterable: true,
      },
      {
        name: 'Assigned To',
        selector: 'tech_user_name',
        sortable: true,
        filterable: true,
      },
      {
        name: 'Status',
        selector: 'cus_ticket_assign_status',
        sortable: true,
        filterable: true,
      },
      {
        name: 'Tech Amount',
        selector: 'tech_amount',
        sortable: true,
        filterable: true,
      },
      {
        name: 'Customer Amount',
        selector: 'cus_amount',
        sortable: true,
        filterable: true,
      },
    ];

    const data = this.state.values;
    const tableData = {
      columns,
      data
    };
    // const [startDate, setStartDate] = useState(new Date());
    // var f1 = flatpickr(document.getElementById('basicFlatpickr'));
    return (
      <div id="content" className="main-content">
        <div className="layout-px-spacing">
          <div className="row layout-top-spacing2 layout-top-spacing">
            <div className="col-xl-12 col-lg-12 col-sm-12 layout-spacing2  layout-spacing">
              <div className="widget-content widget-content-area pg-top set_mini_height">
                {/* <div className="table-responsive mb-4 mt-1"> */}
                    <div className="top_heading_sty">
                        <p className="table-head">Tech Support Report</p>
                    </div>
                        <div class="form-group mb-0 form_cut_dtyle">
                            <label for="admin_user_name">Tech Support</label>
                            {/* <select
                              value={this.state.optionsState}
                              name="customer_id"
                              onChange={(e) =>
                                this.setState({ optionsState: e.target.value })
                              }
                              autoComplete="off" class="form-control  basic"
                            >
                              
                              <option value=""> Select Tech Support</option>
                              {this.state.country_values.map((team) => (
                                <>
                                <option
                                  key={team.user_id}
                                  value={team.user_id}
                                >
                                  {" "}
                                  {team.user_name}{" "}
                                </option>
                                </>
                                // 

                              ))}
                            </select> */}

                            <Select
                              className="basic-single"
                              classNamePrefix="select"
                              name="color"
                              options={this.state.country_values}
                              onChange={this.handleChange}
                            />

                        </div>
                         <div class="form-group mb-0 form_cut_dtyle">
                         <p
                            onClick={() => this.submitFormHandler()}
                            className="ml-1 cust_top btn btn-primary">
                            Search
                        </p>
                         </div>
                        


                    {this.state.showtable ===true ? (
                        <div >
                            <DataTableExtensions {...tableData}
                                print={false}
                                exportHeaders={true}
                                pdf={true}>
                                <DataTable
                                    columns={columns}
                                    data={data}
                                    noHeader
                                    defaultSortField="id"
                                    defaultSortAsc={false}
                                    pagination
                                    highlightOnHover
                                />
                            </DataTableExtensions>
                        </div>
                    ) :
                    (
                        <div className="cust_style_dislaynone">
                          <p>Select Tech Support To Get the Reports</p>
                        </div>
                    )}

                {/* </div> */}
              </div>
            </div>
          </div>
        </div>

        <div className="footer-wrapper">
          <div className="footer-section f-section-1">
            <p className="">
              Copyright © 2020{" "}
              <a target="_blank" href="javascript:void(0);">
                TSAS
              </a>
              , All rights reserved.
            </p>
          </div>
        </div>
      </div>
    );
  }
}
export default CompTechReports;
