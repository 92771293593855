import React from 'react';
import TopBar from '../../Component/TopBar/TopBar'
import LeftSideBar from '../../Component/LeftSideBar/LeftSideBar'
import FixesClosed from '../../Component/Fixes/ClosedFixes/FixesClosed'

export default function ClosedFixes() {
 return (
        <div >
            <TopBar />
            <LeftSideBar />
            <div class="main-container" id="container">

                <div class="overlay"></div>
                <div class="search-overlay"></div>

                <FixesClosed />
            </div>
       </div>
    )
}