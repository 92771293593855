import React, { Component } from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Link, Redirect } from 'react-router-dom';
import { axiosInstance } from '../../../Services/PostData';
import './CountryForm.css';

class CompSkills extends Component {

    constructor(){
		super();
		this.state = {
            prefix: '',
            country_name: '',
            mobile_length: ''
        };
		this.submitFormHandler = this.submitFormHandler.bind(this);
		this.onChangeEvent = this.onChangeEvent.bind(this);
    }
    
    componentDidMount() {
        let query = window.location.search.substring(1);
        if(query != '') {
            let pair = query.split("=");
            var queryString = pair[0];
            var queryValue = pair[1];
            this.getSkillCategoryDetails(queryString, queryValue);
        }
     }

    getSkillCategoryDetails(value, id) {
        let spinner = "loadercustumspinner";
        let overlay = "loadingcustumspinner";
        this.setState({ spinner, overlay });

        const jsonValue = { country_id: id};
        axiosInstance.post(`getEditCountry`, jsonValue)
        .then(res => {
        if(res.data.status === 'success' && res.data.statusCode ==='200' )
        {
            let country_id = res.data.country_details[0].country_id;
            let prefix = res.data.country_details[0].prefix;
            let country_name = res.data.country_details[0].country_name;
            let mobile_length = res.data.country_details[0].mobile_length;
            this.setState({country_id, prefix, country_name, mobile_length});
            let spinner = "loadercustumspinner";
            let overlay = "loadingcustumspinner hide__container";
            this.setState({ spinner, overlay });
        }
        })
        .catch(err => console.log('Login: ', err));
    }
    onChangeEvent(e) {
		this.setState({[e.target.name]: e.target.value})
	}

    validateFunction = () => {
        let prefixError = "";
        let countryNameError = "";
        let mobileLengthError = "";

		if (!this.state.prefix) {
			prefixError = "Prefix Name cannot be blank";
		}
		if (!this.state.country_name) {
			countryNameError = "Country Name cannot be blank";
		}
		if (!this.state.mobile_length) {
			mobileLengthError = "Mobile Length cannot be blank";
		}

		if (prefixError || countryNameError || mobileLengthError) {
		  this.setState({prefixError, countryNameError, mobileLengthError});
		  return false;
		}
	
		return true;
    }

    submitFormHandler() {
        const isValid = this.validateFunction();

        if(isValid) {
            //show spinner 
            let spinner = "loadercustumspinner";
            let overlay = "loadingcustumspinner";
            this.setState({ spinner, overlay });

            let query = window.location.search.substring(1);

             //New submit (Mode = Update )
            if(query != '') {
                const jsonValue = { 
                    country_id: this.state.country_id, 
                    prefix: this.state.prefix,
                    country_name: this.state.country_name,
                    mobile_length: this.state.mobile_length 
                };
                axiosInstance.post(`editCountry`, jsonValue)
                .then(res => {
                if(res.data.status === 'success' && res.data.statusCode ==='200' )
                {
                    let spinner = "loadercustumspinner";
                    let overlay = "loadingcustumspinner hide__container";
                    this.setState({ spinner, overlay });

                    this.setState({countryRedirect: true});
                }
                })
                .catch(err => console.log('Login: ', err));
            }
            //New submit (Mode = New )
            else {
                const jsonValue = { 
                    prefix: this.state.prefix,
                    country_name: this.state.country_name,
                    mobile_length: this.state.mobile_length
                };

                axiosInstance.post(`addCountry`, jsonValue)
                .then(res => {
                if(res.data.status === 'success' && res.data.statusCode ==='200' )
                {
                    let spinner = "loadercustumspinner";
                    let overlay = "loadingcustumspinner hide__container";
                    this.setState({ spinner, overlay });

                    this.setState({countryRedirect: true});
                }
                })
                .catch(err => console.log('Login: ', err));
            }


        }
    }

render() {

    if (this.state.countryRedirect) {
        return <Redirect to="/Country"/>;
    }

    return (
        <div id="content" className="main-content">
            
            <div className="container formcustom" >
                <div className="container">
                    <div className="row layout-top-spacing" id="cancel-row">
                        <div style={{marginTop: '100px'}} id="ftFormArray" className="col-lg-12 layout-spacing">
                            <div className="statbox widget box box-shadow">
                                <div className="widget-header">                                
                                    <div className="row">
                                        <div className="col-xl-12 col-md-12 col-sm-12 col-12">
                                            <h4>Add Country</h4> 
                                        </div>
                                    </div>
                                </div>
                                <div className="widget-content widget-content-area custom-autocomplete h-100">
                                    <form id="formid">
                                        <div className="form-group mb-4">
                                            <label for="country">Prefix</label>
                                            <input type="text" className="form-control" autoComplete='off' value={this.state.prefix}
                                                    name="prefix" onChange={this.onChangeEvent} placeholder="Enter Prefix" />
                                        </div>
                                        <span  className="m-b-5 m-t-15" style={{ fontSize: 12, fontWeight: "bold", color: "#d82b2a" }}>
                                            {this.state.prefixError}
                                        </span>
                                        
                                        <div className="form-group mb-4">
                                            <label for="country">Country Name</label>
                                            <input type="text" className="form-control" autoComplete='off' value={this.state.country_name}
                                                    name="country_name" onChange={this.onChangeEvent} placeholder="Enter Country Name" />
                                        </div>
                                        <span  className="m-b-5 m-t-15" style={{ fontSize: 12, fontWeight: "bold", color: "#d82b2a" }}>
                                            {this.state.countryNameError}
                                        </span>
                                        
                                        <div className="form-group mb-4">
                                            <label for="country">Mobile Length</label>
                                            <input type="text" className="form-control" autoComplete='off' value={this.state.mobile_length}
                                                    name="mobile_length" onChange={this.onChangeEvent} placeholder="Enter Mobile Length" />
                                        </div>
                                        <span  className="m-b-5 m-t-15" style={{ fontSize: 12, fontWeight: "bold", color: "#d82b2a" }}>
                                            {this.state.mobileLengthError}
                                        </span>

                                        <Link onClick={() => this.submitFormHandler()} className="ml-1 fr btn btn-primary">Submit</Link>
                                        <Link to="/Country"className="fr btn btn-danger">Cancel</Link>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className="footer-wrapper">
                <div className="footer-section f-section-1">
                    <p className="">Copyright © 2020 <a target="_blank" href="javascript:void(0);">TSAS</a>, All rights reserved.</p>
                </div>
            </div> */}

<div className={this.state.overlay}>
          <div className={this.state.spinner}></div>
        </div>

        </div>
    )
}
}
export default CompSkills