import React from 'react';
import ResetPasswordComp from '../Component/Password/RestPasswordComp'

export default function EditUsers() {
    return (
        <div >
            <div class="overlay"></div>
            <div class="search-overlay"></div>
            <ResetPasswordComp />
       </div>
    )
}