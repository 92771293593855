import React from 'react';
import LeftSideBar from '../../Component/LeftSideBar/LeftSideBar';
import TopBar from '../../Component/TopBar/TopBar';
import EditTechSupport from '../../Component/Users/TechSupport/EditTechForm';

export default function EditTechSupportt() {
    return (
        <div >
            <TopBar />
            <LeftSideBar />
            <div class="main-container" id="container">

                <div class="overlay"></div>
                <div class="search-overlay"></div>

                <EditTechSupport />
            </div>
       </div>
    )
}