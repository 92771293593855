import $ from "jquery";
import React, {useState, useEffect} from "react";
// import { confirmAlert } from 'react-confirm-alert';
// import "react-confirm-alert/src/react-confirm-alert.css";
import { Link } from 'react-router-dom';
import { axiosInstance } from "../../Services/PostData";
import DataTable from 'react-data-table-component';
import DataTableExtensions from "react-data-table-component-extensions";
import swal from 'sweetalert2';
import { confirmAlert } from "react-confirm-alert";

function Company(props) {
  
    const [companyList, setCompanyList] = useState([]);

    useEffect(() => {
            getCompanyList();
    }, []);

    const getCompanyList = () => {
          axiosInstance
          .get(`getCompany`)
          .then((res) => {
            if (res.data.status === "success" && res.data.statusCode === "200") {
              let response = res.data.company_details;
              setCompanyList(response);
            }
          })
          .catch((err) => console.log("Login: ", err));
    }

    const columns = [
        {
          name: 'Company Name',
          selector: 'c_name',
          sortable: true,
          filterable: true,
        },
        {
          name: 'Email',
          selector: 'c_email',
          sortable: true,
          filterable: true,
        },
        {
          name: 'Phone Number',
          selector: 'c_phone',
          sortable: true,
          filterable: true,
        },
        {
          name: 'Logo',
          selector: (row, index) => {
              return <img src={window.$baseUrl+'uploads/company/'+row.c_logo_url} width="100" />
          },
          sortable: true,
          filterable: true,
        },
        // {
        //   name: 'Status',
        //   selector: 'user_active_status',
        //   sortable: true,
        //   filterable: true,
        // },
        // {
        //   name: 'Details',
        //   selector: (row, index) => {
        //       return <p class="cursorpointer" onClick={() => this.openUserDetailsModal(row.user_id) } >  View </p>
        //   },
        //   sortable: true,
        //   filterable: true,
        // },
        // {
        //   name: 'Suspend / Un-suspend',
        //   selector: (row, index) => {
        //     if(row.suspend_flag == 0) {
        //       return <p class="Suspend_user" onClick={() => this.suspendFucntion(row.user_id) } > Suspend </p>
        //     }
        //     else {
        //       return <p class="unSuspend_user" onClick={() => this.unsuspendFucntion(row.user_id) } >  Un-Suspend </p>
        //     }
        //   },
        //   sortable: true,
        //   filterable: true,
        // },
        {
          name: 'Action',
          selector: (row, index) => {

              return  <div>
                        
                        <Link to={{ pathname: '/EditCompany', search: '?id='+ btoa(row.company_id)  }} >
                          <i 
                            class="fa fa-pencil text-success font-18 cursorpointer" 
                            aria-hidden="true"
                            // onClick={() => editFunctionRedirect(row.company_id) } 
                          ></i>
                        </Link>

                        <i class="fa fa-trash text-danger font-18 cursorpointer m-l-10" aria-hidden="true"
                          onClick={() => deleteFucntion(row.company_id) } ></i>
                      </div>;
            
          },
          sortable: true,
          filterable: true,
        }
      ];
  
      const data = companyList;
      const tableData = {
        columns,
        data
      };

      const deleteFucntion = (company_id) => {
          confirmAlert({
            message: 'Are you sure to delete this one?',
            buttons: [
              {
                label: 'Yes',
                onClick: () => {
                  axiosInstance
                  .post(`deleteCompany`, { company_id })
                  .then(res => {
                    if(res.data.status === 'success' && res.data.statusCode ==='200' )
                    {
                      getCompanyList();
                      suggestionMessage('success','congratulations!','Deleted successfully!');
                    }else{
                      suggestionMessage('info','Warning!','Something went wrong!');
                    }
                  })
                  .catch(err => console.log(err));
                }
              },
              {
                label: 'No'
              }
            ]
        });
      }

      const suggestionMessage = (type, title, message) => {
        swal.fire({
            type: type,
            title: title,
            text: message,
            confirmButtonText: "ok",
            confirmButtonColor: "#D82B2A",
            denyButtonText : "cancel",
            denyButtonColor : "#D82B2A"
        })
        .then((result) => {
            console.log(result);
        });
    }

    return (
        <div id="content" className="main-content">
        <div className="layout-px-spacing">
          <div className="row layout-top-spacing2 layout-top-spacing">
            <div className="col-xl-12 col-lg-12 col-sm-12 layout-spacing2  layout-spacing">
              <div className="widget-content widget-content-area pg-top">
                <div className="table-responsive mb-4 mt-1">
                  <p className="table-head table-head-no-add">Company</p>
                  <div class="d-flex Fixes_addbtn justify-content-sm-end justify-content-center add_btn_sty">
                          <Link to="/AddCompany" className="add_btn_icon_style">
                            Add New
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              class="feather feather-plus-square"
                            >
                              <rect
                                x="3"
                                y="3"
                                width="18"
                                height="18"
                                rx="2"
                                ry="2"
                              ></rect>
                              <line x1="12" y1="8" x2="12" y2="16"></line>
                              <line x1="8" y1="12" x2="16" y2="12"></line>
                            </svg>
                            <a></a>
                          </Link>
                        </div>
                    <DataTableExtensions {...tableData}
                        print={false}
                        exportHeaders={true}
                        pdf={true}
                        >
                        <DataTable
                            columns={columns}
                            data={data}
                            noHeader
                            defaultSortAsc={false}
                            pagination
                            highlightOnHover
                            expandableRows={true}
                            // expandableRowsComponent={<SampleExpandedComponent />}
                            expandOnRowClicked
                        />
                    </DataTableExtensions>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="UserDetails" className="modal animated fadeInRight custo-fadeInRight show" role="dialog" aria-modal="true">
                <div className="modal-dialog-content modal-dialog" style={{width: '55%'}}>
                    <div className="modal-background-color modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">FixCustomer Details</h5>
                    </div>
                        <div className="modal-body">

                        <div>
                        {companyList.map(user_details => ( 
                        <div>
                            <p>
                              <span className="tc_user_lbl">Name</span> 
                              <span className="tc_lbl_colon">: </span>
                              <span className="tc_lbl_content">{user_details.user_name}</span>
                            </p>
                            <p>
                              <span className="tc_user_lbl">Email</span> 
                              <span className="tc_lbl_colon">: </span>
                              <span className="tc_lbl_content">{user_details.user_email}</span>
                            </p>
                            <p>
                              <span className="tc_user_lbl">Phone Number</span> 
                              <span className="tc_lbl_colon">: </span>
                              <span className="tc_lbl_content">{user_details.user_full_phone}</span>
                            </p>
                            <p>
                              <span className="tc_user_lbl">Gender</span> 
                              <span className="tc_lbl_colon">: </span>
                              <span className="tc_lbl_content">{user_details.user_gender}</span>
                            </p>
                            <p>
                              <span className="tc_user_lbl">Country</span> 
                              <span className="tc_lbl_colon">: </span>
                              <span className="tc_lbl_content">{user_details.user_country}</span>
                            </p>
                            <p>
                              <span className="tc_user_lbl">Suspend Status</span> 
                              <span className="tc_lbl_colon">: </span>
                              <span className="tc_lbl_content">{user_details.user_suspend_status}</span>
                            </p>
                        </div> 
                        ))}
                        </div>
                            
                        </div>
                        <div className="modal-footer md-button">
                            <button className="btn" data-dismiss="modal" >
                              <i className="flaticon-cancel-12"></i> Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>


        {/* <div className={this.state.overlay}>
          <div className={this.state.spinner}></div>
        </div> */}
      </div>
    
    );
}

export default Company;