import React from 'react';
import BannerAdd from '../../Component/Banner/AddBanner/BannerAdd';
import LeftSideBar from '../../Component/LeftSideBar/LeftSideBar';
import TopBar from '../../Component/TopBar/TopBar';


export default function AddAvatar() {
    return (
        <div >
            <TopBar />
            <LeftSideBar />
            <div class="main-container" id="container">

                <div class="overlay"></div>
                <div class="search-overlay"></div>

                <BannerAdd />
            </div>
       </div>
    )
}