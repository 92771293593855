import $ from "jquery";
import React, { Component } from "react";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Link, Redirect } from "react-router-dom";
import swal from 'sweetalert2';
import { axiosInstance } from "../../Services/PostData";
import "./AddUserForm.css";

class AddUserForm extends Component {
  constructor() {
    super();
    this.state = {
      admin_user_name: "",
      admin_user_phone: "",
      admin_user_email: "",
      admin_password: "",
      confirm_password: "",
      admin_role_id: "",
      optionsState: "",
      role_values: [],
      country_values: [],
      hiddenClassName: "",
      redirecturl: "/Dashboard",
    };
    this.submitFormHandler = this.submitFormHandler.bind(this);
    this.getCountryPrefix = this.getCountryPrefix.bind(this);
    this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  componentDidMount() {
    //show spinner
    let spinner = "loadercustumspinner";
    let overlay = "loadingcustumspinner";
    this.setState({ spinner, overlay });
    let query = window.location.search.substring(1);
    if (query != "") {
      let pair = query.split("=");
      var queryString = pair[0];
      var queryValue = pair[1];
      this.getUserDetailss(queryString, queryValue);
    }
    this.getRoleDetails();
    this.getCountryDetails();
  }

  getRoleDetails() {
    axiosInstance
      .get(`getRoles`)
      .then((res) => {
        if (res.data.status === "success" && res.data.statusCode === "200") {
          let roleResponse = res.data.role_details;
          let role_response_Details = roleResponse.map((value) => {
            return value;
          });
          this.setState({ role_values: role_response_Details });
        }
      })
      .catch((err) => console.log(err));
  }

  getCountryDetails() {
    axiosInstance
      .get(`getCountry`)
      .then((res) => {
        if (res.data.status === "success" && res.data.statusCode === "200") {
          let countryResponse = res.data.country_details;
          let country_response_Details = countryResponse.map((value) => {
            return value;
          });
          this.setState({ country_values: country_response_Details });
          this.state.mobile_number_count =
            country_response_Details[0].mobile_length;
          this.state.optionsState = country_response_Details[0].prefix;
          let spinner = "loadercustumspinner";
          let overlay = "loadingcustumspinner hide__container";
          this.setState({ spinner, overlay });
        }
      })
      .catch((err) => console.log(err));
  }

  getCountryPrefix(e) {
    this.state.optionsState = e.target.value;
    this.state.mobile_number_count = e.target.selectedOptions[0].getAttribute(
      "data-length"
    );

    // alert(this.state.mobile_number_count);
    // console.log(this.state.prefix);
  }

  getUserDetailss(value, id) {
    //show spinner
    let spinner = "loadercustumspinner";
    let overlay = "loadingcustumspinner";
    this.setState({ spinner, overlay });
    const jsonValue = { id: id };
    axiosInstance
      .post(`getUserDetails`, jsonValue)
      .then((res) => {
        if (res.data.status === "success" && res.data.statusCode === "200") {
          let hiddenClassName = "hide__container";
          let admin_user_id = res.data.user_details[0].admin_user_id;
          let admin_user_name = res.data.user_details[0].admin_user_name;
          let admin_user_email = res.data.user_details[0].admin_user_email;
          let admin_user_phone = res.data.user_details[0].admin_user_phone;
          let admin_role_id = res.data.user_details[0].admin_role_id;
          let optionsState = res.data.user_details[0].prefix;
          let mobile_number_count = res.data.user_details[0].mobile_length;
          let redirecturl = "";

          if (admin_role_id == 1) {
            redirecturl = "/UserSuperAdmin";
          } else if (admin_role_id == 2) {
            redirecturl = "/SiteAdmin";
          } else if (admin_role_id == 3) {
            redirecturl = "/SupportAdmin";
          }

          setTimeout(function () {
            $("#radioRoleBtn" + admin_role_id).prop("checked", true);
          }, 100);

          let spinner = "loadercustumspinner";
          let overlay = "loadingcustumspinner hide__container";
          this.setState({ spinner, overlay });

          // alert('radioRoleBtn'+admin_role_id)
          this.setState({
            hiddenClassName,
            admin_user_id,
            admin_user_name,
            admin_user_email,
            admin_user_phone,
            admin_role_id,
            optionsState,
            redirecturl,
            mobile_number_count
          });
        }
      })
      .catch((err) => console.log("Login: ", err));
  }
  onChangeHandler(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  validateFunction() {
    let nameError = "";
    let phoneError = "";
    let emailError = "";
    let passwordError = "";
    let confirmPasswordError = "";
    let roleError = "";

    if (!this.state.admin_user_name) {
      nameError = "Name cannot be blank";
    }
    if (!this.state.admin_user_phone) {
      phoneError = "Enter Mobile Number";
    } else if (
      this.state.admin_user_phone.length != this.state.mobile_number_count
    ) {
      phoneError =
        "Your mobile number should be " +
        this.state.mobile_number_count +
        " Digit";
    }

    if (!this.state.admin_user_email) {
      emailError = "Email cannot be blank";
    }

    // password
    const strongRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
    );
    if (this.state.admin_password.length < 8) {
      passwordError = "Password should be 8 characters";
    } else if (!strongRegex.test(this.state.admin_password)) {
      passwordError = "use special character, number and capital letter";
    }
    //confirm password
    if (this.state.admin_password !== this.state.confirm_password) {
      confirmPasswordError = "Wrong password";
    }
    if (!this.state.admin_role_id) {
      roleError = "Choose role";
    }

    if (
      nameError ||
      phoneError ||
      emailError ||
      passwordError ||
      confirmPasswordError ||
      roleError
    ) {
      this.setState({
        nameError,
        phoneError,
        emailError,
        passwordError,
        confirmPasswordError,
        roleError,
      });
      return false;
    }

    return true;
  }

  editValidateFunction() {
    let nameError = "";
    let phoneError = "";
    let emailError = "";
    let roleError = "";

    if (!this.state.admin_user_name) {
      nameError = "Name cannot be blank";
    }
    if (!this.state.admin_user_phone) {
      phoneError = "Enter Mobile Number";
    } else if (
      this.state.admin_user_phone.length != this.state.mobile_number_count
    ) {
      phoneError =
        "Your mobile number should be " +
        this.state.mobile_number_count +
        " Digit";
    }

    if (!this.state.admin_user_email) {
      emailError = "Email cannot be blank";
    }

    if (!this.state.admin_role_id) {
      roleError = "Choose role";
    }

    if (nameError || phoneError || emailError || roleError) {
      this.setState({ nameError, phoneError, emailError, roleError });
      return false;
    }

    return true;
  }

  submitFormHandler() {
    let query = window.location.search.substring(1);

    if (query != "") {
      const isValid = this.editValidateFunction();
      if (isValid) {
        //show spinner
        let spinner = "loadercustumspinner";
        let overlay = "loadingcustumspinner";
        this.setState({ spinner, overlay });

        //  update submit (Mode = Update )
        const jsonValue = {
          id: this.state.admin_user_id,
          admin_role_id: this.state.admin_role_id,
          admin_user_name: this.state.admin_user_name,
          admin_user_email: this.state.admin_user_email,
          admin_user_phone: this.state.admin_user_phone,
          prefix: this.state.optionsState,
        };

        // console.log(jsonValue);
        axiosInstance
          .post(`editUsers`, jsonValue)
          .then((res) => {
            if (
              res.data.status === "success" &&
              res.data.statusCode === "200"
            ) {
              let spinner = "loadercustumspinner";
              let overlay = "loadingcustumspinner hide__container";
              this.setState({ spinner, overlay });
              this.setState({ userRedirect: true });
            } else if (
              res.data.status === "success" &&
              res.data.statusCode === "201"
            ) {
              let messageError = res.data.msg;
              this.setState({ messageError });
            }
          })
          .catch((err) => console.log("Login: ", err));
      }
    } else {
      const isValid = this.validateFunction();
      if (isValid) {
        //show spinner
        let spinner = "loadercustumspinner";
        let overlay = "loadingcustumspinner";
        this.setState({ spinner, overlay });

        //  update submit (Mode = Update )
        const jsonValue = {
          admin_role_id: this.state.admin_role_id,
          admin_user_name: this.state.admin_user_name,
          admin_password: this.state.admin_password,
          admin_user_email: this.state.admin_user_email,
          admin_user_phone: this.state.admin_user_phone,
          prefix: this.state.optionsState,
        };
        // console.log(jsonValue);
        axiosInstance
          .post(`InsertUsers`, jsonValue)
          .then((res) => {
            if (
              res.data.status === "success" &&
              res.data.statusCode === "200"
            ) {
              let spinner = "loadercustumspinner";
              let overlay = "loadingcustumspinner hide__container";
              this.setState({ spinner, overlay });
              swal
                .fire({
                  type: "info",
                  title: "User Created Successfuly",
                  // text: 'do you want to continuew',
                  confirmButtonText: "ok",
                  confirmButtonColor: "#D82B2A",
                })
                .then((result) => {
                  this.setState({ userRedirect: true });
                });
            } else if (
              res.data.status === "success" &&
              res.data.statusCode === "201"
            ) {
              let messageError = res.data.msg;
              this.setState({ messageError });
            }
          })
          .catch((err) => console.log("Login: ", err));
      }
    }
  }

  render() {
    if (this.state.userRedirect) {
      return <Redirect to={this.state.redirecturl} />;
    }

    // console.log(this.state.admin_role_id);
    return (
      <div id="content" className="main-content">
        <div className="container formcustom">
          <div className="container">
            <div className="row layout-top-spacing" id="cancel-row">
              <div id="ftFormArray" className="col-lg-12 layout-spacing">
                <div className="statbox widget box box-shadow">
                  <div className="widget-header">
                    <div className="row">
                      <div className="col-xl-12 col-md-12 col-sm-12 col-12">
                        <h4>Add Users</h4>
                      </div>
                    </div>
                  </div>
                  <div className="widget-content widget-content-area custom-autocomplete h-100">
                    <form id="formid">
                      <div className="form-group mb-4">
                        <label for="admin_user_name">Name</label>
                        <input
                          type="text"
                          onChange={this.onChangeHandler}
                          className="form-control"
                          id="admin_user_name"
                          value={this.state.admin_user_name}
                          name="admin_user_name"
                          placeholder="Name"
                        />
                        <span className="m-b-5 m-t-15 frm-err-msg">
                          {this.state.nameError}
                        </span>
                      </div>

                      <div className="form-group">
                        <label for="country">Phone Number</label>
                        <div className="input-group mb-4">
                          <div className="selected-flag">
                            {/* <select onChange={this.getCountryPrefix}>
                                            {this.state.country_values.map(team => (
                                                <option key={team.country_id} data-length={team.mobile_length} value={team.prefix} > {team.prefix} </option>
                                            ))}
                                            </select> */}

                            <select
                              value={this.state.optionsState}
                              name="e_country"
                              onChange={(e) =>
                                this.setState({ optionsState: e.target.value, mobile_number_count : e.target.selectedOptions[0].getAttribute("data-length") })
                              }
                              autoComplete="off"
                            >
                              {this.state.country_values.map((team) => (
                                <option
                                  key={team.country_id}
                                  data-length={team.mobile_length}
                                  value={team.prefix}
                                >
                                  {" "}
                                  {team.prefix}{" "}
                                </option>
                              ))}
                            </select>
                          </div>
                          <input
                            type="text"
                            onChange={this.onChangeHandler}
                            className="form-control"
                            id="admin_user_phone"
                            name="admin_user_phone"
                            value={this.state.admin_user_phone}
                            placeholder="Phone Number"
                          />
                        </div>
                        <span className="m-b-5 m-t-15 frm-err-msg">
                          {this.state.phoneError}
                        </span>
                      </div>

                      <div className="form-group mb-4">
                        <label for="email1">Email address</label>
                        <input
                          type="email"
                          onChange={this.onChangeHandler}
                          className="form-control"
                          name="admin_user_email"
                          value={this.state.admin_user_email}
                          id="email"
                          aria-describedby="emailHelp1"
                          placeholder="Email"
                        />
                        <span className="m-b-5 m-t-15 frm-err-msg">
                          {this.state.emailError}
                        </span>
                      </div>

                      <div
                        className="form-group mb-4"
                        id={this.state.hiddenClassName}
                      >
                        <label for="country">Password</label>
                        <input
                          type="text"
                          onChange={this.onChangeHandler}
                          className="form-control"
                          name="admin_password"
                          id="admin_password"
                          placeholder="Password"
                        />
                        <span className="m-b-5 m-t-15 frm-err-msg">
                          {this.state.passwordError}
                        </span>
                      </div>

                      <div
                        className="form-group mb-4"
                        id={this.state.hiddenClassName}
                      >
                        <label for="country">Confirm Password</label>
                        <input
                          type="text"
                          onChange={this.onChangeHandler}
                          className="form-control"
                          name="confirm_password"
                          id="confirm_password"
                          placeholder="Confirm Password"
                        />
                        <span className="m-b-5 m-t-15 frm-err-msg">
                          {this.state.confirmPasswordError}
                        </span>
                      </div>

                      <div className="form-group">
                        <label style={{ marginBottom: "0px" }} for="country">
                          <b>Role</b>
                        </label>
                        <div
                          style={{ padding: "10px" }}
                          className="widget-content widget-content-area"
                        >
                          {/* value="male" checked={this.state.e_gender === 'male'} onChange={this.onGenderChange} /> */}

                          {this.state.role_values.map((value_details) => (
                            <div className="custom-control custom-radio custom-control-inline">
                              <input
                                type="radio"
                                value={value_details.admin_role_id}
                                checked={
                                  this.state.admin_role_id ===
                                  value_details.admin_role_id
                                }
                                id={
                                  "radioRoleBtn" + value_details.admin_role_id
                                }
                                onChange={this.onChangeHandler}
                                name="admin_role_id"
                                className="custom-control-input"
                              />
                              <label
                                className="custom-control-label"
                                for={
                                  "radioRoleBtn" + value_details.admin_role_id
                                }
                              >
                                {value_details.admin_role_name}
                              </label>
                            </div>
                          ))}
                        </div>
                        <span className="m-b-5 m-t-15 frm-err-msg">
                          {this.state.roleError}
                        </span>
                      </div>
                      <div className="m-b-5 m-t-15 frm-err-msg text-center">
                        {this.state.messageError}
                      </div>

                      <p
                        onClick={() => this.submitFormHandler()}
                        className="ml-1 fr btn btn-primary"
                      >
                        Submit
                      </p>
                      <Link
                        to={this.state.redirecturl}
                        className="fr btn btn-danger"
                      >
                        Cancel
                      </Link>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="footer-wrapper">
                <div className="footer-section f-section-1">
                    <p className="">Copyright © 2020 <Link target="_blank" href="javascript:void(0);">TSAS</Link>, All rights reserved.</p>
                </div>
            </div> */}

        <div className={this.state.overlay}>
          <div className={this.state.spinner}></div>
        </div>
      </div>
    );
  }
}
export default AddUserForm;
