import React from 'react';
import {Link} from 'react-router-dom';
import Logo from '../logo-ts.png';

export default function ForgotPassword() {
    return (
        <div class="limiter">
		<div class="container-login100">
			<div class="wrap-login100 p-l-50 p-r-50 p-t-37 p-b-30">
				<img class="loginlogo p-b-20" src={Logo} />
				
				<form class="login100-form validate-form">
					<span class="login100-form-title p-b-35">
						FORGoT PASSWORD 
					</span>

					<div class="wrap-input100 validate-input m-b-16" >
						<input id="email" class="input100" type="text" name="newpass" placeholder="New Password" />
						<span class="focus-input100"></span>
						<span class="symbol-input100">
							<span class="fa fa-lock"></span>
						</span>
					</div>

					<div class="wrap-input100 validate-input m-b-16" >
						<input id="pass" class="input100" type="password" name="conpass" placeholder="Confirm Password" />
						<span class="focus-input100"></span>
						<span class="symbol-input100">
							<span class="fa fa-lock"></span>
						</span>
					</div>
					
					<div class="container-login100-form-btn p-t-25">
						<a class="forget-btn login100-form-btn">
							Submit
						</a>
					</div>
					<div class="text-center w-full p-t-15">
						<a id="forget_id" class="txt1 bo1 hov1" href="#">
							<Link to="/">Login Page</Link>						
						</a>
					</div>

					<div class="text-center w-full p-t-42 p-b-22">
						<span class="txt1 error">Error</span>
						<span class="txt1 sucess">Login Sucessfully.</span>
					</div>
				</form>
			</div>
		</div>
	</div>
    )
}