import React from 'react';
import TopBar from '../../Component/TopBar/TopBar'
import LeftSideBar from '../../Component/LeftSideBar/LeftSideBar'
import CompTechSupportFeedback from '../../Component/Feedback/CompTechSupportFeedback'

export default function TechSupportFeedback() {
    return (
        <div >
            <TopBar />
            <LeftSideBar />
            <div class="main-container" id="container">
                <div class="overlay"></div>
                <div class="search-overlay"></div>
                <CompTechSupportFeedback />
            </div>
       </div>
    )
}