import React from 'react';
import TopBar from '../../../Component/TopBar/TopBar'
import LeftSideBar from '../../../Component/LeftSideBar/LeftSideBar'
import SkillForm from '../../../Component/Master/Skills/SkillForm'

export default function EditSkills() {
    return (
        <div >
            <TopBar />
            <LeftSideBar />
            <div class="main-container" id="container">

                <div class="overlay"></div>
                <div class="search-overlay"></div>

                <SkillForm />
            </div>
       </div>
    )
}