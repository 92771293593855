import React, { Component } from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Link, Redirect } from 'react-router-dom';
import { axiosInstance } from '../../Services/PostData';
import './RoleForm.css';
import swal from 'sweetalert2';

class RoleForm extends Component {

    constructor(){
		super();
		this.state = {
            admin_role_name: "",
            open_fixes: 0,
            private_fixes: 0,
            resolve_fixes: 0,
            close_fixes: 0,
            country_master: 0,
            skill_master: 0,
            super_admin: 0,
            site_admin: 0,
            support_admin: 0,
            tech_support: 0,
            customer: 0,
            reports: 0,
            feedback: 0,
            notification: 0,
            roles: 0,
            avatar: 0,
            banner: 0,
            support_chat: 0,
            editRendering: false
        };
		this.submitFormHandler = this.submitFormHandler.bind(this);
		this.onChangeEvent = this.onChangeEvent.bind(this);
		this.onCheckboxChange = this.onCheckboxChange.bind(this);
		this.getuserRolesDetails = this.getuserRolesDetails.bind(this);
    }
    
    componentDidMount() {
        let query = window.location.search.substring(1);
        if(query != '') {
            let pair = query.split("=");
            var queryString = pair[0];
            var queryValue = pair[1];
            this.getuserRolesDetails(queryString, queryValue);
            this.setState({editRendering: true});
        }
    }

    getuserRolesDetails(value, id) {
        const jsonValue = { id: id};
        axiosInstance.post(`getEditRoleDetails`, jsonValue)
        .then(res => {
        if(res.data.status === 'success' && res.data.statusCode ==='200' )
        {
            let admin_role_name = res.data.role_details[0].admin_role_name;
            let open_fixes = res.data.role_details[0].open_fixes;
            let close_fixes = res.data.role_details[0].close_fixes;
            let private_fixes = res.data.role_details[0].private_fixes;
            let resolve_fixes = res.data.role_details[0].resolve_fixes;
            let country_master = res.data.role_details[0].country_master;
            let skill_master = res.data.role_details[0].skill_master;
            let super_admin = res.data.role_details[0].super_admin;
            let site_admin = res.data.role_details[0].site_admin;
            let support_admin = res.data.role_details[0].support_admin;
            let tech_support = res.data.role_details[0].tech_support;
            let customer = res.data.role_details[0].customer;
            let reports = res.data.role_details[0].reports;
            let feedback = res.data.role_details[0].feedback;
            let notification = res.data.role_details[0].notification;
            let roles = res.data.role_details[0].roles;
            let avatar = res.data.role_details[0].avatar;
            let banner = res.data.role_details[0].banner;
            let support_chat = res.data.role_details[0].support_chat;

            this.setState({admin_role_name, open_fixes, close_fixes, private_fixes, resolve_fixes, country_master, skill_master, 
                super_admin, site_admin, support_admin, tech_support, customer, reports,feedback, notification, roles, avatar, banner, support_chat })
        }
        })
        .catch(err => console.log('Login: ', err));
    }
    onChangeEvent(e) {
		this.setState({[e.target.name]: e.target.value})
	}

    validateFunction = () => {
        let adminRoleNameError = "";

		if (!this.state.admin_role_name) {
			adminRoleNameError = "Admin Role Name cannot be blank";
		}

		if (adminRoleNameError) {
		  this.setState({adminRoleNameError});
		  return false;
		}
	
		return true;
    }


    onCheckboxChange(e) {
        var value = 0;
        if(e.target.checked == true) {
            value = 1;
        } else {
            value = 0;
        }
        // alert(e.target.name)
        this.setState({[e.target.name]: value});
    }

    submitFormHandler() {
        this.setState({adminRoleNameError: ''});
        const isValid = this.validateFunction();

        if(isValid) {
            //show spinner 
            let spinner = 'lds-dual-ring custom-screen-middle';
            let overlay = 'overlay';
            this.setState({ spinner, overlay });

            let query = window.location.search.substring(1);

            //New submit (Mode = Update )
            if(query != '') {

                let pair = query.split("=");
                let queryString = pair[0];
                let queryValue = pair[1];

                const jsonValue = { 
                    admin_role_id: queryValue, 
                    admin_role_name: this.state.admin_role_name,
                    open_fixes: this.state.open_fixes,
                    close_fixes: this.state.close_fixes,
                    private_fixes: this.state.private_fixes,
                    resolve_fixes: this.state.resolve_fixes,
                    country_master: this.state.country_master,
                    skill_master: this.state.skill_master,
                    super_admin: this.state.super_admin,
                    site_admin: this.state.site_admin,
                    support_admin: this.state.support_admin,
                    tech_support: this.state.tech_support,
                    customer: this.state.customer,
                    reports: this.state.reports,
                    feedback: this.state.feedback,
                    notification: this.state.notification,
                    roles: this.state.roles,
                    avatar: this.state.avatar,
                    banner: this.state.banner,
                    support_chat: this.state.support_chat,
                };
                axiosInstance.post(`editRoles`, jsonValue)
                .then(res => {
                if(res.data.status === 'success' && res.data.statusCode ==='200' )
                {
                    let spinner = 'lds-dual-ring custom-screen-middle hide__container';
                    let overlay = 'overlay hide__container';
                    this.setState({ spinner, overlay });

                    swal.fire({
                        type: "info",
                        text: 'updated Successfuly',
                        confirmButtonText: "ok",
                        confirmButtonColor: "#D82B2A",
                    })
                    .then((result) => {
                        this.setState({ rolesRedirect: true });
                    });

                }
                else if(res.data.status === 'success' && res.data.statusCode ==='201' ){
                    this.setState({adminRoleNameError: res.data.msg});
                }
                })
                .catch(err => console.log('Login: ', err));
            }
            //New submit (Mode = New )
            else {
                const jsonValue = { 
                    admin_role_name: this.state.admin_role_name,
                    open_fixes: this.state.open_fixes,
                    close_fixes: this.state.close_fixes,
                    private_fixes: this.state.private_fixes,
                    resolve_fixes: this.state.resolve_fixes,
                    country_master: this.state.country_master,
                    skill_master: this.state.skill_master,
                    super_admin: this.state.super_admin,
                    site_admin: this.state.site_admin,
                    support_admin: this.state.support_admin,
                    tech_support: this.state.tech_support,
                    customer: this.state.customer,
                    reports: this.state.reports,
                    feedback: this.state.feedback,
                    notification: this.state.notification,
                    roles: this.state.roles,
                    avatar: this.state.avatar,
                    banner: this.state.banner,
                    support_chat: this.state.support_chat,
                };

                // console.log(jsonValue);

                axiosInstance.post(`addRoles`, jsonValue)
                .then(res => {
                if(res.data.status === 'success' && res.data.statusCode ==='200' )
                {
                    let spinner = 'lds-dual-ring custom-screen-middle hide__container';
                    let overlay = 'overlay hide__container';
                    this.setState({ spinner, overlay });

                    //this.setState({rolesRedirect: true});
                    swal.fire({
                        type: "info",
                        text: 'added Successfuly',
                        confirmButtonText: "ok",
                        confirmButtonColor: "#D82B2A",
                    })
                    .then((result) => {
                        this.setState({ rolesRedirect: true });
                    });
                }
                else if(res.data.status === 'success' && res.data.statusCode ==='201' ){
                    this.setState({adminRoleNameError: res.data.msg});
                }
                })
                .catch(err => console.log('Login: ', err));
            }


        }
    }

render() {

    if (this.state.rolesRedirect) {
        return <Redirect to="/Roles"/>;
    }

    if( this.state.editRendering == false) { 
       
        return (
            <div id="content" className="main-content">
    
                <div className="container formcustom">
                <div className="container">
                    <div className="row layout-top-spacing" id="cancel-row">
                        <div id="ftFormArray" className="col-lg-12 layout-spacing">
                            <div className="statbox widget box box-shadow">
                                <div className="widget-header">
                                    <div className="row">
                                        <div className="col-xl-12 col-md-12 col-sm-12 col-12">
                                            <h4>Add Roles</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="widget-content widget-content-area custom-autocomplete h-100">
                                    <form id="formid">
                                        <div className="form-group mb-4">
                                            <label for="country">Role Name</label>
                                            <input type="text" className="form-control" autoComplete='off' name="admin_role_name" onChange={this.onChangeEvent} value={this.state.admin_role_name} placeholder="Enter role name" />
                                        </div>
                                        <span className="m-b-5 m-t-15" style={{ fontSize: 12, fontWeight: "bold", color: "#d82b2a" }}>
                                            {this.state.adminRoleNameError}
                                        </span>
        
        
                                        <div class="form-group">
                                            <label style={{marginBottom: '0px'}} for="country"><b>Fixes</b></label>
                                            <div style={{padding: '10px'}} class="widget-content widget-content-area">
        
        
                                                <div class="row">
                                                    <div class="col-lg-6 col-12">
                                                        <div class="custom-control custom-checkbox custom-control-inline">
                                                            <input type="checkbox" id="Fixes1" name="open_fixes" class="custom-control-input" onChange={this.onCheckboxChange} />
                                                            <label class="custom-control-label" for="Fixes1">Open Fixes</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6 col-12">
                                                        <div class="custom-control custom-checkbox custom-control-inline">
                                                            <input type="checkbox" id="Fixes2" name="private_fixes" class="custom-control-input"  onChange={this.onCheckboxChange}/>
                                                            <label class="custom-control-label" for="Fixes2">Private Fixes</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6 col-12">
                                                        <div class="custom-control custom-checkbox custom-control-inline">
                                                            <input type="checkbox" id="Fixes3" name="resolve_fixes" class="custom-control-input"  onChange={this.onCheckboxChange}/>
                                                            <label class="custom-control-label" for="Fixes3">Resolved Fixes</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6 col-12">
                                                        <div class="custom-control custom-checkbox custom-control-inline">
                                                            <input type="checkbox" id="Fixes4" name="close_fixes" class="custom-control-input" onChange={this.onCheckboxChange} />
                                                            <label class="custom-control-label" for="Fixes4">Closed Fixes</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
        
                                        <div class="form-group">
                                            <label style={{marginBottom: '0px'}} for="country"><b>Master</b></label>
                                            <div style={{padding: '10px'}} class="widget-content widget-content-area">
                                                <div class="row">
                                                    <div class="col-lg-6 col-12">
                                                        <div class="custom-control custom-checkbox custom-control-inline">
                                                            <input type="checkbox" id="master1" name="country_master" class="custom-control-input" onChange={this.onCheckboxChange}/>
                                                            <label class="custom-control-label" for="master1">Country Master</label>
                                                        </div>
                                                    </div>
                                                    
                                                    <div class="col-lg-6 col-12">
                                                        <div class="custom-control custom-checkbox custom-control-inline">
                                                            <input type="checkbox" id="master4" name="skill_master" class="custom-control-input" onChange={this.onCheckboxChange}/>
                                                            <label class="custom-control-label" for="master4">Skills Master</label>
                                                        </div>
                                                    </div>
    
                                                </div>
                                            </div>
                                        </div>
        
                                        <div class="form-group">
                                            <label style={{marginBottom: '0px'}} for="country"><b>Users</b></label>
                                            <div style={{padding: '10px'}}class="widget-content widget-content-area">
                                                
                                                <div class="row">
    
                                                    <div class="col-lg-6 col-12">
                                                        <div class="custom-control custom-checkbox custom-control-inline">
                                                            <input type="checkbox" id="page1" name="super_admin" class="custom-control-input" onChange={this.onCheckboxChange}/>
                                                            <label class="custom-control-label" for="page1">Super Admin</label>
                                                        </div>
                                                    </div>
    
                                                    <div class="col-lg-6 col-12">
                                                        <div class="custom-control custom-checkbox custom-control-inline">
                                                            <input type="checkbox" id="page2" name="site_admin" class="custom-control-input" onChange={this.onCheckboxChange}/>
                                                            <label class="custom-control-label" for="page2">Site Admin</label>
                                                        </div>
                                                    </div>
    
                                                    <div class="col-lg-6 col-12">
                                                        <div class="custom-control custom-checkbox custom-control-inline">
                                                            <input type="checkbox" id="page3" name="support_admin" class="custom-control-input" onChange={this.onCheckboxChange}/>
                                                            <label class="custom-control-label" for="page3">Support Admin</label>
                                                        </div>
                                                    </div>
    
                                                    <div class="col-lg-6 col-12">
                                                        <div class="custom-control custom-checkbox custom-control-inline">
                                                            <input type="checkbox" id="page4" name="tech_support" class="custom-control-input" onChange={this.onCheckboxChange}/>
                                                            <label class="custom-control-label" for="page4">Tech Support</label>
                                                        </div>
                                                    </div>
    
                                                    <div class="col-lg-6 col-12">
                                                        <div class="custom-control custom-checkbox custom-control-inline">
                                                            <input type="checkbox" id="page5" name="customer" class="custom-control-input" onChange={this.onCheckboxChange}/>
                                                            <label class="custom-control-label" for="page5">Customer</label>
                                                        </div>
                                                    </div>
        
                                                </div>
                                            </div>
                                        </div>
        
                                        <div class="form-group">
                                            <label style={{marginBottom: '0px'}} for="country"><b>Pages</b></label>
                                            <div style={{padding: '10px'}}class="widget-content widget-content-area">
                                                
                                                <div class="row">
    
                                                    <div class="col-lg-6 col-12">
                                                        <div class="custom-control custom-checkbox custom-control-inline">
                                                            <input type="checkbox" id="page6" name="reports" class="custom-control-input" onChange={this.onCheckboxChange}/>
                                                            <label class="custom-control-label" for="page6">Reports</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6 col-12">
                                                        <div class="custom-control custom-checkbox custom-control-inline">
                                                            <input type="checkbox" id="page7" name="notification" class="custom-control-input" onChange={this.onCheckboxChange}/>
                                                            <label class="custom-control-label" for="page7">Notification</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6 col-12">
                                                        <div class="custom-control custom-checkbox custom-control-inline">
                                                            <input type="checkbox" id="page12" name="feedback" class="custom-control-input" onChange={this.onCheckboxChange}/>
                                                            <label class="custom-control-label" for="page12">Feedback</label>
                                                        </div>
                                                    </div>                                                    
                                                    <div class="col-lg-6 col-12">
                                                        <div class="custom-control custom-checkbox custom-control-inline">
                                                            <input type="checkbox" id="page8" name="roles" class="custom-control-input" onChange={this.onCheckboxChange}/>
                                                            <label class="custom-control-label" for="page8">Roles</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6 col-12">
                                                        <div class="custom-control custom-checkbox custom-control-inline">
                                                            <input type="checkbox" id="page9" name="avatar" class="custom-control-input" onChange={this.onCheckboxChange}/>
                                                            <label class="custom-control-label" for="page9">Avatar</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6 col-12">
                                                        <div class="custom-control custom-checkbox custom-control-inline">
                                                            <input type="checkbox" id="page10" name="banner" class="custom-control-input" onChange={this.onCheckboxChange}/>
                                                            <label class="custom-control-label" for="page10">Banner</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6 col-12">
                                                        <div class="custom-control custom-checkbox custom-control-inline">
                                                            <input type="checkbox" id="page11" name="support_chat" class="custom-control-input" onChange={this.onCheckboxChange}/>
                                                            <label class="custom-control-label" for="page11">Support Chat</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <span className="m-b-5 m-t-15 frm-err-msg"> {this.state.errorMessage} </span>
                                        <p onClick={()=> this.submitFormHandler()} className="ml-1 fr btn btn-primary">Submit</p>
                                        <Link to="/Roles" className="fr btn btn-danger">Cancel</Link>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
                <div className={this.state.overlay}>
                    <div className={this.state.spinner}></div>
                </div>
        
            </div>
        
            )
    }
    else {
            return (
                <div id="content" className="main-content">
        
                    <div className="container formcustom">
                    <div className="container">
                        <div className="row layout-top-spacing" id="cancel-row">
                            <div id="ftFormArray" className="col-lg-12 layout-spacing">
                                <div className="statbox widget box box-shadow">
                                    <div className="widget-header">
                                        <div className="row">
                                            <div className="col-xl-12 col-md-12 col-sm-12 col-12">
                                                <h4>Edit Roles</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="widget-content widget-content-area custom-autocomplete h-100">
                                        <form id="formid">
                                            <div className="form-group mb-4">
                                                <label for="country">Role Name</label>
                                                <input type="text" className="form-control" autoComplete='off' name="admin_role_name" onChange={this.onChangeEvent} value={this.state.admin_role_name} placeholder="Enter role name" />
                                            </div>
                                            <span className="m-b-5 m-t-15" style={{ fontSize: 12, fontWeight: "bold", color: "#d82b2a" }}>
                                                {this.state.adminRoleNameError}
                                            </span>
            
            
                                            <div class="form-group">
                                                <label style={{marginBottom: '0px'}} for="country"><b>Fixes</b></label>
                                                <div style={{padding: '10px'}} class="widget-content widget-content-area">
            
            
                                                    <div class="row">
                                                        <div class="col-lg-6 col-12">
                                                            <div class="custom-control custom-checkbox custom-control-inline">
                                                                <input type="checkbox" id="Fixes1" name="open_fixes" class="custom-control-input" defaultChecked={this.state.open_fixes === '1'} onChange={this.onCheckboxChange} />
                                                                <label class="custom-control-label" for="Fixes1">Open Fixes</label>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6 col-12">
                                                            <div class="custom-control custom-checkbox custom-control-inline">
                                                                <input type="checkbox" id="Fixes2" name="private_fixes" class="custom-control-input" defaultChecked={this.state.private_fixes === '1'} onChange={this.onCheckboxChange}/>
                                                                <label class="custom-control-label" for="Fixes2">Private Fixes</label>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6 col-12">
                                                            <div class="custom-control custom-checkbox custom-control-inline">
                                                                <input type="checkbox" id="Fixes3" name="resolve_fixes" class="custom-control-input" defaultChecked={this.state.resolve_fixes === '1'} onChange={this.onCheckboxChange}/>
                                                                <label class="custom-control-label" for="Fixes3">Resolved Fixess</label>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6 col-12">
                                                            <div class="custom-control custom-checkbox custom-control-inline">
                                                                <input type="checkbox" id="Fixes4" name="close_fixes" class="custom-control-input" defaultChecked={this.state.close_fixes === '1'} onChange={this.onCheckboxChange} />
                                                                <label class="custom-control-label" for="Fixes4">Closed Fixes</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
            
                                            <div class="form-group">
                                                <label style={{marginBottom: '0px'}} for="country"><b>Master</b></label>
                                                <div style={{padding: '10px'}} class="widget-content widget-content-area">
                                                    <div class="row">
                                                        <div class="col-lg-6 col-12">
                                                            <div class="custom-control custom-checkbox custom-control-inline">
                                                                <input type="checkbox" id="master1" name="country_master" class="custom-control-input" defaultChecked={this.state.country_master === '1'} onChange={this.onCheckboxChange}/>
                                                                <label class="custom-control-label" for="master1">Country Master</label>
                                                            </div>
                                                        </div>
                                                        
                                                        <div class="col-lg-6 col-12">
                                                            <div class="custom-control custom-checkbox custom-control-inline">
                                                                <input type="checkbox" id="master4" name="skill_master" class="custom-control-input" defaultChecked={this.state.skill_master === '1'} onChange={this.onCheckboxChange}/>
                                                                <label class="custom-control-label" for="master4">Skills Master</label>
                                                            </div>
                                                        </div>
        
                                                    </div>
                                                </div>
                                            </div>
            
                                            <div class="form-group">
                                                <label style={{marginBottom: '0px'}} for="country"><b>Users</b></label>
                                                <div style={{padding: '10px'}}class="widget-content widget-content-area">
                                                    
                                                    <div class="row">
        
                                                        <div class="col-lg-6 col-12">
                                                            <div class="custom-control custom-checkbox custom-control-inline">
                                                                <input type="checkbox" id="page1" name="super_admin" class="custom-control-input" defaultChecked={this.state.super_admin === '1'} onChange={this.onCheckboxChange}/>
                                                                <label class="custom-control-label" for="page1">Super Admin</label>
                                                            </div>
                                                        </div>
        
                                                        <div class="col-lg-6 col-12">
                                                            <div class="custom-control custom-checkbox custom-control-inline">
                                                                <input type="checkbox" id="page2" name="site_admin" class="custom-control-input" defaultChecked={this.state.site_admin === '1'} onChange={this.onCheckboxChange}/>
                                                                <label class="custom-control-label" for="page2">Site Admin</label>
                                                            </div>
                                                        </div>
        
                                                        <div class="col-lg-6 col-12">
                                                            <div class="custom-control custom-checkbox custom-control-inline">
                                                                <input type="checkbox" id="page3" name="support_admin" class="custom-control-input" defaultChecked={this.state.support_admin === '1'} onChange={this.onCheckboxChange}/>
                                                                <label class="custom-control-label" for="page3">Support Admin</label>
                                                            </div>
                                                        </div>
        
                                                        <div class="col-lg-6 col-12">
                                                            <div class="custom-control custom-checkbox custom-control-inline">
                                                                <input type="checkbox" id="page4" name="tech_support" class="custom-control-input" defaultChecked={this.state.tech_support === '1'} onChange={this.onCheckboxChange}/>
                                                                <label class="custom-control-label" for="page4">Tech Support</label>
                                                            </div>
                                                        </div>
        
                                                        <div class="col-lg-6 col-12">
                                                            <div class="custom-control custom-checkbox custom-control-inline">
                                                                <input type="checkbox" id="page5" name="customer" class="custom-control-input" defaultChecked={this.state.customer === '1'} onChange={this.onCheckboxChange}/>
                                                                <label class="custom-control-label" for="page5">Customer</label>
                                                            </div>
                                                        </div>
            
                                                    </div>
                                                </div>
                                            </div>
            
                                            <div class="form-group">
                                                <label style={{marginBottom: '0px'}} for="country"><b>Pages</b></label>
                                                <div style={{padding: '10px'}}class="widget-content widget-content-area">
                                                    
                                                    <div class="row">
        
                                                        <div class="col-lg-6 col-12">
                                                            <div class="custom-control custom-checkbox custom-control-inline">
                                                                <input type="checkbox" id="page6" name="reports" class="custom-control-input" defaultChecked={this.state.reports === '1'} onChange={this.onCheckboxChange}/>
                                                                <label class="custom-control-label" for="page6">Reports</label>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6 col-12">
                                                            <div class="custom-control custom-checkbox custom-control-inline">
                                                                <input type="checkbox" id="page7" name="notification" class="custom-control-input" defaultChecked={this.state.notification === '1'} onChange={this.onCheckboxChange}/>
                                                                <label class="custom-control-label" for="page7">Notification</label>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6 col-12">
                                                            <div class="custom-control custom-checkbox custom-control-inline">
                                                                <input type="checkbox" id="page12" name="feedback" class="custom-control-input" defaultChecked={this.state.feedback === '1'} onChange={this.onCheckboxChange}/>
                                                                <label class="custom-control-label" for="page12">Feedback</label>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6 col-12">
                                                            <div class="custom-control custom-checkbox custom-control-inline">
                                                                <input type="checkbox" id="page8" name="roles" class="custom-control-input" defaultChecked={this.state.roles === '1'} onChange={this.onCheckboxChange}/>
                                                                <label class="custom-control-label" for="page8">Roles</label>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6 col-12">
                                                            <div class="custom-control custom-checkbox custom-control-inline">
                                                                <input type="checkbox" id="page9" name="avatar" class="custom-control-input" defaultChecked={this.state.avatar === '1'} onChange={this.onCheckboxChange}/>
                                                                <label class="custom-control-label" for="page9">Avatar</label>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6 col-12">
                                                            <div class="custom-control custom-checkbox custom-control-inline">
                                                                <input type="checkbox" id="page10" name="banner" class="custom-control-input" defaultChecked={this.state.banner === '1'} onChange={this.onCheckboxChange}/>
                                                                <label class="custom-control-label" for="page10">Banner</label>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6 col-12">
                                                            <div class="custom-control custom-checkbox custom-control-inline">
                                                                <input type="checkbox" id="page11" name="support_chat" class="custom-control-input" defaultChecked={this.state.support_chat === '1'} onChange={this.onCheckboxChange}/>
                                                                <label class="custom-control-label" for="page11">Support Chat</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <span className="m-b-5 m-t-15 frm-err-msg"> {this.state.errorMessage} </span>
                                            <p onClick={()=> this.submitFormHandler()} className="ml-1 fr btn btn-primary">Update</p>
                                            <Link to="/Roles" className="fr btn btn-danger">Cancel</Link>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                    <div className={this.state.overlay}>
                        <div className={this.state.spinner}></div>
                    </div>
            
                </div>
            
                )
        
    }
    
}
}
export default RoleForm