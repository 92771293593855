import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Cookies from 'universal-cookie';
import Logo from '../logo-ts.png';
import { axiosInstance } from '../Services/PostData';
const cookies = new Cookies();
// export default function Login() {
class Login extends Component {

    constructor(){
		super();
		this.state = {
            email: '',
            pass: ''
        };
		this.submitFormHandler = this.submitFormHandler.bind(this);
		this.onChangeHandler = this.onChangeHandler.bind(this);
	}
	
	onChangeHandler(e) {
		this.setState({[e.target.name]: e.target.value})
	}

	
    validateFunction() {
        let passError = "";
        let emailError = "";

		if (!this.state.email) {
			emailError = "User Name cannot be blank";
		}
        if (!this.state.pass) {
			passError = "Password cannot be blank";
		}

		if (emailError || passError) {
		  this.setState({emailError, passError});
		  return false;
		}
	
		return true;
    }

	submitFormHandler() {

        let query = window.location.search.substring(1);
            const isValid = this.validateFunction();
		if(isValid) {
				
			this.setState({ emailError: '', passError:''});
                //show spinner 
                let spinner = 'lds-dual-ring custom-screen-middle';
                let overlay = 'overlay';
                this.setState({ spinner, overlay });
    
                //  update submit (Mode = Update )
                const jsonValue = {
					username: this.state.email, 
					password: this.state.pass
                };
                axiosInstance.post(`Login`, jsonValue)
                .then(res => {
                if(res.data.status === 'success' && res.data.statusCode ==='200' )
                {
                    let spinner = 'lds-dual-ring custom-screen-middle hide__container';
                    let overlay = 'overlay hide__container';
					this.setState({ spinner, overlay });

					let responseValue = res.data.details[0];
					cookies.set('adminUserId', res.data.admin_user_id );
					window.localStorage.setItem('adminUserLocalIdTSAS', res.data.admin_user_id);
					window.localStorage.setItem('logoTSASAdmin', responseValue.c_logo_url);
					window.localStorage.setItem('colorcodeTSASAdmin', responseValue.c_color_code);
					window.localStorage.setItem('typeTSASAdmin', responseValue.type);
					window.localStorage.setItem('typeTSASAdminCompany', responseValue.company_id);

                    this.setState({userRedirect: true});
                }
                else if(res.data.status === 'fail' && res.data.statusCode ==='400' ){
                    let messageError = res.data.msg;
                    this.setState({ messageError });
                }
                })
                .catch(err => console.log('Login: ', err));             
            }
    }

	render() {

		if (this.state.userRedirect) {
			return <Redirect to="/Dashboard"/>;
		}

    return (
        <div className="limiter">
		<div className="container-login100">
			<div className="wrap-login100 p-l-50 p-r-50 p-t-37 p-b-30">
				<img className="loginlogo p-b-20" src={Logo} />
				<form className="login100-form validate-form">
					<span className="login100-form-title p-b-35">
						Login
					</span>
					<div className="wrap-input100 validate-input m-b-16" >
						<input id="email" className="input100" type="text" onChange={this.onChangeHandler}  name="email" placeholder="Email or Phonenumber" />
						<span className="focus-input100"></span>
						<span className="symbol-input100">
							<span className="fa fa-envelope"></span>
							</span>
						<span className="m-b-5 m-t-15 frm-err-msg">{this.state.emailError}</span>
					</div>
					<div className="wrap-input100 validate-input m-b-16" >
						<input id="pass" className="input100" onChange={this.onChangeHandler}  type="password" name="pass" placeholder="Password" />
						<span className="focus-input100"></span>
						<span className="symbol-input100">
							<span className="fa fa-lock"></span>
							</span>
					<span className="m-b-5 m-t-15 frm-err-msg">{this.state.passError}</span>
						</div>
					<div className="text-center m-b-5 m-t-15 frm-err-msg">{this.state.messageError} </div>
						
					<div className="container-login100-form-btn p-t-25">
						<a onClick={() => this.submitFormHandler()} className="login-btn login100-form-btn">
							Submit
						</a>
					</div>
					<div className="text-center w-full p-t-15	">
						<a id="forget_id" className="txt1 bo1 hov1" href="#">
                        {/* <Link to="/ForgotPassword">Forgot Password ?</Link>*/}
						</a>
					</div>
					<div className="text-center w-full p-t-42 p-b-22">
						<span className="txt1 error">Error</span>
						<span className="txt1 sucess">Login Sucessfully.</span>
					</div>	
				</form>
			</div>
		</div>
	</div>
    )
}
}
export default Login