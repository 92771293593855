import $ from 'jquery';
import React, { Component } from 'react';
// import {as} from '../../../js/datatables'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Link } from 'react-router-dom';
import swal from 'sweetalert2';
import { axiosInstance } from '../../../Services/PostData';
import './UserTechSupport.css';
import DataTable from 'react-data-table-component';
import DataTableExtensions from "react-data-table-component-extensions";

class UserTechSupport extends Component {

    constructor(){
		super();
		this.state = {
            techies: [],
            TechDetails: [],
            TechDetails_certificate: [],
            TechDetails_proof: [],
            aprovalUserId: '',
            amount: '',
            techie_details_id: '',
            Commission_rate: '',
            remaining_fixers: 0,
            typeTSASAdminCompany: window.localStorage.getItem('typeTSASAdminCompany'),
        };
		this.getTechieDetails = this.getTechieDetails.bind(this);
		this.submitNegotiateRate = this.submitNegotiateRate.bind(this);
		this.onChange = this.onChange.bind(this);
		this.approveFunction = this.approveFunction.bind(this);
		this.Commission_amount = this.Commission_amount.bind(this);
		this.submitCommisionAmount = this.submitCommisionAmount.bind(this);
		this.openTechUserDetailsModal = this.openTechUserDetailsModal.bind(this);
    }
    
    componentDidMount() {

      const baseUrl = window.$baseUrl;
      this.setState({baseUrl})

        //show spinner
        let spinner = "loadercustumspinner";
        let overlay = "loadingcustumspinner";
        this.setState({ spinner, overlay });

        this.getTechieDetails();
        // window.onload = function() {
        //     if(!window.location.hash) {
        //         window.location = window.location + '#loaded';
        //         window.location.reload();
        //     }
        // }
        $(document).ready(function () {
            setTimeout(
              function() {
                $('#default-ordering').DataTable( {
                  responsive: true,
                    "oLanguage": {
                        "oPaginate": { "sPrevious": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-left"><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg>', "sNext": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>' },
                        "sInfo": "Showing page _PAGE_ of _PAGES_",
                        "sSearch": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>',
                        "sSearchPlaceholder": "Search...",
                       "sLengthMenu": "Results :  _MENU_",
                    },
                    "order": [[ 0, "asc" ]],
                    "stripeClasses": [],
                    "lengthMenu": [10, 20, 50],
                    "pageLength": 10,
                    drawCallback: function () { $('.dataTables_paginate > .pagination').addClass(' pagination-style-13 pagination-bordered mb-5'); }
                } );
        
              }.bind(this),100);
        });
	}
    
    async getTechieDetails() {
//        await axiosInstance.get(`getTechiesDetails`)
//            .then(res => {
//                if(res.data.status === 'success' && res.data.statusCode ==='200' )
//                {
//                    let techie_res = res.data.user_details;
//                    if(techie_res.length != 0 || techie_res.length != '0')
//                    {
//                        setTimeout(function(){
//                            $( ".dataTables_empty" ).hide();
//                        }, 100);
//
//                        
//                    }
//                    let tech_Details = techie_res.map(techies => { return techies });
//                    this.setState({ techies: tech_Details });
//                    
//                    let remaining_fixers = res.data.remaining_fixers;
//                    let spinner = "loadercustumspinner";
//                    let overlay = "loadingcustumspinner hide__container";
//                    this.setState({ spinner, overlay, remaining_fixers });
//                }		 
//            })
//            .catch(err => console.log('Login: ', err));
        
        const json = { company_id: this.state.typeTSASAdminCompany };
        await axiosInstance
            .post(`getTechiesDetails`, json)
            .then(res => {
                if(res.data.status === 'success' && res.data.statusCode ==='200' )
                {
                    let techie_res = res.data.user_details;
                    if(techie_res.length !== 0 || techie_res.length !== '0')
                    {
                        setTimeout(function(){
                            $( ".dataTables_empty" ).hide();
                        }, 100);

                        
                    }
                    let tech_Details = techie_res.map(techies => { return techies });
                    this.setState({ techies: tech_Details });
                    
                    let remaining_fixers = res.data.remaining_fixers;
                    let spinner = "loadercustumspinner";
                    let overlay = "loadingcustumspinner hide__container";
                    this.setState({ spinner, overlay, remaining_fixers });
                }		 
            })
            .catch(err => console.log('Login: ', err));
    }

    approveFunction(id,rate,tech_id,currency_symbol) {
        this.state.aprovalUserId = id;
        this.state.techie_details_id = tech_id;

        let techie_rate = currency_symbol +" "+ rate;
        this.setState({ techie_rate });
        $('#faderightModal').show();
    }

    Commission_amount(id,Commission,tech_id) {
        this.state.aprovalUserId = id;
        this.state.techie_details_id = tech_id;

        let Commission_rate = Commission;
        this.setState({ Commission_rate });
        $('#Commission_amount').show();
    }

    closeModalHere() {
        $('#faderightModal').hide();
    }

    closeCommisionModalHere() {
        $('#Commission_amount').hide();
    }

    submitCommisionAmount() {

        // console.log(this.state.Commission_rate);

        let spinner = "loadercustumspinner";
        let overlay = "loadingcustumspinner";
        this.setState({ spinner, overlay });
        const user = {
            amount: this.state.Commission_rate,
            user_id: this.state.aprovalUserId,
            tech_user_details_id: this.state.techie_details_id
          };
          
        axiosInstance.post(`updatecommisionamount`, user)
        .then(res => {
        if(res.data.status === 'success' && res.data.statusCode ==='200' )
        {
            let spinner = "loadercustumspinner";
            let overlay = "loadingcustumspinner hide__container";
            this.setState({ spinner, overlay });
            swal
            .fire({
              type: "info",
              title: "Updated Successfuly",
              // text: 'do you want to continuew',
              confirmButtonText: "ok",
              confirmButtonColor: "#D82B2A",
            })
            .then((result) => {
                $('#Commission_amount').hide();
                window.location.reload();
            });

        }
        })
        .catch(err => console.log('Login: ', err));
    }

    submitNegotiateRate() {
            

        if(this.state.amount == "") {
            this.setState({negotiationError: 'Negotiation amount should not be blank'})
        }
        else {
            this.setState({negotiationError: ''})

            let spinner = "loadercustumspinner";
            let overlay = "loadingcustumspinner";
            this.setState({ spinner, overlay });

            const user = {
                amount: this.state.amount,
                user_id: this.state.aprovalUserId,
                tech_user_details_id: this.state.techie_details_id
              };

            axiosInstance.post(`updateNegotiateAmount`, user)
            .then(res => {
            if(res.data.status === 'success' && res.data.statusCode ==='200' )
            {
                $('#faderightModal').hide();
                window.location.reload();
                let spinner = "loadercustumspinner";
                let overlay = "loadingcustumspinner hide__container";
                this.setState({ spinner, overlay });
            }
            })
            .catch(err => console.log('Login: ', err));
        }
    }

    changeApprovalStatus(userId, approveStatus) {

        confirmAlert({
            message: 'Are you sure to do this.',
            buttons: [
              {
                label: 'Yes',
                onClick: () => {
                    //show spinner
                    let spinner = "loadercustumspinner";
                    let overlay = "loadingcustumspinner";
                    this.setState({ spinner, overlay });
                    if(approveStatus == 0)
                    {
                        approveStatus = 1;
                    }
                    else {
                        approveStatus = 0;
                    }

                    const user = {
                        user_id: userId,
                        approval_status:approveStatus
                      };
                    
                    axiosInstance.post(`updateApprovalStatus`, user)
                    .then(res => {
                    if(res.data.status === 'success' && res.data.statusCode ==='200' )
                    {
                       window.location.reload();
                       let spinner = "loadercustumspinner";
                       let overlay = "loadingcustumspinner hide__container";
                       this.setState({ spinner, overlay });
                    }
                    })
                    .catch(err => console.log('Login: ', err));
                }
              },
              {
                label: 'No'
              }
            ]
          });


        
    }

    onChange(e) {
		this.setState({[e.target.name]: e.target.value});
    }
    

    deleteFucntion(id) {
        confirmAlert({
          message: "Are you sure to do this.",
          buttons: [
            {
              label: "Yes",
              onClick: () => {
                const user = { user_id: id };
                axiosInstance
                  .post(`deletetechsupport`, user)
                  .then((res) => {
                    if (
                      res.data.status === "success" &&
                      res.data.statusCode === "200"
                    ) {
                      window.location.reload();
                    }
                  })
                  .catch((err) => console.log("Login: ", err));
              },
            },
            {
              label: "No",
            },
          ],
        });
      }
    
      suspendFucntion(id) {
        confirmAlert({
          message: "Are you sure to do this.",
          buttons: [
            {
              label: "Yes",
              onClick: () => {
                //show spinner
                let spinner = "loadercustumspinner";
                let overlay = "loadingcustumspinner";
                this.setState({ spinner, overlay });
                const user = { user_id: id };
                axiosInstance
                  .post(`Suspendtechsupport`, user)
                  .then((res) => {
                    if (
                      res.data.status === "success" &&
                      res.data.statusCode === "200"
                    ) {
                      let spinner = "loadercustumspinner";
                      let overlay = "loadingcustumspinner hide__container";
                      this.setState({ spinner, overlay });
                      window.location.reload();
                    }
                  })
                  .catch((err) => console.log("Login: ", err));
              },
            },
            {
              label: "No",
            },
          ],
        });
      }

      unsuspendFucntion(id) {
        confirmAlert({
          message: "Are you sure to do this.",
          buttons: [
            {
              label: "Yes",
              onClick: () => {
                //show spinner
                let spinner = "loadercustumspinner";
                let overlay = "loadingcustumspinner";
                this.setState({ spinner, overlay });
                const user = { user_id: id };
                axiosInstance
                  .post(`UnSuspendtechsupport`, user)
                  .then((res) => {
                    if (
                      res.data.status === "success" &&
                      res.data.statusCode === "200"
                    ) {
                      let spinner = "loadercustumspinner";
                      let overlay = "loadingcustumspinner hide__container";
                      this.setState({ spinner, overlay });
                      window.location.reload();
                    }
                  })
                  .catch((err) => console.log("Login: ", err));
              },
            },
            {
              label: "No",
            },
          ],
        });
      }

      openTechUserDetailsModal(id) {
        $('#TechUserDetails').show();
        const user = { user_id: id };
        axiosInstance.post(`getFixerUserDetails`, user)
        .then(res => {
        if(res.data.status === 'success' && res.data.statusCode ==='200' )
        {
              this.setState({techDetailsAvailable: true})
              let techie_res = res.data.user_details;
              let tech_user_Details = techie_res.map(user_details => { return user_details });
              this.setState({ TechDetails: tech_user_Details });
              
              let techie_certificate = res.data.certificate_details;
              let techie_certificate_Details = techie_certificate.map(user_certificate_details => { return user_certificate_details });
              this.setState({ TechDetails_certificate: techie_certificate_Details });
              if(techie_certificate.length !=0)
              {
                this.setState({certificateDetailsAvailable: true});
              }
              else {
                this.setState({certificateDetailsAvailable: false});
              }
              
              let techie_proof = res.data.proof_details;
              let techie_proof_Details = techie_proof.map(user_proof_details => { return user_proof_details });
              this.setState({ TechDetails_proof: techie_proof_Details });
              if(techie_proof.length !=0)
              {
                this.setState({proofDetailsAvailable: true});
              }
              else {
                this.setState({proofDetailsAvailable: false});
              }

              let spinner = "loadercustumspinner";
              let overlay = "loadingcustumspinner hide__container";
              this.setState({ spinner, overlay });
        }
        else {
          this.setState({techDetailsAvailable: false})
        }

        })
        .catch(err => console.log('Login: ', err));

      }
      
      closeTechUserDetailsModal() {
        $('#TechUserDetails').hide();
      }


      logoutUser(id) {
        swal.fire({
            text: "Are you sure you want to logout this user ?",
            showCancelButton: true,
            confirmButtonColor: '#D90429',
            cancelButtonText: "No",
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.value) {

                //show spinner
                let spinner = "loadercustumspinner";
                let overlay = "loadingcustumspinner";
                this.setState({ spinner, overlay });

                const user = { tech_user_id: id };
                axiosInstance
                  .post(`logoutTechUser`, user)
                  .then((res) => {
                    if ( res.data.status === "success" && res.data.statusCode === "200" )
                    {
                      let spinner = "loadercustumspinner";
                      let overlay = "loadingcustumspinner hide__container";
                      this.setState({ spinner, overlay });

                      let message = res.data.msg;
                      swal.fire({ text: message });
                    }
                    else {
                      let message = res.data.msg;
                      console.log(message);
                      swal.fire({ text: message });
                    }
                  })
                  .catch((err) => console.log("Login: ", err));
            }
        })
      }
render() {

  const SampleExpandedComponent = ({ data }) => (
    <div style={{padding: '1% 5%'}}>
      <div className="expandable-container-col-one col-md-4 pull-left">
        <p>
          <span className="tc_user_lbl">Name</span> 
          <span className="tc_lbl_colon">: </span>
          <span className="tc_lbl_content">{data.user_name}</span>
        </p>
        
        <p>
          <span className="tc_user_lbl">Email</span> 
          <span className="tc_lbl_colon">: </span>
          <span className="tc_lbl_content">{data.user_email}</span>
        </p>
        
        <p>
          <span className="tc_user_lbl">Phone</span> 
          <span className="tc_lbl_colon">: </span>
          <span className="tc_lbl_content">{data.phone_prefix} - {data.user_phone}</span>
        </p>
      
        <p>
          <span className="tc_user_lbl">Company</span> 
          <span className="tc_lbl_colon">: </span>
          <span className="tc_lbl_content">{data.company_name}</span>
        </p>
      </div>
      <div className="expandable-container-col-one col-md-4 pull-left">
        <p>
          <span className="">Compnay Enable Status</span> 
          <span className="tc_lbl_colon">: </span>
          {data.company_enable_status == 0 || data.company_enable_status == "0" ?
            <span className="tc_lbl_content">Not Enabled</span>
            :
            <span className="tc_lbl_content">Enabled</span>
          }
        </p>
      </div>
      <div className="expandable-container-col-two col-md-4 pull-left">
        <a class="btn btn-primary btn-rounded" onClick={() => this.logoutUser(data.user_id)}>Logout User</a>
        <a href={'mailto:' + data.user_email} class="btn btn-primary btn-rounded m-l-10" >Send Email</a>
      </div>


    </div>
  );
  
  const columns = [
    {
      name: 'Name',
      selector: 'user_name',
      sortable: true,
      filterable: true,
    },
    {
      name: 'Phone',
      selector: 'user_phone',
      sortable: true,
      filterable: true,
      style: {
        minWidth: '135px'
      }
    },
    {
      name: 'Rate',
      // selector: 'rate',
      selector: 'rate_symbol',
      sortable: true,
      filterable: true,
    },
    {
      name: 'Approval',
      selector: (row, index) => {
        return  <span>
                <input type="checkbox" className="form-check-input-switchery-primary"
                  checked="" data-fouc="" data-switchery="true" style={{display: 'none'}} />
                <span className={row.approvalClassName}
                      onClick={() => this.changeApprovalStatus(row.user_id, row.approval_status)} >
                <small className="smallClassname"></small>
                </span>
                </span>
      },
      sortable: true,
      filterable: true, 
    },
    {
      name: 'Negotiate',
      selector: 'negotiation_amount',
      sortable: true,
      filterable: true,
    },
    {
      name: 'Nego. Status',
      selector: 'negotiation_status',
      sortable: true,
      filterable: true,
    },
    {
      name: 'Edit Nego.',
      selector: (row, index) => {
        return  <i className="fa fa-pencil text-success font-18 cursor-pointer" aria-hidden="true" 
                  onClick={() => this.approveFunction(row.user_id, row.rate, row.tech_user_details_id, row.currency_symbol)}></i>
      },
      sortable: true,
      filterable: true,
    },
    {
      name: 'Edit Commission',
      selector: (row, index) => {
        return  <i className="fa fa-pencil text-success font-18 cursor-pointer" aria-hidden="true" 
                    onClick={() => this.Commission_amount(row.user_id, row.commission, row.tech_user_details_id)}></i>
      },
      sortable: true,
      filterable: true,
      
    },
    {
      name: 'Details',
      selector: (row, index) => {
          return <p class="cursorpointer" onClick={() => this.openTechUserDetailsModal(row.user_id) } >  View </p>
      },
      sortable: true,
      filterable: true,
    },
    {
      name: 'Status',
      selector: "user_active_status",
      sortable: true,
      filterable: true,
    },
    {
      name: 'Suspend / Un-suspend',
      selector: (row, index) => {
        if(row.suspend_flag == 0) {
          return <p class="Suspend_user" onClick={() => this.suspendFucntion(row.user_id) } > Suspend </p>
        }
        else {
          return <p class="unSuspend_user" onClick={() => this.unsuspendFucntion(row.user_id) } >  Un-Suspend </p>
        }
      },
      sortable: true,
      filterable: true,
    },
    {
      name: 'Action',
      selector: (row, index) => {
          return  <div>
                    <Link to={{ pathname: '/EditTechSupport', search: 'Id='+row.user_id  }} >
                          <i class="fa fa-pencil text-success font-18" aria-hidden="true"></i>
                    </Link>
                    <i class="fa fa-trash text-danger font-18 cursorpointer m-l-10" aria-hidden="true" 
                        onClick={() => this.deleteFucntion(row.user_id) }> 
                    </i>
                  </div>;
        
      },
      sortable: true,
      filterable: true,
    }
  ];

  const data = this.state.techies;
  const tableData = {
    columns,
    data
  };



    return (
        <div id="content" className="main-content">
            <div className="layout-px-spacing">
                <div className="row layout-top-spacing2 layout-top-spacing">
                    <div className="col-xl-12 col-lg-12 col-sm-12 layout-spacing2  layout-spacing">
                       <div className="widget-content widget-content-area pg-top">
                            <div className="table-responsive mb-4 mt-1">
                                <p className="table-head table-head-no-add">Tech Support</p>
                                <p className="" style={{paddingLeft: '15px'}}>**Note: {this.state.remaining_fixers} Fixers not completed their basic details... Click here to <Link to="/IncomTechSupport">View</Link></p>
                                {/* <table id="default-ordering" className="table-responsive table1 table table-hover dataNewTable">
                                    <thead>
                                        <tr className="">
                                            <th>Name</th>
                                            <th>Phone</th>
                                            <th>Rate</th>
                                            <th>Approval</th>
                                            <th>Negotiate</th>
                                            <th>Nego. Status</th>
                                            <th>Edit Nego.</th>
                                            <th>Edit Commission</th>
                                            <th>Details</th>
                                            <th>Action</th>
                                            <th>Status</th>
                                            <th>Suspend</th>
                                        </tr>
                                    </thead>
                                    <tbody id="tableBodyd">

                                    {this.state.techies.map(techie_details => (
                                    <tr className="" role="row" key={techie_details.user_id}>
                                        <td>{techie_details.user_name}</td>
                                        <td>{techie_details.user_phone}</td>
                                        <td>{techie_details.rate}</td>
                                        <td>
                                            <input type="checkbox" className="form-check-input-switchery-primary"
                                             checked="" data-fouc="" data-switchery="true" style={{display: 'none'}} />
                                            <span className={techie_details.approvalClassName}
                                                  onClick={() => this.changeApprovalStatus(techie_details.user_id, techie_details.approval_status)} >
                                            <small className="smallClassname"></small>
                                            </span>
                                        </td>
                                        
                                        <td>{techie_details.negotiation_amount}</td>
                                        <td>{techie_details.negotiation_status}</td>
                                        <td>
                                            <i className="fa fa-pencil text-success font-18 cursor-pointer" aria-hidden="true" 
                                            onClick={() => this.approveFunction(techie_details.user_id, techie_details.rate, techie_details.tech_user_details_id)}></i>
                                        </td>
                                        <td>
                                            <i className="fa fa-pencil text-success font-18 cursor-pointer" aria-hidden="true" 
                                            onClick={() => this.Commission_amount(techie_details.user_id, techie_details.commission, techie_details.tech_user_details_id)}></i>
                                        </td>
                                        <td className="cursorpointer" onClick={() => this.openTechUserDetailsModal(techie_details.user_id) }>
                                              View
                                        </td>
                                        <td>
                                            <Link to={{ pathname: '/EditTechSupport', search: 'Id='+techie_details.user_id  }} >
                                                <i class="fa fa-pencil text-success font-18" aria-hidden="true"></i>
                                            </Link>
                                            
                                            <i
                                            class="fa fa-trash text-danger font-18 cursorpointer"
                                            aria-hidden="true"
                                            onClick={() =>
                                                this.deleteFucntion(techie_details.user_id)
                                            }
                                            ></i>
                                        </td>
                                        <td>{techie_details.user_active_status}</td>
                                        <td>
                                            {techie_details.suspend_flag == 0 ? (
                                            <p
                                                class="Suspend_user Suspend_user_tech"
                                                onClick={() =>
                                                this.suspendFucntion(techie_details.user_id)
                                                }
                                            >
                                                {" "}
                                                Suspend{" "}
                                            </p>
                                            ) : (
                                            <p
                                                class="unSuspend_user unSuspend_user_tech"
                                                onClick={() =>
                                                this.unsuspendFucntion(techie_details.user_id)
                                                }
                                            >
                                                {" "}
                                                Un-Suspend{" "}
                                            </p>
                                            )}
                                        </td>
                                        
                                    </tr>
                            
                                    ))}
                                        
                                    </tbody>
                                </table> */}
                                
                        <DataTableExtensions {...tableData}
                          print={false}
                          exportHeaders={false}
                          pdf={false}
                          export={false}
                          >
                          <DataTable
                              columns={columns}
                              data={data}
                              noHeader
                              defaultSortAsc={false}
                              pagination
                              highlightOnHover
                              expandableRows={true}
                              expandableRowsComponent={<SampleExpandedComponent />}
                              expandOnRowClicked
                          />
                        </DataTableExtensions>

                            </div>
                        </div>
                    </div>

                </div>

            </div>

            <div id="faderightModal" className="modal animated fadeInRight custo-fadeInRight show" role="dialog" aria-modal="true">
                <div className="modal-dialog-content modal-dialog" style={{width: '35%'}}>
                    <div className="modal-background-color modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Negotiate Rate</h5>
                    </div>
                        <div className="modal-body">
                            <div>
                                <div className="">
                                    <div className="row">
                                        <form style={{width: '100%'}}>
                                        <div className="form-group">
                                            <label for="exampleFormControlInput1">
                                                Tech Support Rate : {this.state.techie_rate} 
                                            </label>
                                        </div> 
                                        <div className="form-group">
                                            <label for="exampleFormControlInput1">Enter Negotiation Rate</label>
                                            <input type="text" className="form-control" id="" name="amount" placeholder="Enter Rate" onChange={this.onChange} />
                                        </div> 
                                        <div  className="m-b-5 m-t-15" style={{ fontSize: 12, fontWeight: "bold", color: "#d82b2a" }}>
                                            {this.state.negotiationError}
                                        </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer md-button">
                            <button className="btn" data-dismiss="modal" onClick={this.closeModalHere}><i className="flaticon-cancel-12"></i> Close</button>
                            <button className="btn" data-dismiss="modal" onClick={this.submitNegotiateRate}><i className="flaticon-cancel-12"></i> Submit</button>
                        </div>
                    </div>
                </div>
            </div>

            <div id="Commission_amount" className="modal animated fadeInRight custo-fadeInRight show" role="dialog" aria-modal="true">
                <div className="modal-dialog-content modal-dialog" style={{width: '35%'}}>
                    <div className="modal-background-color modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Commission Amount</h5>
                    </div>
                        <div className="modal-body">
                            <div>
                                <div className="">
                                    <div className="row">
                                        <form style={{width: '100%'}}>
                                        {/* <div className="form-group">
                                            <label for="exampleFormControlInput1">
                                                Commission Amount : {this.state.Commission_rate} 
                                            </label>
                                        </div>  */}
                                        <div className="form-group">
                                            <label for="exampleFormControlInput1">Commission Amount</label>
                                            <input type="text" className="form-control" id=""  name="Commission_rate" value={this.state.Commission_rate} placeholder="Enter Commission" onChange={this.onChange} />
                                        </div> 
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer md-button">
                            <button className="btn" data-dismiss="modal" onClick={this.closeCommisionModalHere}><i className="flaticon-cancel-12"></i> Close</button>
                            <button className="btn" data-dismiss="modal" onClick={this.submitCommisionAmount}><i className="flaticon-cancel-12"></i> Submit</button>
                        </div>
                    </div>
                </div>
            </div>
            
            <div id="TechUserDetails" className="modal animated fadeInRight custo-fadeInRight show" role="dialog" aria-modal="true">
                <div className="modal-dialog-content modal-dialog" style={{width: '55%'}}>
                    <div className="modal-background-color modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Expert Fixers</h5>
                    </div>
                        <div className="modal-body customTechModalDetails">

                        {this.state.techDetailsAvailable ? 
                        <div>
                        {this.state.TechDetails.map(user_details => ( 
                          <>
                          <div>
                              <p>
                                <span className="tc_user_lbl">Name</span> 
                                <span className="tc_lbl_colon">: </span>
                                <span className="tc_lbl_content">{user_details.user_name}</span>
                              </p>
                              <p>
                                <span className="tc_user_lbl">Email</span> 
                                <span className="tc_lbl_colon">: </span>
                                <span className="tc_lbl_content">{user_details.user_email}</span>
                              </p>
                              <p>
                                <span className="tc_user_lbl">Phone Number</span> 
                                <span className="tc_lbl_colon">: </span>
                                <span className="tc_lbl_content">{user_details.user_full_phone}</span>
                              </p>
                              <p>
                                <span className="tc_user_lbl">Gender</span> 
                                <span className="tc_lbl_colon">: </span>
                                <span className="tc_lbl_content">{user_details.user_gender}</span>
                              </p>
                              <p>
                                <span className="tc_user_lbl">Country</span> 
                                <span className="tc_lbl_colon">: </span>
                                <span className="tc_lbl_content">{user_details.user_country}</span>
                              </p>
                              <p>
                                <span className="tc_user_lbl">Fixer Rate</span> 
                                <span className="tc_lbl_colon">: </span>
                                <span className="tc_lbl_content">{user_details.rate}</span>
                              </p>
                              <p>
                                <span className="tc_user_lbl">Fixer Experience</span> 
                                <span className="tc_lbl_colon">: </span>
                                <span className="tc_lbl_content">{user_details.experience}</span>
                              </p>
                              <p>
                                <span className="tc_user_lbl">Fixer Commission</span> 
                                <span className="tc_lbl_colon">: </span>
                                <span className="tc_lbl_content">{user_details.commission}</span>
                              </p>
                              <p>
                                <span className="tc_user_lbl">Approve Status</span> 
                                <span className="tc_lbl_colon">: </span>
                                <span className="tc_lbl_content">{user_details.user_approval_status}</span>
                              </p>
                              <p>
                                <span className="tc_user_lbl">Suspend Status</span> 
                                <span className="tc_lbl_colon">: </span>
                                <span className="tc_lbl_content">{user_details.user_suspend_status}</span>
                              </p>
                              <p>
                                <span className="tc_user_lbl">Skills</span> 
                                <span className="tc_lbl_colon">: </span>
                                <span className="tc_lbl_content">{user_details.skill_category}</span>
                              </p>
                          </div> 
                          
                          {this.state.certificateDetailsAvailable ? 
                            <div className="m-t-6">
                            <p>Certificate Details</p>
                            {this.state.TechDetails_certificate.map(certificates => (
                                <div class="right__menu_cr text-center m-b-5" key={certificates.tech_certificate_id}>
                                    
                                    {certificates.file_extension == 'png' || certificates.file_extension == 'PNG' ||
                                    certificates.file_extension == 'jpg' || certificates.file_extension == 'JPG' ||
                                    certificates.file_extension == 'jpeg' || certificates.file_extension == 'JPEG' ?

                                    <a href={this.state.baseUrl +`uploads/certificate/` + certificates.file_name} target="_blank" >
                                      <img src={this.state.baseUrl +`uploads/certificate/` + certificates.file_name} />
                                    </a>
                                    :
                                    certificates.file_extension == 'pdf' || certificates.file_extension == 'PDF' ?

                                    <a href={this.state.baseUrl +`uploads/certificate/` + certificates.file_name} target="_blank" >
                                      <img src={this.state.baseUrl +`assets/pdf_icon.svg`} />
                                    </a>
                                    : 
                                    <a href={this.state.baseUrl +`uploads/certificate/` + certificates.file_name} target="_blank" >
                                      <img src={this.state.baseUrl +`assets/other-file.png`} />
                                    </a>
                                    
                                    }
                                </div>
                              ))}
                            </div>
                            :
                            <div>
                            <p className="m-t-6">
                              <span className="tc_user_lbl">Certificates</span> 
                              <span className="tc_lbl_colon">: </span>
                              <span className="tc_lbl_content">No certificate found</span>
                            </p>
                            </div>
                          }
                         
                         {this.state.proofDetailsAvailable ? 

                          <div className="m-t-6">
                          <p >Proof Details</p>
                          {this.state.TechDetails_proof.map(certificates => (
                              <div class="right__menu_cr text-center m-b-5" key={certificates.tech_certificate_id}>
                                  
                                  {certificates.file_extension == 'png' || certificates.file_extension == 'PNG' ||
                                  certificates.file_extension == 'jpg' || certificates.file_extension == 'JPG' ||
                                  certificates.file_extension == 'jpeg' || certificates.file_extension == 'JPEG' ?

                                  <a href={this.state.baseUrl +`uploads/proof/` + certificates.file_name} target="_blank" >
                                    <img src={this.state.baseUrl +`uploads/proof/` + certificates.file_name} />
                                  </a>
                                  :
                                  certificates.file_extension == 'pdf' || certificates.file_extension == 'PDF' ?

                                  <a href={this.state.baseUrl +`uploads/proof/` + certificates.file_name} target="_blank" >
                                    <img src={this.state.baseUrl +`assets/pdf_icon.svg`} />
                                  </a>
                                  : 
                                  <a href={this.state.baseUrl +`uploads/proof/` + certificates.file_name} target="_blank" >
                                    <img src={this.state.baseUrl +`assets/other-file.png`} />
                                  </a>
                                  
                                  }
                              </div>
                            ))}
                          </div>
                          :
                          <div>
                            <p className="m-t-6">
                              <span className="tc_user_lbl">Proof</span> 
                              <span className="tc_lbl_colon">: </span>
                              <span className="tc_lbl_content">No Proof found</span>
                            </p>
                          </div>
                         }

                          </>
                        ))}
                        </div>
                        :
                        <p>Some details not available for this Fixer.</p>
                        }
                            
                        </div>
                        <div className="modal-footer md-button">
                            <button className="btn" data-dismiss="modal" onClick={this.closeTechUserDetailsModal}>
                              <i className="flaticon-cancel-12"></i> Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="footer-wrapper">
                <div className="footer-section f-section-1">
                    <p className="">Copyright © 2020 <a target="_blank" href="javascript:void(0);">TSAS</a>, All rights reserved.</p>
                </div>
            </div>
            <div className={this.state.overlay}>
          <div className={this.state.spinner}></div>
        </div>
        </div>
    )
}
}
export default UserTechSupport