import React, {Component} from 'react';
import $ from "jquery";
import { Link, Redirect } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { axiosInstance } from '../../Services/PostData';
import './TopBar.css';
const cookies = new Cookies();


class TopBar extends Component {
    constructor() {
      super();
      this.state = {
        values: [],
        notification_count:0,
        Redirect: false,
        logoUrl: localStorage.getItem('logoTSASAdmin')
      };
      this.getunreadedNotifications = this.getunreadedNotifications.bind(this);     

    }


    componentDidMount() {
        // let adminUserId=cookies.get('adminUserId');
        this.getunreadedNotifications(); 

     }

     getunreadedNotifications () {
        axiosInstance
        .get(`getunreadedNotifications`)
        .then((res) => {
        if (res.data.status === "success" && res.data.statusCode === "200") {
            let response = res.data.notification_details;
            let notification_count = res.data.notification_count;


        // this.setState(notification_count);

            let response_Details = response.map((value) => {
            return value;
            });
            this.setState({ values: response_Details,notification_count:notification_count });
        }
        })
        .catch((err) => console.log("Login: ", err));
    }


    logoutFucntion = () => { 
        const body = { id: '3' };
        axiosInstance.post(`logout`, body)
        .then(res => {
        if(res.data.status === 'success' && res.data.statusCode ==='200' ) 
        {
            cookies.remove('adminUserId');
            this.setState({Redirect: true});
        }
        })
        .catch(err => console.log(err));
        window.localStorage.removeItem('adminUserLocalIdTSAS');
    }
        
    updatenotification(id, customer_id, tech_id)
    { 
        const body = { notification_id: id };
        axiosInstance.post(`updatenotificationstatus`, body)
        .then(res => {
        if(res.data.status === 'success' && res.data.statusCode ==='200' ) 
        {
            if(customer_id == 0) {
                this.setState({techSupportRedirect: true});
            }
            else if(tech_id == 0) {
                this.setState({customerRedirect: true});
            }
            else {
                this.setState({notificationRedirect: true});
            }
        }
        })
        .catch(err => console.log(err));
    }


    render() {

    if(this.state.notificationRedirect) {
        return <Redirect to={{ pathname: '/Notification'}} />;
    }
    if(this.state.techSupportRedirect) {
        return <Redirect to={{ pathname: '/TechSupport'}} />;
    }
    if(this.state.customerRedirect) {
        return <Redirect to={{ pathname: '/Customer'}} />;
    }
    if(this.state.Redirect) {
        return <Redirect to={{ pathname: '/'}} />;
    }


    return (
    <div className="header-container fixed-top">
    <header className="header navbar navbar-expand-sm">
        <ul className="navbar-item theme-brand flex-row  text-center">
            <li className="nav-item theme-logo">
                <Link to="/Dashboard">
                    {
                        this.state.logoUrl === undefined || this.state.logoUrl === null || this.state.logoUrl === "" ?
                        <img src="assets/img/logo-ts.png" className="navbar-logo" alt="" />
                        :
                        <img src={window.$baseUrl+'uploads/company/'+ this.state.logoUrl} className="navbar-logo" alt="" />
                    }
                </Link>
            </li>
        </ul>
        <ul className="navbar-item flex-row ml-md-auto">

            
            <li className="nav-item dropdown message-dropdown">
            <Link className="nav-link dropdown-toggle" id="messageDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-bell">
                        <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path>
                        <path d="M13.73 21a2 2 0 0 1-3.46 0"></path>
                    </svg>

                    <span className="mail-badge badge mystyle">{this.state.notification_count}</span>
                </Link>
                <div className="dropdown-menu position-absolute" aria-labelledby="notificationDropdown">
                    <div className="notification-scroll">

                    {this.notification_count!=="0" ? 
                    
                    <>
                    {this.state.values.map((value) => (
                        <div className="dropdown-item">
                            <div className="media" onClick={() => this.updatenotification(value.notification_id, value.user_id, value.tech_id) }>
                                <div className="media-body">
                                    <div className="notification-para">
                                    <h6  className="cut-text"> {value.message}</h6>
                                    <p > {value.date} {value.time} </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    </>
                    :
                    <div className="dropdown-item">
                        <div className="media">
                            <div className="media-body">
                                <div className="notification-para">
                                <p>No Notification </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    }
                    </div>
                </div>
            </li>
            
            
            <li className="nav-item dropdown user-profile-dropdown">
                <Link className="nav-link dropdown-toggle user" id="userProfileDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-bell">
                        <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                        <circle cx="12" cy="7" r="4"></circle>
                    </svg>
                </Link>

                <div className="dropdown-menu position-absolute" aria-labelledby="userProfileDropdown">
                    <div className="">
                        <div className="dropdown-item">
                            <Link to={{ pathname: '/Profile'}} className="">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-user">
                                    <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                    <circle cx="12" cy="7" r="4"></circle>
                                </svg>
                                My Profile
                            </Link>
                        </div>
                        <div className="dropdown-item">
                            <Link to={{ pathname: '/ProfileChangePassword'}} >
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-lock">
                                    <rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect>
                                    <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
                                </svg>
                                Change Password
                            </Link>
                        </div>
                        <div className="dropdown-item">
                            <Link className="" onClick={this.logoutFucntion}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-log-out">
                                    <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                                    <polyline points="16 17 21 12 16 7"></polyline>
                                    <line x1="21" y1="12" x2="9" y2="12"></line>
                                </svg>
                                Sign Out
                            </Link>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
    </header>
</div>
);
}
}

export default TopBar