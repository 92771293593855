import React, { Component } from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Link, Redirect } from 'react-router-dom';
import { axiosInstance } from '../../../Services/PostData';
import './SkillForm.css';

class CompSkills extends Component {

    constructor(){
		super();
		this.state = {
            skill_category_name: ''
        };
		this.submitFormHandler = this.submitFormHandler.bind(this);
		this.onChangeEvent = this.onChangeEvent.bind(this);
    }
    
    componentDidMount() {
        let query = window.location.search.substring(1);
        if(query != '') {
            let pair = query.split("=");
            var queryString = pair[0];
            var queryValue = pair[1];
            this.getSkillCategoryDetails(queryString, queryValue);
        }
     }

    getSkillCategoryDetails(value, id) {
        let spinner = "loadercustumspinner";
        let overlay = "loadingcustumspinner";
        this.setState({ spinner, overlay });
        const jsonValue = { skill_category_id: id};
        axiosInstance.post(`getEditSkillCategory`, jsonValue)
        .then(res => {
        if(res.data.status === 'success' && res.data.statusCode ==='200' )
        {
            let skill_category_id = res.data.skill_details[0].skill_category_id;
            let skill_category_name = res.data.skill_details[0].skill_category;
            this.setState({skill_category_id, skill_category_name});
            let spinner = "loadercustumspinner";
            let overlay = "loadingcustumspinner hide__container";
            this.setState({ spinner, overlay });
        }
        })
        .catch(err => console.log('Login: ', err));
    }
    onChangeEvent(e) {
		this.setState({[e.target.name]: e.target.value})
	}

    validateFunction = () => {
        let skillCategoryError = "";

		if (!this.state.skill_category_name) {
			skillCategoryError = "Category Name cannot be blank";
		}

		if (skillCategoryError) {
		  this.setState({skillCategoryError});
		  return false;
		}
	
		return true;
    }

    submitFormHandler() {
        const isValid = this.validateFunction();

        if(isValid) {
            //show spinner 
            let spinner = "loadercustumspinner";
            let overlay = "loadingcustumspinner";
            this.setState({ spinner, overlay });

            let query = window.location.search.substring(1);

             //New submit (Mode = Update )
            if(query != '') {
                const jsonValue = { 
                    skill_category_id: this.state.skill_category_id, 
                    skill_category_name: this.state.skill_category_name 
                };
                axiosInstance.post(`editSkillCategory`, jsonValue)
                .then(res => {
                if(res.data.status === 'success' && res.data.statusCode ==='200' )
                {
                    let spinner = "loadercustumspinner";
                    let overlay = "loadingcustumspinner hide__container";
                    this.setState({ spinner, overlay });

                    this.setState({SkillRedirect: true});
                }
                })
                .catch(err => console.log('Login: ', err));
            }
            //New submit (Mode = New )
            else {

                const jsonValue = { skill_category_name: this.state.skill_category_name };
                axiosInstance.post(`addSkillCategory`, jsonValue)
                .then(res => {
                if(res.data.status === 'success' && res.data.statusCode ==='200' )
                {
                    let spinner = "loadercustumspinner";
                    let overlay = "loadingcustumspinner hide__container";
                    this.setState({ spinner, overlay });

                    this.setState({SkillRedirect: true});
                }
                })
                .catch(err => console.log('Login: ', err));
            }


        }
    }

render() {

    if (this.state.SkillRedirect) {
        return <Redirect to="/Skills"/>;
    }

    return (
        <div id="content" className="main-content">
            
            <div className="container formcustom" >
                <div className="container">
                    <div className="row layout-top-spacing" id="cancel-row">
                        <div style={{marginTop: '100px'}} id="ftFormArray" className="col-lg-12 layout-spacing">
                            <div className="statbox widget box box-shadow">
                                <div className="widget-header">                                
                                    <div className="row">
                                        <div className="col-xl-12 col-md-12 col-sm-12 col-12">
                                            <h4>Add Skill Category</h4> 
                                        </div>
                                    </div>
                                </div>
                                <div className="widget-content widget-content-area custom-autocomplete h-100">
                                    <form id="formid">
                                        <div className="form-group mb-4">
                                            <label for="country">Skill Category Name</label>
                                            <input type="text" className="form-control" autoComplete='off' value={this.state.skill_category_name}
                                                    name="skill_category_name" onChange={this.onChangeEvent} placeholder="Skill Category Name" />
                                        </div>
                                        <div  className="m-b-5 m-t-15" style={{ fontSize: 12, fontWeight: "bold", color: "#d82b2a" }}>
                                            {this.state.skillCategoryError}
                                        </div>
                                        <Link onClick={() => this.submitFormHandler()} className="ml-1 fr btn btn-primary">Submit</Link>
                                        <Link to="/Skills"className="fr btn btn-danger">Cancel</Link>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className="footer-wrapper">
                <div className="footer-section f-section-1">
                    <p className="">Copyright © 2020 <a target="_blank" href="javascript:void(0);">TSAS</a>, All rights reserved.</p>
                </div>
            </div> */}

<div className={this.state.overlay}>
          <div className={this.state.spinner}></div>
        </div>

        </div>
    )
}
}
export default CompSkills