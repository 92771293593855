import React, { Component } from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Link, Redirect } from 'react-router-dom';
import swal from 'sweetalert2';
import { axiosInstance } from '../../../Services/PostData';
import './BannerAdd.css';

class BannerAdd extends Component {

    constructor(){
		super();
		this.state = {
            cus_banner_title: '',
            country_values: [],
            hiddenClassName: '',
            image_file: null,
            image_preview: '',
        };
		this.submitFormHandler = this.submitFormHandler.bind(this);
		this.onChangeHandler = this.onChangeHandler.bind(this);
		this.handleImagePreview = this.handleImagePreview.bind(this);
    }
    
    componentDidMount() {

        let query = window.location.search.substring(1);
        if(query != '') {
            let pair = query.split("=");
            var queryString = pair[0];
            var queryValue = pair[1];
            this.getUserDetailss(queryString, queryValue);
        }
     }

     

    getUserDetailss(value, id) {
    //show spinner
    let spinner = "loadercustumspinner";
    let overlay = "loadingcustumspinner";
    this.setState({ spinner, overlay });
        const jsonValue = { cus_banner_id: id};

        axiosInstance.post(`getBannerDetails`, jsonValue)
        .then(res => {
        if(res.data.status === 'success' && res.data.statusCode ==='200' )
        {
            let cus_banner_title   = res.data.banner_details[0].cus_banner_title;
            let image_file = res.data.banner_details[0].cus_banner_url;
            let avatar_id = res.data.banner_details[0].cus_banner_id;

            // alert('radioRoleBtn'+admin_role_id)
            this.setState({cus_banner_title, image_file, avatar_id, edit_status: true});
            //HIDE SPINNER
            let spinner = "loadercustumspinner";
            let overlay = "loadingcustumspinner hide__container";
            this.setState({ spinner, overlay });
        }
        })
        .catch(err => console.log('Login: ', err));
    }

    onChangeHandler(e) {
		this.setState({[e.target.name]: e.target.value, messageError: '', nameError: '', imageError: ''});
	}

    validateFunction() {
        let nameError = "";
        let imageError = "";

        if (!this.state.cus_banner_title) {
			nameError = "Enter Banner Title";
		}
        
        if (this.state.image_file == null) {
			imageError = "Select Image";
		}

		if (nameError, imageError ) {
		  this.setState({nameError, imageError});
		  return false;
		}
	
		return true;
    }
    
    // Image Preview Handler
    handleImagePreview (e){
        let image_as_base64 = URL.createObjectURL(e.target.files[0])
        let image_as_files = e.target.files[0];

        this.setState({
            image_preview: image_as_base64,
            image_file: image_as_files,
        });
    }

    submitFormHandler() {
        const isValid = this.validateFunction();

        if(isValid) {
            //show spinner
            let spinner = "loadercustumspinner";
            let overlay = "loadingcustumspinner";
            this.setState({ spinner, overlay });

            let query = window.location.search.substring(1);

             //New submit (Mode = Update )
            if(query != '') {
                let formData = new FormData();
                if(this.state.image_file == null)
                {
                    this.state.image_file = '';
                }
                formData.append('cus_banner_title', this.state.cus_banner_title);
                formData.append('imageFile', this.state.image_file);
                formData.append('id', this.state.avatar_id);

                axiosInstance.post(`editBanner`, formData)
                .then(res => {
                if(res.data.status === 'success' && res.data.statusCode ==='200' )
                {
                    //HIDE SPINNER
                    let spinner = "loadercustumspinner";
                    let overlay = "loadingcustumspinner hide__container";
                    this.setState({ spinner, overlay });
                    
                    swal
                    .fire({
                      type: "info",
                      title: "Updated Successfuly",
                      // text: 'do you want to continuew',
                      confirmButtonText: "ok",
                      confirmButtonColor: "#D82B2A",
                    })
                    .then((result) => {
                      this.setState({ AvatarRedirect: true });
                    });
                }
                else if(res.data.status === 'failed' && res.data.statusCode ==='400' )
                {
                    //HIDE SPINNER
                    let spinner = "loadercustumspinner";
                    let overlay = "loadingcustumspinner hide__container";
                    this.setState({ spinner, overlay });
                    
                    swal
                    .fire({
                      type: "info",
                      title: "Error ",
                      text: res.data.msg,
                      confirmButtonText: "ok",
                      confirmButtonColor: "#D82B2A",
                    })
                    // .then((result) => {
                    //   this.setState({ AvatarRedirect: true });
                    // });                  
                }
                else if(res.data.status === 'success' && res.data.statusCode ==='201' ) {
                    //HIDE SPINNER
                    let spinner = "loadercustumspinner";
                    let overlay = "loadingcustumspinner hide__container";
                    this.setState({ spinner, overlay });

                    this.setState({messageError: res.data.msg});
                }


                })
                .catch(err => console.log('Login: ', err));
            }
            //New submit (Mode = New )
            else {
                let formData = new FormData();
                if(this.state.image_file == null)
                {
                    this.state.image_file = '';
                }
                formData.append('cus_banner_title', this.state.cus_banner_title);
                formData.append('imageFile', this.state.image_file);

                axiosInstance.post(`addBanner`, formData)
                .then(res => {
                if(res.data.status === 'success' && res.data.statusCode ==='200' )
                {
                    //HIDE SPINNER
                    let spinner = "loadercustumspinner";
                    let overlay = "loadingcustumspinner hide__container";
                    this.setState({ spinner, overlay });
                    
                    swal
                    .fire({
                      type: "info",
                      title: "Inserted Successfuly",
                      // text: 'do you want to continuew',
                      confirmButtonText: "ok",
                      confirmButtonColor: "#D82B2A",
                    })
                    .then((result) => {
                      this.setState({ AvatarRedirect: true });
                    });
                    


                    //this.setState({AvatarRedirect: true});
                }
                else if(res.data.status === 'failed' && res.data.statusCode ==='400' )
                {
                    //HIDE SPINNER
                    let spinner = "loadercustumspinner";
                    let overlay = "loadingcustumspinner hide__container";
                    this.setState({ spinner, overlay });
                    
                    swal
                    .fire({
                      type: "info",
                      title: "Error ",
                      text: res.data.msg,
                      confirmButtonText: "ok",
                      confirmButtonColor: "#D82B2A",
                    })
                    // .then((result) => {
                    //   this.setState({ AvatarRedirect: true });
                    // });                  
                }
                else if(res.data.status === 'success' && res.data.statusCode ==='201' ) {
                    //HIDE SPINNER
                    let spinner = "loadercustumspinner";
                    let overlay = "loadingcustumspinner hide__container";
                    this.setState({ spinner, overlay });

                    this.setState({messageError: res.data.msg});
                }


                
                })
                .catch(err => console.log('Login: ', err));
            }

        }
    }

render() {

    if (this.state.AvatarRedirect) {
        return <Redirect to="/Banner"/>;
    }
    return (
        <div id="content" className="main-content">
            
            <div className="container formcustom" >
                <div className="container">
                    <div className="row layout-top-spacing" id="cancel-row">
                        <div  id="ftFormArray" className="col-lg-12 layout-spacing">
                            <div className="statbox widget box box-shadow">
                                <div className="widget-header">                                
                                    <div className="row">
                                        <div className="col-xl-12 col-md-12 col-sm-12 col-12">
                                            <h4>Add Banner</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="widget-content widget-content-area custom-autocomplete h-100">
                                <form id="formid">
                                    
                                <div className="form-group mb-4">
                                    <label for="cus_banner_title">Banner Name</label>
                                    <input
                                    type="text"
                                    onChange={this.onChangeHandler}
                                    className="form-control"
                                    id="cus_banner_title"
                                    value={this.state.cus_banner_title}
                                    name="cus_banner_title"
                                    placeholder="Name"
                                    />
                                    <span className="m-b-5 m-t-15 frm-err-msg">
                                    {this.state.nameError}
                                    </span>
                                </div>

                                    <div className="form-group">
                                        <label style={{marginBottom: '0px'}} for="country"><b>Choose Avatar</b></label>
                                            <br />
                                            <input type="file" 
                                                    name="image_url" 
                                                    onChange={this.handleImagePreview} />
                                            <br />
                                            {this.state.edit_status ? 
                                                // <img src={'http://localhost/main-folder/projects/tsas-sanyaa/uploads/avatar/'+ this.state.image_file}
                                                //     class="navbar-logo" alt="" />
                                                     <img src={'https://api.touchtofix.com/demo/sanyaa-api/uploads/banner/'+ this.state.image_file}
                                                                                        class="navbar-logo" alt="" /> 
                                                :
                                                null
                                            }
                                        
                                    </div>
                                    <span className="m-b-5 m-t-15 frm-err-msg">{this.state.imageError}</span>
                                    
                                 
                                    <div className="m-b-5 m-t-15 frm-err-msg text-center">{this.state.messageError}</div>
                                    <p onClick={() => this.submitFormHandler()} className="ml-1 fr btn btn-primary">Submit</p>
                                    <Link to="/Banner"className="fr btn btn-danger">Cancel</Link>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className="footer-wrapper">
                <div className="footer-section f-section-1">
                    <p className="">Copyright © 2020 <Link target="_blank" href="javascript:void(0);">TSAS</Link>, All rights reserved.</p>
                </div>
            </div> */}

            <div className={this.state.overlay}>
              <div className={this.state.spinner}></div>
            </div>

        </div>
    )
}
}
export default BannerAdd