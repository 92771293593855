import React from 'react';
import LeftSideBar from '../../Component/LeftSideBar/LeftSideBar';
import TopBar from '../../Component/TopBar/TopBar';
import AddUserForm from '../../Component/Users/AddUserForm';

export default function EditUsers() {
    return (
        <div >
            <TopBar />
            <LeftSideBar />
            <div class="main-container" id="container">

                <div class="overlay"></div>
                <div class="search-overlay"></div>

                <AddUserForm />
            </div>
       </div>
    )
}