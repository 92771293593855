import React from 'react';

import TopBar from '../../Component/TopBar/TopBar'
import LeftSideBar from '../../Component/LeftSideBar/LeftSideBar'
import CompTechReports from '../../Component/Reports/CompTechReports'

export default function TechReports() {
    return (
        <div >
            <TopBar />
            <LeftSideBar />
            <div class="main-container" id="container">

                <div class="overlay"></div>
                <div class="search-overlay"></div>

                <CompTechReports />
            </div>
       </div>
    )
}