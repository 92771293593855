import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
// import Logo from '../logo-ts.png';
import { axiosInstance } from '../../Services/PostData';

class ResetPasswordComp extends Component {

    constructor(){
		super();
		this.state = {
			password: '',
			confirm_password: ''
		};
		this.submitResetPassword = this.submitResetPassword.bind(this)
		this.onChange = this.onChange.bind(this)
	}

	componentDidMount() { 

	}

	validate = () => {
		let passwordError = "";
		let confirmPasswordError = "";

		// password
		const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");

		if( this.state.password.length < 8) {
			passwordError = "Password should be 8 characters";
		}
		else if( ! strongRegex.test(this.state.password)) {
			passwordError = "use special character, number and capital letter";
		}
		//confirm password
		if(this.state.password !== this.state.confirm_password)
		{
			confirmPasswordError = "Wrong password";
		}

		if (passwordError || confirmPasswordError) {
		  this.setState({ passwordError, confirmPasswordError});
		  return false;
		}
	
		return true;
	  };

      submitResetPassword() {

		var query = window.location.search.substring(1);
        var pair = query.split("=");
		let queryValue = pair[1];
		
		const user = {
			password: this.state.password,
			user_id: queryValue
		};
	
		//calling signup API
		let errorMessageresponse = '';
    	const isValid = this.validate();
    	if (isValid) {

			let spinner = 'lds-dual-ring custom-screen-middle';
			let overlay = 'overlay';
			this.setState({ spinner, overlay });

			axiosInstance.post(`resetPassword`, user)
			.then(res => {
			   if(res.data.status === 'success' && res.data.statusCode ==='200' )
			   {
				this.setState({loginRedirect: true})
			   }
			   else if(res.data.statusCode ==='400' ) {
					errorMessageresponse = res.data.msg
					this.setState({ errorMessageresponse});
				}

				let spinner = 'lds-dual-ring custom-screen-middle hide__container';
				let overlay = 'overlay hide__container';
				this.setState({ spinner, overlay });

			})
			.catch(err => console.log('SignUp: ', err));
		}
	}

	onChange(e) {
		this.setState({[e.target.name]: e.target.value})
	}	
	


    render() {

		if (this.state.loginRedirect) {
            return <Redirect to="/"/>;
        }
			 
    return (
        <div class="limiter">
		<div class="container-login100">
			<div class="wrap-login100 p-l-50 p-r-50 p-t-37 p-b-30">
				<img class="loginlogo p-b-20" src="assets/images/logo-ts.png" />
				
				<form class="login100-form validate-form">
					<span class="login100-form-title p-b-35">
						Reset Password
					</span>

					<div class="wrap-input100 validate-input m-b-16" >
                    <input id="pass" class="input100" type="password" name="password" onChange={this.onChange} placeholder="Password" />
						<span class="focus-input100"></span>
						<span class="symbol-input100">
                        <span class="fa fa-lock"></span>
						</span>
					</div>
					<span className="m-b-5 m-t-15 frm-err-msg">{this.state.passwordError}</span>

					<div class="wrap-input100 validate-input m-b-16" >
						<input id="pass" class="input100" type="password" name="confirm_password" 
						onChange={this.onChange} placeholder="Confirm Password" />
						<span class="focus-input100"></span>
						<span class="symbol-input100">
							<span class="fa fa-lock"></span>
						</span>
					</div>
					<span className="m-b-5 m-t-15 frm-err-msg">{this.state.confirmPasswordError}</span>
					
					<div class="container-login100-form-btn p-t-25">
						<p onClick={this.submitResetPassword} class="login-btn login100-form-btn">
							Submit
						</p>
					</div>
					<div class="text-center w-full p-t-15	">
						<a id="forget_id" class="txt1 bo1 hov1" href="#">
                        {/* <Link to="/ForgotPassword">Forgot Password ?</Link>*/}
						</a>
					</div>

					<div class="text-center w-full p-t-42 p-b-22">
						<span class="txt1 error">Error</span>
						<span class="txt1 sucess">Login Sucessfully.</span>
					</div>	
				</form>
			</div>
		</div>
	</div>
    )
}
}

export default ResetPasswordComp