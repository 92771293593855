import React, {useEffect} from 'react';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import AddAvatar from './Pages/Avatar/AddAvatar';
import Avatar from './Pages/Avatar/Avatar';
import AddBanner from './Pages/Banner/AddBanner';
import Banner from './Pages/Banner/Banner';
import Dashboard from './Pages/Dashboard';
import AbortedFixes from './Pages/Fixes/AbortedFixes';
import ClosedFixes from './Pages/Fixes/ClosedFixes';
import FixesDetails from './Pages/Fixes/FixesDetails';
import OpenFixes from './Pages/Fixes/OpenFixes';
import PrivateFixes from './Pages/Fixes/PrivateFixes';
import ReFixesDetails from './Pages/Fixes/ReFixesDetails';
// import UserDetails from './Pages/UserDetails';
import ForgotPassword from './Pages/ForgotPassword';
import Login from './Pages/Login';
import AddCountry from './Pages/Master/Country/AddCountry';
import Country from './Pages/Master/Country/Country';
import EditCountry from './Pages/Master/Country/EditCountry';
import Payment from './Pages/Master/Payment';
import AddSkills from './Pages/Master/Skills/AddSkills';
import EditSkills from './Pages/Master/Skills/EditSkills';
import Skills from './Pages/Master/Skills/Skills';
import SMS from './Pages/Master/SMS';
import Notification from './Pages/Notification/Notification';
import ProfileEdit from './Pages/Profile/Editprofile';
import Profile from './Pages/Profile/Profile';
import Reports from './Pages/Reports/Reports';
import CustReports from './Pages/Reports/CustReports';
import TechReports from './Pages/Reports/TechReports';
import ResetPassword from './Pages/ResetPassword';
import AddRoles from './Pages/Roles/AddRoles';
import EditRoles from './Pages/Roles/EditRoles';
import Roles from './Pages/Roles/Roles';
import AddUser from './Pages/Users/AddUser';
import Customer from './Pages/Users/Customer';
import EditCustomerr from './Pages/Users/EditCustomer';
import EditTechSupportt from './Pages/Users/Edittechsupport';
import EditUsers from './Pages/Users/EditUsers';
import SiteAdmin from './Pages/Users/SiteAdmin';
import SupportAdmin from './Pages/Users/SupportAdmin';
import TechSupport from './Pages/Users/TechSupport';
import UserSuperAdmin from './Pages/Users/UserSuperAdmin';

import Chat from './Pages/Chat/Chat';
import UserNotification from './Pages/Notification/UserNotification';
import ProfileChangePassword from './Pages/Profile/ProfileChangePassword';

import CustomerFeedback from './Pages/Feedback/CustomerFeedback';
import TechSupportFeedback from './Pages/Feedback/TechSupportFeedback';
import IncomTechSupport from './Pages/Users/IncomTechSupport';

import Company from './Pages/company/CompanyComp';
import AddCompany from './Pages/company/AddCompany';
import EditCompany from './Pages/company/EditCompany';


function App() {
  
  useEffect(() => {
    // let colorCode = getComputedStyle(document.documentElement).getPropertyValue('--primary-theme-color');
    let themeName = localStorage.getItem('colorcodeTSASAdmin');
    document.documentElement.style.setProperty('--primary-theme-color', themeName);
  }, [])

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Login} />
        {/* <Route exact path="/" component={UserDetails} /> */}
        <Route exact path="/ForgotPassword" component={ForgotPassword} />
        <Route exact path="/Dashboard" component={Dashboard} />
        <Route exact path="/AddUser" component={AddUser} />
        <Route exact path="/EditUsers" component={EditUsers} />
        <Route exact path="/UserSuperAdmin" component={UserSuperAdmin} />
        <Route exact path="/SiteAdmin" component={SiteAdmin} />
        <Route exact path="/SupportAdmin" component={SupportAdmin} />
        <Route exact path="/TechSupport" component={TechSupport} />
        <Route exact path="/Customer" component={Customer} />
        <Route exact path="/OpenFixes" component={OpenFixes} />
        <Route exact path="/PrivateFixes" component={PrivateFixes} />
        <Route exact path="/ClosedFixes" component={ClosedFixes} />
        <Route exact path="/AbortedFixes" component={AbortedFixes} />
        <Route exact path="/Country" component={Country} />
        <Route exact path="/AddCountry" component={AddCountry} />
        <Route exact path="/EditCountry" component={EditCountry} />
        <Route exact path="/Payment" component={Payment} />
        <Route exact path="/SMS" component={SMS} />
        <Route exact path="/Skills" component={Skills} />
        <Route exact path="/AddSkills" component={AddSkills} />
        <Route exact path="/EditSkills" component={EditSkills} />
        <Route exact path="/Reports" component={Reports} />
        <Route exact path="/CustReports" component={CustReports} />
        <Route exact path="/TechReports" component={TechReports} />
        <Route exact path="/Notification" component={Notification} />
        <Route exact path="/Roles" component={Roles} />
        <Route exact path="/AddRoles" component={AddRoles} />
        <Route exact path="/EditRoles" component={EditRoles} />
        <Route exact path="/Banner" component={Banner} />
        <Route exact path="/Avatar" component={Avatar} />
        <Route exact path="/ResetPassword" component={ResetPassword} />
        <Route exact path="/Profile" component={Profile} />
        <Route exact path="/Editprofile" component={ProfileEdit} />
        <Route exact path="/EditTechSupport" component={EditTechSupportt} />
        <Route exact path="/EditCustomer" component={EditCustomerr} />
      
      <Route exact path="/AddAvatar" component={AddAvatar} />
      <Route exact path="/AddBanner" component={AddBanner} />

      <Route exact path="/FixesDetails" component={FixesDetails} />
      <Route exact path="/ReFixesDetails" component={ReFixesDetails} />
      <Route exact path="/Chat" component={Chat} />
      <Route exact path="/UserNotification" component={UserNotification} />

      <Route exact path="/ProfileChangePassword" component={ProfileChangePassword} />
      
      <Route exact path="/CustomerFeedback" component={CustomerFeedback} />
      <Route exact path="/TechSupportFeedback" component={TechSupportFeedback} />
      <Route exact path="/IncomTechSupport" component={IncomTechSupport} />

      <Route exact path="/Company" component={Company} />
      {/* <Route exact path="/AddCompany" component={AddCompany} /> */}
      <Route exact path="/AddCompany" render={() => <AddCompany pageTitle="new" companyData={[]} />} />

      <Route exact path="/EditCompany" component={EditCompany} />

      </Switch>
    </BrowserRouter>
   
  );
}

export default App;
