import React, {useState, useEffect} from 'react';
import { Link, Redirect } from 'react-router-dom';
import swal from 'sweetalert2';
import { axiosInstance } from '../../../Services/PostData';
import styles from '../Company.module.css';

function AddCompany({ pageTitle, companyData }) {
    
    const [overLay, setOverlay] = useState('loadingcustumspinner hide__container');
    const [companyRedirect, setCompanyRedirect] = useState(false);

    //******declaring form state ******
    const [c_name, setCName] = useState('');
    const [c_phone, setCPhone] = useState('');
    const [c_email, setCEmail] = useState('');
    const [c_address1, setCAddress1] = useState('');
    const [c_address2, setCAddress2] = useState('');
    const [c_color_code, setColorCode] = useState('');
    const [image_file, setImageFile] = useState(null);
    const [image_preview, setImagePreview] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    
    //***** declare error state *****
    const [c_name_error, setCNameError] = useState('');
    const [c_phone_error, setCPhoneError] = useState('');
    const [c_email_error, setCEmailError] = useState('');
    const [c_address1_error, setCAddress1Error] = useState('');
    const [c_address2_error, setCAddress2Error] = useState('');
    const [c_color_code_error, setColorCodeError] = useState('');
    const [image_file_error, setImageFileError] = useState("");
    const [messageError, setMessageError] = useState('');
    const [usernameError, setUsernameError] = useState('');
    const [passwordError, setPasswordError] = useState('');


    useEffect(() =>{

        if(pageTitle == 'Edit')
        {
            setCName(companyData['c_name']);
            setCEmail(companyData['c_email']);
            setCPhone(companyData['c_phone']);
            setCAddress1(companyData['c_address1']);
            setCAddress2(companyData['c_address2']);
            setColorCode(companyData['c_color_code']);
            setImagePreview('https://api.touchtofix.com/demo/sanyaa-api/uploads/company/' + companyData['c_logo_url']);
            setUsername(companyData['admin_user_name']);
        }      
    }, [companyData])

    // ********************* ONCHANGE VALIDATION FOR FORM INPUT **************************
    const onChangeHandler = (setIdentifierState, e)=> {
        setIdentifierState(e.target.value);
        setCNameError("");
        setCPhoneError("");
        setCEmailError("");
        setCAddress1Error("");
        setCAddress2Error("");
        setColorCodeError("");
        setUsernameError("");
        setPasswordError("");
        setImageFileError("");
    }

    // ********************* ONCHANGE VALIDATION FOR FORM INPUT **************************
    const handleImagePreview = (e) => {
        let image_as_base64 = URL.createObjectURL(e.target.files[0])
        let image_as_files = e.target.files[0];

        setImagePreview(image_as_base64);
        setImageFile(image_as_files);
    }

    const validateFunction = () => {
        
        if(c_name === "") { setCNameError("Enter company name") }  else { setCNameError("")  }
        if(c_phone === "") { setCPhoneError("Enter company phone number") } else { setCPhoneError("") }
        if(c_email === "") { setCEmailError("Enter company email address") } else { setCEmailError("") }
        if(c_address1 === "") { setCAddress1Error("Enter company address line 1") } else { setCAddress1Error("") }
        if(c_address2 === "") { setCAddress2Error("Enter company address line 2") } else { setCAddress2Error("") }
        if(c_color_code === "") { setColorCodeError("Enter color code") } else { setColorCodeError("") }
        if(username === "") { setUsernameError("Enter Username") } else { setUsernameError("") }
        if(password === "" && pageTitle != 'Edit') { setPasswordError("Enter Password") } else { setPasswordError("") }
        if(image_file === null && pageTitle != 'Edit') { setImageFileError("Please select logo") } else { setImageFileError("") }
        
        if(c_name === "" || c_phone === "" || c_email === "" || c_address1 === "" || c_address2 === "" || 
           c_color_code === "" || ( image_file === null && pageTitle != 'Edit' ) || username === "" || ( password === "" && pageTitle != 'Edit' ) ) {
            return false;
        }
        return true;
    }

    const submitFormHandler = () => {
        const isValid = validateFunction();
        if(isValid) {
            //show spinner
            setOverlay('loadingcustumspinner');

            let formData = new FormData();
            if(companyData?.company_id){
                formData.append('company_id', companyData.company_id);
            }
            formData.append('c_name', c_name);
            formData.append('c_phone', c_phone);
            formData.append('c_email', c_email);
            formData.append('c_address1', c_address1);
            formData.append('c_address2', c_address2);
            formData.append('c_color_code', c_color_code);
            formData.append('username', username);
            formData.append('password', password);
            
            if(image_file == null){
                formData.append('c_logo_url', '');
            }else{
                formData.append('imageFile', image_file);
            }
            

            //New submit (Mode = Update )
            if(pageTitle == 'Edit') {
                updateFunction(formData);
            }
            //New submit (Mode = New )
            else {
                saveFunction(formData);
            }
        }
    }

    const saveFunction = (formData) => {
                
        axiosInstance.post(`addCompany`, formData)
        .then(res => {
            if(res.data.status === 'success' && res.data.statusCode ==='200' )
            {
                //HIDE SPINNER & SUGGESTION MESSAGE
                setOverlay('loadingcustumspinner hide__container');
                suggestionMessage('success', 'company added successfully', '', 'add');
                clearFunction();
            }
            else if(res.data.status === 'failed' && res.data.statusCode ==='400' )
            {
                //HIDE SPINNER & SUGGESTION MESSAGE
                setOverlay('loadingcustumspinner hide__container');
                suggestionMessage('info', 'error', res.data.msg, '');                 
            }
            else if(res.data.status === 'success' && res.data.statusCode ==='201' ) {
                //HIDE SPINNER & SUGGESTION MESSAGE
                setOverlay('loadingcustumspinner hide__container');
                suggestionMessage('info', 'error', res.data.msg, '');  
                setMessageError(res.data.msg);
            }
        })
        .catch(err => {
            console.log(err);
        });
    }

    const updateFunction = (formData) => {

        axiosInstance.post(`editCompany`, formData)
        .then(res => {
            if(res.data.status === 'success' && res.data.statusCode ==='200' )
            {
                //HIDE SPINNER & SUGGESTION MESSAGE
                setOverlay('loadingcustumspinner hide__container');
                suggestionMessage('success', 'updated successfully', '', 'update');
            }
            else if(res.data.status === 'failed' && res.data.statusCode ==='400' )
            {
                //HIDE SPINNER & SUGGESTION MESSAGE
                setOverlay('loadingcustumspinner hide__container');
                suggestionMessage('info', 'Updated successfully', res.data.msg, '');          
            }
            else if(res.data.status === 'success' && res.data.statusCode ==='201' ) {
                //HIDE SPINNER & SUGGESTION MESSAGE
                setOverlay('loadingcustumspinner hide__container');
                suggestionMessage('info', 'error', res.data.msg, '');
            }
        })
        .catch(err => {
            console.log(err);
        });
    }

    const suggestionMessage = (type, title, message, mode) => {
        swal.fire({
            type: type,
            title: title,
            text: message,
            confirmButtonText: "ok",
            confirmButtonColor: "#D82B2A",
        })
        .then((result) => {
            if(mode === 'add' || mode === 'update') {
                setCompanyRedirect(true);
            }
        });
    }

    const clearFunction = () => {
        setCName('');
        setCPhone('');
        setCEmail('');
        setCAddress1('');
        setCAddress2('');
        setColorCode('');
        setImageFile(null);
        setImagePreview('');
        setUsername('');
        setPassword('');
    }

    if(companyRedirect) {
        return <Redirect to="/Company" />;
    }
    else {
        return (
            <div id="content" className="main-content">
                <div className={`${styles.container} ${styles.formcustom}`} >
                    <div className="container">
                        <div className="row layout-top-spacing" id="cancel-row">
                            <div  id="ftFormArray" className="col-lg-12 layout-spacing">
                                <div className="statbox widget box box-shadow">
                                    <div className="widget-header">                                
                                        <div className="row">
                                            <div className="col-xl-12 col-md-12 col-sm-12 col-12 pt-2">
                                                <h3>{`${pageTitle === undefined || pageTitle === "" ? "Add" : pageTitle} Company`}</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="widget-content widget-content-area custom-autocomplete h-100">
                                    <form id="formid">
                                    <div className="row">
                                        <div className="form-group mb-4 col-md-6">
                                            <label for="c_name">Company Name</label>
                                            <input
                                            type="text"
                                            onChange={onChangeHandler.bind(this, setCName)}
                                            className="form-control"
                                            id="c_name"
                                            value={c_name}
                                            name="setCName"
                                            placeholder="company name"
                                            />
                                            <span className="m-b-5 m-t-15 frm-err-msg">
                                            {c_name_error}
                                            </span>
                                        </div>
                                        <div className="form-group mb-4 col-md-6">
                                            <label for="c_email">Company Email</label>
                                            <input
                                            type="text"
                                            onChange={onChangeHandler.bind(this, setCEmail)}
                                            className="form-control"
                                            id="c_email"
                                            value={c_email}
                                            name="setCEmail"
                                            placeholder="company email"
                                            />
                                            <span className="m-b-5 m-t-15 frm-err-msg">
                                            {c_email_error}
                                            </span>
                                        </div>
                                    </div>
                                    
                                    <div className="row">
                                        <div className="form-group mb-4 col-md-6">
                                            <label for="c_phone">Company Phone</label>
                                            <input
                                            type="text"
                                            onChange={onChangeHandler.bind(this, setCPhone)}
                                            className="form-control"
                                            id="c_phone"
                                            value={c_phone}
                                            name="setCPhone"
                                            placeholder="company phone"
                                            />
                                            <span className="m-b-5 m-t-15 frm-err-msg">
                                            {c_phone_error}
                                            </span>
                                        </div>

                                        <div className="form-group mb-4 col-md-6">
                                            <label for="c_color_code">Color Code</label>
                                            <input
                                            type="text"
                                            onChange={onChangeHandler.bind(this, setColorCode)}
                                            className="form-control"
                                            id="c_color_code"
                                            value={c_color_code}
                                            name="setColorCode"
                                            placeholder="color code"
                                            />
                                            <span className="m-b-5 m-t-15 frm-err-msg">
                                            {c_color_code_error}
                                            </span>
                                        </div>

                                    </div>
                                    
                                    <div className="row">
                                        <div className="form-group mb-4 col-md-6">
                                            <label for="c_name">Username</label>
                                            <input
                                            type="text"
                                            onChange={onChangeHandler.bind(this, setUsername)}
                                            className="form-control"
                                            id="username"
                                            value={username}
                                            name="setUsername"
                                            placeholder="username"
                                            />
                                            <span className="m-b-5 m-t-15 frm-err-msg">
                                                {usernameError}
                                            </span>
                                        </div>
                                        <div className="form-group mb-4 col-md-6">
                                            <label for="c_email">Password <span className='text-danger'>{ pageTitle == 'Edit' && "(don't want to edit the password, leave it empty!)" }</span></label>
                                            <input
                                            type="text"
                                            onChange={onChangeHandler.bind(this, setPassword)}
                                            className="form-control"
                                            id="password"
                                            value={password}
                                            name="setPassword"
                                            placeholder="Enter the new password"
                                            />
                                            <span className="m-b-5 m-t-15 frm-err-msg">
                                                {passwordError}
                                            </span>
                                        </div>
                                    </div>
                                    
                                
                                    <div className="row">
                                        
                                    
                                        <div className="form-group mb-4 col-md-6">
                                            <label for="c_address1">Address Line 1</label>
                                            <input
                                            type="text"
                                            onChange={onChangeHandler.bind(this, setCAddress1)}
                                            className="form-control"
                                            id="c_address1"
                                            value={c_address1}
                                            name="setCAddress1"
                                            placeholder="address line 1"
                                            />
                                            <span className="m-b-5 m-t-15 frm-err-msg">
                                            {c_address1_error}
                                            </span>
                                        </div>

                                        <div className="form-group mb-4 col-md-6">
                                            <label for="c_address2">Address Line 2</label>
                                            <input
                                            type="text"
                                            onChange={onChangeHandler.bind(this, setCAddress2)}
                                            className="form-control"
                                            id="c_address2"
                                            value={c_address2}
                                            name="setCAddress2"
                                            placeholder="address line 2"
                                            />
                                            <span className="m-b-5 m-t-15 frm-err-msg">
                                            {c_address2_error}
                                            </span>
                                        </div>

                                    </div>

                                    <div className="row">
                                        <div className="form-group col-md-6">
                                            <label style={{marginBottom: '0px'}} for="country"><b>Choose Logo</b></label> <br />
                                            <input type="file" name="image_url" onChange={handleImagePreview} />
                                            <div className="Logo-container mt-3">
                                                <img 
                                                    src={ image_preview }
                                                    class="navbar-logo" alt="" 
                                                /> 
                                            </div>
                                        </div>

                                        {/* <div className="form-group col-md-6">
                                            <label style={{marginBottom: '0px'}} for="country"><b>Choose Favicon</b></label> <br />
                                            <input type="file" name="" />
                                            <div className="favicon-container">
                                                {
                                                    pageTitle == 'Edit'
                                                    ? 
                                                    <img 
                                                        src={'https://api.touchtofix.com/demo/sanyaa-api/uploads/company/'+ image_file}
                                                        class="navbar-logo" alt="" 
                                                    /> 
                                                    :
                                                    null
                                                }
                                            </div>
                                        </div> */}

                                    </div>
                                    <span className="m-b-5 m-t-15 frm-err-msg">{image_file_error}</span>
                                        
                                    
                                    <div className="m-b-5 m-t-15 frm-err-msg text-center">{messageError}</div>
                                        <p onClick={() => submitFormHandler()} className="ml-1 fr btn btn-primary">Submit</p>
                                        <Link to="/Company"className="fr btn btn-danger">Cancel</Link>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={overLay}>
                <div className={`loadercustumspinner`}></div>
                </div>

            </div>
        );
    }
}

export default AddCompany;