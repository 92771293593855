import React from 'react';

import TopBar from '../../Component/TopBar/TopBar'
import LeftSideBar from '../../Component/LeftSideBar/LeftSideBar'
import SuperAdmin from '../../Component/Users/SuperAdmin/SuperAdmin'

export default function Payment() {
    return (
        <div >
            <TopBar />
            <LeftSideBar />
            <div class="main-container" id="container">

                <div class="overlay"></div>
                <div class="search-overlay"></div>

                <SuperAdmin />
            </div>
       </div>
    )
}