import $ from "jquery";
import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Link } from "react-router-dom";
import { axiosInstance } from "../../Services/PostData";
import "./CompNotification.css";
import DataTable from 'react-data-table-component';
import DataTableExtensions from "react-data-table-component-extensions";

class CompNotification extends Component {
  constructor() {
    super();
    this.state = {
      values: [],
    };
    this.getNotifications = this.getNotifications.bind(this);
  }

  componentDidMount() {
    this.getNotifications();
    $(document).ready(function () {
      setTimeout(
        function () {
          $("#default-ordering").DataTable({
            oLanguage: {
              oPaginate: {
                sPrevious:
                  '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-left"><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg>',
                sNext:
                  '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>',
              },
              sInfo: "Showing page _PAGE_ of _PAGES_",
              sSearch:
                '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>',
              sSearchPlaceholder: "Search...",
              sLengthMenu: "Results :  _MENU_",
            },
            order: [[0, "asc"]],
            stripeClasses: [],
            lengthMenu: [10, 20, 50],
            pageLength: 10,
            drawCallback: function () {
              $(".dataTables_paginate > .pagination").addClass(
                " pagination-style-13 pagination-bordered mb-5"
              );
            },
          });
        }.bind(this),
        100
      );
    });
  }

  async getNotifications() {
    await axiosInstance
      .get(`getNotifications`)
      .then((res) => {
        if (res.data.status === "success" && res.data.statusCode === "200") {
          let response = res.data.notification_details;
          if (response.length != 0 || response.length != "0") {
            setTimeout(function () {
              $(".dataTables_empty").hide();
            }, 100);
          }
          let response_Details = response.map((value) => {
            return value;
          });
          this.setState({ values: response_Details });
        }
      })
      .catch((err) => console.log("Login: ", err));
  }

  // editFucntion(id) {
  //     // this.props.history.push()
  //     this.setState({redirect: true});
  // }

  deleteFucntion(id) {
    confirmAlert({
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            const user = { country_id: id };
            axiosInstance
              .post(`deleteCountry`, user)
              .then((res) => {
                if (
                  res.data.status === "success" &&
                  res.data.statusCode === "200"
                ) {
                  window.location.reload();
                }
              })
              .catch((err) => console.log("Login: ", err));
          },
        },
        {
          label: "No",
        },
      ],
    });
  }

  render() {

    const columns = [
      {
        name: 'Date',
        selector: 'date',
        sortable: true,
        filterable: true,
      },
      {
        name: 'Time',
        selector: 'time',
        sortable: true,
        filterable: true,
      },
      {
        name: 'Message',
        selector: 'message',
        sortable: true,
        filterable: true,
      },
    ];

    const data = this.state.values;
    const tableData = {
      columns,
      data
    };

    return (
      <div id="content" className="main-content">
        <div className="layout-px-spacing">
          <div className="row layout-top-spacing2 layout-top-spacing">
            <div className="col-xl-12 col-lg-12 col-sm-12 layout-spacing2  layout-spacing">
              <div className="widget-content widget-content-area pg-top">
                <div className="table-responsive mb-4 mt-1">
                <div className="top_heading_sty">
                    <p className="table-head">Notification Details</p>
                  </div>
                 
                  {/* <table
                    id="default-ordering"
                    className="table1 table table-hover dataNewTable"
                  >
                    <thead>
                      <tr className="">
                        <th>Date</th>
                        <th>Time</th>
                        <th>Message</th>
                      </tr>
                    </thead>
                    <tbody id="tableBodyd">
                      {this.state.values.map((value) => (
                        <tr className="" role="row" key={value.notification_id}>
                          <td className="table_width">
                          {value.date}
                          </td>
                          <td className="table_width">
                          {value.time}
                          </td>
                          <td className="cust_text_left_align">
                                    <p class=" cust_text_left_align" >{value.message}</p>
                          </td>


                        </tr>
                      ))}
                    </tbody>
                  </table> */}

                  
                            <DataTableExtensions {...tableData}
                                print={false}
                                exportHeaders={false}
                                pdf={false}
                                export={false}
                                >
                                <DataTable
                                    columns={columns}
                                    data={data}
                                    noHeader
                                    defaultSortAsc={false}
                                    pagination
                                    highlightOnHover
                                    // expandableRows={true}
                                    // expandableRowsComponent={<SampleExpandedComponent />}
                                    // expandOnRowClicked
                                />
                            </DataTableExtensions>


                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-wrapper">
          <div className="footer-section f-section-1">
            <p className="">
              Copyright © 2020{" "}
              <a target="_blank" href="javascript:void(0);">
                TSAS
              </a>
              , All rights reserved.
            </p>
          </div>
        </div>
      </div>
    );
  }
}
export default CompNotification;
