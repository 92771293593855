import $ from "jquery";
import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Link } from "react-router-dom";
import { axiosInstance } from "../../Services/PostData";
import "./CompRoles.css";
import DataTable from 'react-data-table-component';
import DataTableExtensions from "react-data-table-component-extensions";
import swal from 'sweetalert2';

class CompRoles extends Component {
  constructor() {
    super();
    this.state = {
      values: [],
    };
    this.getRoles = this.getRoles.bind(this);
  }

  componentDidMount() {
    this.getRoles();
  }

  async getRoles() {
    await axiosInstance
      .get(`getRoles`)
      .then((res) => {
        if (res.data.status === "success" && res.data.statusCode === "200") {
          let response = res.data.role_details;
          if (response.length != 0 || response.length != "0") {
            setTimeout(function () {
              $(".dataTables_empty").hide();
            }, 100);
          }
          let response_Details = response.map((value) => {
            return value;
          });
          this.setState({ values: response_Details });
        }
      })
      .catch((err) => console.log("Login: ", err));
  }

  // editFucntion(id) {
  //     // this.props.history.push()
  //     this.setState({redirect: true});
  // }

  deleteFucntion(id) {
    confirmAlert({
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {

            //show spinner 
            let spinner = 'lds-dual-ring custom-screen-middle';
            let overlay = 'overlay';
            this.setState({ spinner, overlay });

            const user = { id: id };
            axiosInstance
              .post(`deleteRoles`, user)
              .then((res) => {
                if (res.data.status === "success" && res.data.statusCode === "200" ) {

                  let spinner = 'lds-dual-ring custom-screen-middle hide__container';
                    let overlay = 'overlay hide__container';
                    this.setState({ spinner, overlay });

                    swal.fire({
                        type: "info",
                        text: 'Deleted Successfuly',
                        confirmButtonText: "ok",
                        confirmButtonColor: "#D82B2A",
                    })
                    .then((result) => {
                        window.location.reload(false);
                    });
                }
              })
              .catch((err) => console.log("Login: ", err));
          },
        },
        {
          label: "No",
        },
      ],
    });
  }

  
  render() {

    const columns = [
      {
        name: 'Role Name',
        selector: 'admin_role_name',
        sortable: true,
        filterable: true,
      },
      {
        name: 'Action',
        selector: (row, index) => {
          return <>
                  <Link to={{ pathname: "/EditRoles", search: "adminRoleId=" + row.admin_role_id, }} >
                          <i class="fa fa-pencil text-success font-18" aria-hidden="true" ></i>
                    </Link>
                    <Link onClick={() => this.deleteFucntion(row.admin_role_id)} className="mar-l-20">
                      <i class="fa fa-trash text-danger font-18" aria-hidden="true" ></i>
                  </Link>
                </>
        },
        sortable: true,
        filterable: true,
      }
    ];

    const data = this.state.values;
    const tableData = {
      columns,
      data
    };


    return (
      <div id="content" className="main-content">
        <div className="layout-px-spacing">
          <div className="row layout-top-spacing2 layout-top-spacing">
            <div className="col-xl-12 col-lg-12 col-sm-12 layout-spacing2  layout-spacing">
              <div className="widget-content widget-content-area pg-top">
                <div className="table-responsive mb-4 mt-1">
                <div className="top_heading_sty">
                    <p className="table-head">Role Master</p>
                    <div class="d-flex Fixes_addbtn justify-content-sm-end justify-content-center add_btn_sty">
                    <Link to="/AddRoles" className="add_btn_icon_style">
                    Add New<svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-plus-square"
                      >
                        <rect
                          x="3"
                          y="3"
                          width="18"
                          height="18"
                          rx="2"
                          ry="2"
                        ></rect>
                        <line x1="12" y1="8" x2="12" y2="16"></line>
                        <line x1="8" y1="12" x2="16" y2="12"></line>
                      </svg>
                    </Link>
                    </div>
                  </div>
                  
                  {/* <table
                    id="default-ordering"
                    className="table1 table table-hover dataNewTable"
                  >
                    <thead>
                      <tr className="">
                        <th>Role Name</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody id="tableBodyd">
                      {this.state.values.map((value) => (
                        <tr className="" role="row" key={value.admin_role_id}>
                          <td>{value.admin_role_name}</td>
                          <td>
                            <Link
                              to={{
                                pathname: "/EditCountry",
                                search: "countryId=" + value.country_id,
                              }}
                            >
                              <i
                                class="fa fa-pencil text-success font-18"
                                aria-hidden="true"
                              ></i>
                            </Link>

                            <i
                              class="fa fa-trash text-danger font-18"
                              aria-hidden="true"
                              onClick={() =>
                                this.deleteFucntion(value.country_id)
                              }
                            ></i>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table> */}
                  
                            <DataTableExtensions {...tableData}
                                print={false}
                                exportHeaders={false}
                                pdf={false}
                                export={false}
                                >
                                <DataTable
                                    columns={columns}
                                    data={data}
                                    noHeader
                                    defaultSortAsc={false}
                                    pagination
                                    highlightOnHover
                                    // expandableRows={true}
                                    // expandableRowsComponent={<SampleExpandedComponent />}
                                    // expandOnRowClicked
                                />
                            </DataTableExtensions>

                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-wrapper">
          <div className="footer-section f-section-1">
            <p className="">
              Copyright © 2020{" "}
              <a target="_blank" href="javascript:void(0);">
                TSAS
              </a>
              , All rights reserved.
            </p>
          </div>
        </div>
      </div>
    );
  }
}
export default CompRoles;
