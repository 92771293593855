import $ from "jquery";
import React, { Component,useState  } from "react";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Link } from "react-router-dom";
import { axiosInstance } from "../../Services/PostData";
import "./CompReports.css";

import DataTable from 'react-data-table-component';
import DataTableExtensions from "react-data-table-component-extensions";

import DatePicker from "react-datepicker";
 
import "react-datepicker/dist/react-datepicker.css";
class CompReports extends Component {
  constructor() {
    super();
    this.state = {
      values: [],
      showtable:false,
      from_date:new Date(),
      to_date:new Date(),
    };
    this.getReportsDetailss = this.getReportsDetailss.bind(this);
    this.onChangeHandlerfrom = this.onChangeHandlerfrom.bind(this);
    this.onChangeHandlerto = this.onChangeHandlerto.bind(this);
  }

  componentDidMount() {
    // this.getReportsDetailss();
  }

  async getReportsDetailss() {

    const jsonValue = { from_date: this.state.from_date, to_date :this.state.to_date };
    axiosInstance
      .post(`datewisereport`, jsonValue)
      .then((res) => {
        if (res.data.status === "success" && res.data.statusCode === "200") {
          let response = res.data.report_details;
            
          let response_Details = response.map((value) => {
            return value;
          });
          this.setState({ values: response_Details,showtable:true });
        }
      })
      .catch((err) => console.log("Login: ", err));
  }

  onChangeHandlerfrom(e) {
    // console.log(e);
    this.setState({ from_date: e });
  }

  onChangeHandlerto(e) {
    // console.log(e);
    this.setState({ to_date: e });
  }
  submitFormHandler()
  {
      this.getReportsDetailss();
  }

  render() {

    const columns = [
      {
        name: 'Date',
        selector: 'cus_ticket_created_date',
        sortable: true,
        filterable: true,
      },
      {
        name: 'Fixes ID',
        selector: 'cus_ticket_id',
        sortable: true,
        filterable: true,
      },
      {
        name: 'Created By',
        selector: 'cus_user_name',
        sortable: true,
        filterable: true,
      },
      {
        name: 'Assigned To',
        selector: 'tech_user_name',
        sortable: true,
        filterable: true,
      },
      {
        name: 'Status',
        selector: 'cus_ticket_assign_status',
        sortable: true,
        filterable: true,
      },
      {
        name: 'Tech Amount',
        selector: 'tech_amount',
        sortable: true,
        filterable: true,
      },
      {
        name: 'Customer Amount',
        selector: 'cus_amount',
        sortable: true,
        filterable: true,
      },
    ];

    const data = this.state.values;
    const tableData = {
      columns,
      data
    };
    // const [startDate, setStartDate] = useState(new Date());
    // var f1 = flatpickr(document.getElementById('basicFlatpickr'));
    return (
      <div id="content" className="main-content">
        <div className="layout-px-spacing">
          <div className="row layout-top-spacing2 layout-top-spacing">
            <div className="col-xl-12 col-lg-12 col-sm-12 layout-spacing2  layout-spacing">
              <div className="widget-content widget-content-area pg-top set_mini_height">
                <div className="table-responsive mb-4 mt-1">
                    <div className="top_heading_sty">
                        <p className="table-head">Date Wise Report</p>
                    </div>
                        <div class="form-group mb-0 form_cut_dtyle">
                            <label for="admin_user_name">From Date</label>
                             <DatePicker className="form-control" selected={this.state.from_date} name="from_date" onChange={this.onChangeHandlerfrom}  />
                             &nbsp; &nbsp; &nbsp;
                        </div>
                         <div class="form-group mb-0 form_cut_dtyle">
                             <label for="admin_user_name">To Date</label>
                             <DatePicker className="form-control" selected={this.state.to_date} name="to_date" onChange={this.onChangeHandlerto} />
                         </div>
                         <div class="form-group mb-0 form_cut_dtyle">
                         <p
                            onClick={() => this.submitFormHandler()}
                            className="ml-1 cust_top btn btn-primary">
                            Search
                        </p>
                         </div>
                        


                    {this.state.showtable ===true ? (
                        <div >
                            <DataTableExtensions {...tableData}
                                print={false}
                                exportHeaders={true}
                                pdf={true}>
                                <DataTable
                                    columns={columns}
                                    data={data}
                                    noHeader
                                    defaultSortField="id"
                                    defaultSortAsc={false}
                                    pagination
                                    highlightOnHover
                                />
                            </DataTableExtensions>
                        </div>
                    ) :
                    (
                      <div className="cust_style_dislaynone">
                      <p>Select Dates To Get the Reports</p>
                    </div>
                    )}

                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-wrapper">
          <div className="footer-section f-section-1">
            <p className="">
              Copyright © 2020{" "}
              <a target="_blank" href="javascript:void(0);">
                TSAS
              </a>
              , All rights reserved.
            </p>
          </div>
        </div>
      </div>
    );
  }
}
export default CompReports;
