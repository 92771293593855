import $ from 'jquery';
import React, { Component } from 'react';
import { confirmAlert } from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css';
import { axiosInstance } from '../../Services/PostData';
import './chat.css';
import './fixesdetails.css';

class CompReFixesDetails extends Component {
    constructor(){
		super();
		this.state = {
            responseValue: []
        };
		this.getFixesDetails = this.getFixesDetails.bind(this);
		// this.loadDatatable = this.loadDatatable.bind(this);
    }
    
    componentDidMount() {
        let spinner = "loadercustumspinner";
        let overlay = "loadingcustumspinner";
        this.setState({ spinner, overlay });
        this.getFixesDetails();
        $(document).ready(function () {
            setTimeout(
              function() {
                $('#default-ordering').DataTable( {
                    "oLanguage": {
                        "oPaginate": { "sPrevious": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-left"><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg>', "sNext": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>' },
                        "sInfo": "Showing page _PAGE_ of _PAGES_",
                        "sSearch": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>',
                        "sSearchPlaceholder": "Search...",
                       "sLengthMenu": "Results :  _MENU_",
                    },
                    "order": [[ 0, "asc" ]],
                    "stripeClasses": [],
                    "lengthMenu": [10, 20, 50],
                    "pageLength": 10,
                    drawCallback: function () { $('.dataTables_paginate > .pagination').addClass(' pagination-style-13 pagination-bordered mb-5'); }
                } );
        
              }.bind(this),200);
        });
        // this.loadDatatable();
        
    }
    
    // loadDatatable() {

    // }


    reassigntickets(id) {

        let tech_user_id = id;
        let cus_user_id= this.state.queryValue1;
        //let ticket_history_id = this.state.queryValue2;
        let ticket_history_id = 0;
        const jsonValue = { 'cus_user_id': cus_user_id ,'tech_user_id':tech_user_id,'ticket_history_id':ticket_history_id};

        confirmAlert({
          message: "Are you sure to do this.",
          buttons: [
            {
              label: "Yes",
              onClick: () => {
                const user = { country_id: id };
                axiosInstance
                  .post(`assignFixesRequest`, jsonValue)
                  .then((res) => {
                    if (
                      res.data.status === "success" &&
                      res.data.statusCode === "200"
                    ) {
                      window.location.reload();
                    }
                  })
                  .catch((err) => console.log("Login: ", err));
              },
            },
            {
              label: "No",
            },
          ],
        });
      }

    
    async getFixesDetails() {
        const baseUrl = window.$baseUrl;
        this.setState({baseUrl});
        
        // let query = window.location.search.substring(1);

        // console.log(window.location.search.substring(2));

        // let pair = query.split("=");
        // var queryString = pair[0];
        // var queryValue = pair[1];

        let query = window.location.search.substring(2);


        let pair = query.split("=");
        var queryString = pair[1];
        var queryValue = queryString.split(",");

        var queryValue1 = queryValue[0];
        var queryValue2 = queryValue[2];

        this.setState({queryValue1,queryValue2});

        const jsonValue = { 'cus_user_id': queryValue1 };

        await axiosInstance.post(`getOnlineTechUsers`, jsonValue)
            .then(res => {
                if(res.data.status === 'success' && res.data.statusCode ==='200' )
                {
                    let response = res.data.user_details;
                    if(response.length != 0 || response.length != '0')
                    {
                        setTimeout(function(){
                            $( ".dataTables_empty" ).hide();
                        }, 100);          
                    }
                    let Details = response.map(values_response => { return values_response });
                    this.setState({ responseValue: Details });
                }		 
                    let spinner = "loadercustumspinner";
                    let overlay = "loadingcustumspinner hide__container";
                    this.setState({ spinner, overlay });
            })
            .catch(err => console.log('Login: ', err));
    }

render() {
    return (
        <div id="content" className="main-content">
            <div className="layout-px-spacing">
                <div className="row layout-top-spacing2 layout-top-spacing">
                    <div className="col-xl-12 col-lg-12 col-sm-12 layout-spacing2  layout-spacing">
                       <div className="widget-content widget-content-area pg-top">
                            <div className="table-responsive mb-4 mt-1">
                            <p className="table-head table-head-no-add">Online Users</p>
                                <table id="default-ordering" className="table1 table table-hover dataNewTable">
                                    <thead>
                                        <tr className="">
                                            <th style={{width: '13% !important'}}>Tech User Name</th>
                                            <th>SKill Category</th>
                                            <th>Skill Set</th>
                                            <th>Rate</th>
                                            {/* <th>Details</th> */}
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody id="tableBodyd">

                                    {this.state.responseValue.map(values => (
                                    <tr className="" role="row" key={values.user_id}>
                                        {/* <td>{values.date} &nbsp; {values.time}</td> */}
                                        <td>{values.user_name}</td>
                                        <td>{values.skill_category}</td>
                                        <td>{values.skillset}</td>
                                        <td>{values.rate}</td>
                                        {/* <td>{values.cus_ticket_title}</td> */}
                                        <td className="cursor-pointer" >
                                        <p
                                            class="Suspend_user"
                                            onClick={() =>
                                            this.reassigntickets(values.user_id)
                                            }
                                        >
                                            {" "}
                                            Reassign{" "}
                                        </p>

                                        </td>
                                        
                                    </tr>
                            
                                    ))}
                                        
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

            <div id="faderightModal" className="modal animated fadeInRight custo-fadeInRight show" role="dialog" aria-modal="true">
                <div className="modal-dialog-content modal-dialog" style={{width: '35%'}}>
                    <div className="modal-background-color modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Negotiate Rate</h5>
                    </div>
                        <div className="modal-body">
                            <div>
                                <div className="">
                                    <div className="row">
                                        <form style={{width: '100%'}}>
                                        <div class="form-group">
                                            <label for="exampleFormControlInput1">
                                                Tech Support Rate : {this.state.techie_rate} 
                                            </label>
                                        </div> 
                                        <div class="form-group">
                                            <label for="exampleFormControlInput1">Enter Negotiation Rate</label>
                                            <input type="text" class="form-control" id="" name="amount" placeholder="Enter Rate" onChange={this.onChange} />
                                        </div> 
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer md-button">
                            <button className="btn" data-dismiss="modal" onClick={this.closeModalHere}><i className="flaticon-cancel-12"></i> Close</button>
                            <button className="btn" data-dismiss="modal" onClick={this.submitNegotiateRate}><i className="flaticon-cancel-12"></i> Submit</button>
                        </div>
                    </div>
                </div>
            </div>

            {/*<div className="footer-wrapper">
                <div className="footer-section f-section-1">
                    <p className="">Copyright © 2020 <a target="_blank" href="javascript:void(0);">TSAS</a>, All rights reserved.</p>
                </div>
            </div>*/}
        <div className={this.state.overlay}>
          <div className={this.state.spinner}></div>
        </div>
        </div>
    )
}
}
export default CompReFixesDetails