import React, { Component } from "react";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Link, Redirect } from "react-router-dom";
import swal from 'sweetalert2';
import { axiosInstance } from "../../../Services/PostData";
import "./EditCustomer.css";

class EditCustomer extends Component {
  constructor() {
    super();
    this.state = {
      user_name: "",
      user_phone: "",
      user_email: "",
      user_gender: "",
      admin_password: "",
      confirm_password: "",
      optionsState: "",
      role_values: [],
      country_values: [],
      hiddenClassName: "",
      user_id:"",
      redirecturl: "/Customer",
    };
    this.submitFormHandler = this.submitFormHandler.bind(this);
    this.getCountryPrefix = this.getCountryPrefix.bind(this);
    this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  componentDidMount() {
    //show spinner
    let spinner = "loadercustumspinner";
    let overlay = "loadingcustumspinner";
    this.setState({ spinner, overlay });
    let query = window.location.search.substring(1);
    if (query != "") {
      let pair = query.split("=");
      var queryString = pair[0];
      var queryValue = pair[1];
      this.getUserDetailss(queryString, queryValue);
    }
    
    this.getCountryDetails();
  }

  getCountryDetails() {
    axiosInstance
      .get(`getCountry`)
      .then((res) => {
        if (res.data.status === "success" && res.data.statusCode === "200") {
          let countryResponse = res.data.country_details;
          let country_response_Details = countryResponse.map((value) => {
            return value;
          });
          this.setState({ country_values: country_response_Details });
          this.state.mobile_number_count = country_response_Details[0].mobile_length;
          this.state.optionsState = country_response_Details[0].prefix;
          let spinner = "loadercustumspinner";
          let overlay = "loadingcustumspinner hide__container";
          this.setState({ spinner, overlay });
        }
      })
      .catch((err) => console.log(err));
  }

  getCountryPrefix(e) {
    this.state.optionsState = e.target.value;
    this.state.mobile_number_count = e.target.selectedOptions[0].getAttribute(
      "data-length"
    );

    // alert(this.state.mobile_number_count);
    // console.log(this.state.optionsState);
  }

  getUserDetailss(value, id) {
    //show spinner
    let spinner = "loadercustumspinner";
    let overlay = "loadingcustumspinner";
    this.setState({ spinner, overlay });
    const jsonValue = { user_id: id };
    axiosInstance
      .post(`getCustomers_byid`, jsonValue)
      .then((res) => {
        if (res.data.status === "success" && res.data.statusCode === "200") {
          let hiddenClassName = "hide__container";
          let user_id = res.data.user_details[0].user_id;
          let user_name = res.data.user_details[0].user_name;
          let user_email = res.data.user_details[0].user_email;
          let user_phone = res.data.user_details[0].user_phone;
          let optionsState = res.data.user_details[0].phone_prefix;
          let user_gender = res.data.user_details[0].user_gender;
          let mobile_number_count = res.data.user_details[0].mobile_length;
          let redirecturl = "/Customer";


          let spinner = "loadercustumspinner";
          let overlay = "loadingcustumspinner hide__container";
          this.setState({ spinner, overlay });

          // alert('radioRoleBtn'+admin_role_id)
          this.setState({
            hiddenClassName,
            user_id,
            user_name,
            user_email,
            user_phone,
            optionsState,
            redirecturl,
            user_gender,
            mobile_number_count
          });
          
        }
      })
      .catch((err) => console.log("Login: ", err));
  }
  onChangeHandler(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  validateFunction() {
    let nameError = "";
    let phoneError = "";
    let emailError = "";
    let passwordError = "";
    let confirmPasswordError = "";
    let roleError = "";

    if (!this.state.user_name) {
      nameError = "Name cannot be blank";
    }
    if (!this.state.user_phone) {
      phoneError = "Enter Mobile Number";
    } else if (
      this.state.user_phone.length != this.state.mobile_number_count
    ) {
      phoneError =
        "Your mobile number should be " +
        this.state.mobile_number_count +
        " Digit";
    }

    if (!this.state.user_email) {
      emailError = "Email cannot be blank";
    }

    // password
    const strongRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
    );
    if (this.state.admin_password.length < 8) {
      passwordError = "Password should be 8 characters";
    } else if (!strongRegex.test(this.state.admin_password)) {
      passwordError = "use special character, number and capital letter";
    }
    //confirm password
    if (this.state.admin_password !== this.state.confirm_password) {
      confirmPasswordError = "Wrong password";
    }


    if (
      nameError ||
      phoneError ||
      emailError ||
      passwordError ||
      confirmPasswordError ||
      roleError
    ) {
      this.setState({
        nameError,
        phoneError,
        emailError,
        passwordError,
        confirmPasswordError,
        roleError,
      });
      return false;
    }

    return true;
  }

  editValidateFunction() {
    let nameError = "";
    let phoneError = "";
    let emailError = "";
    let roleError = "";
    let user_genderError = "";

    if (!this.state.user_name) {
      nameError = "Name cannot be blank";
    }
    if (!this.state.user_phone) {
      phoneError = "Enter Mobile Number";
    } 
    else if (this.state.user_phone.length != this.state.mobile_number_count ) {
      phoneError =
        "Your mobile number should be " +
        this.state.mobile_number_count +
        " Digit";
    }

    if (!this.state.user_email) {
      emailError = "Email cannot be blank";
    }

    if (!this.state.user_gender) {
      user_genderError = "Skill set cannot be blank";
    }


    if (nameError || phoneError || emailError || roleError || user_genderError) {
      this.setState({ nameError, phoneError, emailError, roleError, user_genderError });
      return false;
    }

    return true;
  }

  submitFormHandler() {
    let query = window.location.search.substring(1);

    if (query != "") {
      const isValid = this.editValidateFunction();
      if (isValid) {
        //show spinner
        let spinner = "loadercustumspinner";
        let overlay = "loadingcustumspinner";
        this.setState({ spinner, overlay });

        //  update submit (Mode = Update )
        const jsonValue = {
          user_id: this.state.user_id,
          name: this.state.user_name,
          email: this.state.user_email,
          phone: this.state.user_phone,
          prefix: this.state.optionsState,
          user_gender: this.state.user_gender,
        };

        // console.log(jsonValue);
        axiosInstance
          .post(`updateCustomerDetails`, jsonValue)
          .then((res) => {
            if (
              res.data.status === "success" &&
              res.data.statusCode === "200"
            ) {
              let spinner = "loadercustumspinner";
              let overlay = "loadingcustumspinner hide__container";
              this.setState({ spinner, overlay });
              swal
                .fire({
                  type: "info",
                  title: "Updated Successfuly",
                  // text: 'do you want to continuew',
                  confirmButtonText: "ok",
                  confirmButtonColor: "#D82B2A",
                })
                .then((result) => {
                  this.setState({ userRedirect: true });
                });
            } else if (
              res.data.status === "success" &&
              res.data.statusCode === "201"
            ) {
              let messageError = res.data.msg;
              this.setState({ messageError });
            }
          })
          .catch((err) => console.log("Login: ", err));
      }
    } 
    
  }

  render() {
    if (this.state.userRedirect) {
      return <Redirect to={this.state.redirecturl} />;
    }

    // console.log(this.state.admin_role_id);
    return (
      <div id="content" className="main-content">
        <div className="container formcustom">
          <div className="container">
            <div className="row layout-top-spacing" id="cancel-row">
              <div id="ftFormArray" className="col-lg-12 layout-spacing">
                <div className="statbox widget box box-shadow">
                  <div className="widget-header">
                    <div className="row">
                      <div className="col-xl-12 col-md-12 col-sm-12 col-12">
                      <h4>Edit Tech Support User</h4>
                      </div>
                    </div>
                  </div>
                  <div className="widget-content widget-content-area custom-autocomplete h-100">
                    <form id="formid">
                      <div className="form-group mb-4">
                        <label for="user_name">Name</label>
                        <input
                          type="text"
                          onChange={this.onChangeHandler}
                          className="form-control"
                          id="user_name"
                          value={this.state.user_name}
                          name="user_name"
                          placeholder="Name"
                        />
                        <span className="m-b-5 m-t-15 frm-err-msg">
                          {this.state.nameError}
                        </span>
                      </div>

                      <div className="form-group">
                        <label for="country">Phone Number</label>
                        <div className="input-group mb-4">
                          <div className="selected-flag">
                            {/* <select onChange={this.getCountryPrefix}>
                                            {this.state.country_values.map(team => (
                                                <option key={team.country_id} data-length={team.mobile_length} value={team.prefix} > {team.prefix} </option>
                                            ))}
                                            </select> */}

                            <select
                              value={this.state.optionsState}
                              name="e_country"
                              onChange={(e) =>
                                this.setState({ optionsState: e.target.value , mobile_number_count : e.target.selectedOptions[0].getAttribute("data-length")})
                              }
                              autoComplete="off"
                            >
                              {this.state.country_values.map((team) => (
                                <option
                                  key={team.country_id}
                                  data-length={team.mobile_length}
                                  value={team.prefix}
                                >
                                  {" "}
                                  {team.prefix}{" "}
                                </option>
                              ))}
                            </select>
                          </div>
                          <input
                            type="text"
                            onChange={this.onChangeHandler}
                            className="form-control"
                            id="user_phone"
                            name="user_phone"
                            value={this.state.user_phone}
                            placeholder="Phone Number"
                          />
                        </div>
                        <span className="m-b-5 m-t-15 frm-err-msg">
                          {this.state.phoneError}
                        </span>
                      </div>

                      <div className="form-group mb-4">
                        <label for="email1">Email address</label>
                        <input
                          type="email"
                          onChange={this.onChangeHandler}
                          className="form-control"
                          name="user_email"
                          value={this.state.user_email}
                          id="email"
                          aria-describedby="emailHelp1"
                          placeholder="Email"
                        />
                        <span className="m-b-5 m-t-15 frm-err-msg">
                          {this.state.emailError}
                        </span>
                      </div>

                      <div className="form-group">
                        <label style={{marginBottom: '0px'}} for="country"><b>Gender</b></label>
                        <div style={{padding: '10px'}} className="widget-content widget-content-area">
                            <div className="custom-control custom-radio custom-control-inline">
                                <input type="radio" value="male"
                                        id="male"
                                        onChange={this.onChangeHandler}
                                        name="user_gender" 
                                        checked={this.state.user_gender === 'male'}
                                        className="custom-control-input" />
                                <label className="custom-control-label" for="male">
                                    Male
                                </label>
                            </div>

                            <div className="custom-control custom-radio custom-control-inline">
                                <input type="radio" value="female"
                                        id="female"
                                        onChange={this.onChangeHandler}
                                        name="user_gender" 
                                        checked={this.state.user_gender === 'female'}
                                        className="custom-control-input" />
                                <label className="custom-control-label" for="female">
                                    Female
                                </label>
                            </div>
                            
                            <div className="custom-control custom-radio custom-control-inline">
                                <input type="radio" value="others"
                                        id="others"
                                        onChange={this.onChangeHandler}
                                        name="user_gender" 
                                        checked={this.state.user_gender === 'others'}
                                        className="custom-control-input" />
                                <label className="custom-control-label" for="others">
                                Others
                                </label>
                            </div>
                        </div>
                        <span className="m-b-5 m-t-15 frm-err-msg">{this.state.user_genderError}</span>
                    </div>

                      <div className="form-group mb-4">
                        <label style={{ marginBottom: "0px" }} for="country">
                          {/* <b>Role</b> */}
                        </label>

                        <div class="row form-group mb-4 checkbox_cust_style">
                        {this.state.role_values.map((value_details) => (
                            <div class="col-lg-6 col-12 padding_sty">
                                <div class="custom-control custom-checkbox custom-control-inline">
                                    <input type="checkbox" id={value_details.skill_category_id} name={value_details.skill_category} class="custom-control-input" />
                                    <label class="custom-control-label" for={value_details.skill_category_id}>{value_details.skill_category}</label>
                                </div>
                            </div>
                        ))}
                        </div>

                        <span className="m-b-5 m-t-15 frm-err-msg">
                          {this.state.roleError}
                        </span>
                      </div>

                      <div className="m-b-5 m-t-15 frm-err-msg text-center">
                        {this.state.messageError}
                      </div>

                      <p
                        onClick={() => this.submitFormHandler()}
                        className="ml-1 fr btn btn-primary"
                      >
                        Submit
                      </p>
                      <Link
                        to={this.state.redirecturl}
                        className="fr btn btn-danger"
                      >
                        Cancel
                      </Link>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="footer-wrapper">
                <div className="footer-section f-section-1">
                    <p className="">Copyright © 2020 <Link target="_blank" href="javascript:void(0);">TSAS</Link>, All rights reserved.</p>
                </div>
            </div> */}

        <div className={this.state.overlay}>
          <div className={this.state.spinner}></div>
        </div>
      </div>
    );
  }
}
export default EditCustomer;
