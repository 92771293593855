import React, { Component } from "react";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Link, Redirect } from "react-router-dom";
import swal from 'sweetalert2';
import Cookies from "universal-cookie";
import { axiosInstance } from "../../../Services/PostData";
import "./Editprofile.css";
const cookies = new Cookies();

class Editprofile extends Component {
  constructor() {
    super();
    this.state = {
      admin_user_email: "",
      prefix: "",
      admin_user_phone: "",
      admin_user_name: "",
      admin_role_name: "",
      admin_user_id: "",
    };
    this.submitFormHandler = this.submitFormHandler.bind(this);
    this.onChangeEvent = this.onChangeEvent.bind(this);
  }

  componentDidMount() {
    //show spinner
    let spinner = "loadercustumspinner";
    let overlay = "loadingcustumspinner";
    this.setState({ spinner, overlay });
    let adminUserId = cookies.get("adminUserId");
    this.getprofileedit(adminUserId);
  }

  getprofileedit(id) {
    const jsonValue = { admin_user_id: id };
    axiosInstance
      .post(`getprofileedit`, jsonValue)
      .then((res) => {
        console.log(res.data.status);
        if (res.data.status === "success" && res.data.statusCode === "200") {
          let admin_user_name = res.data.user_details[0].admin_user_name;
          let admin_user_id = res.data.user_details[0].admin_user_id;
          let admin_user_email = res.data.user_details[0].admin_user_email;
          let prefix = res.data.user_details[0].prefix;
          let admin_user_phone = res.data.user_details[0].admin_user_phone;
          let admin_role_name = res.data.user_details[0].admin_role_name;
          this.setState({
            admin_user_name,
            admin_user_email,
            prefix,
            admin_user_phone,
            admin_role_name,
            admin_user_id,
          });
          let spinner = "loadercustumspinner";
          let overlay = "loadingcustumspinner hide__container";
          this.setState({ spinner, overlay });
        }
      })
      .catch((err) => console.log("Login: ", err));
  }
  
  onChangeEvent(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  validateFunction() {
    let userNameERROR = "";
    if (!this.state.admin_user_name) {
      userNameERROR = "User Name cannot be blank";
    }
    if (userNameERROR) {
      this.setState({ userNameERROR });
      return false;
    }

    return true;
  }

  submitFormHandler() {
    const isValid = this.validateFunction();

    if (isValid) {
      this.setState({ userNameERROR: "" });
      //show spinner
      let spinner = "loadercustumspinner";
      let overlay = "loadingcustumspinner";
      this.setState({ spinner, overlay });


      let query = window.location.search.substring(1);

      //New submit (Mode = New )
      const jsonValue = {
        admin_user_name: this.state.admin_user_name,
        admin_user_id: this.state.admin_user_id,
      };
      axiosInstance.post(`editprofile`, jsonValue)
      .then(res => {
      if(res.data.status === 'success' && res.data.statusCode ==='200' )
      {
        let spinner = "loadercustumspinner";
        let overlay = "loadingcustumspinner hide__container";
        this.setState({ spinner, overlay });
          swal.fire({
              type: 'info',
              title: 'Updated Successfuly',
              // text: 'do you want to continuew',
              confirmButtonText: 'ok',
              confirmButtonColor: '#D82B2A'
          }).then ((result) => {
              this.setState({countryRedirect: true});
          });

      }
      })
      .catch(err => console.log('Login: ', err));
    }
  }

  render() {
    let adminUserId = cookies.get("adminUserId");

    if (this.state.countryRedirect) {
      return <Redirect to={{ pathname: "/Profile" }} />;
    }

    return (
      <div id="content" className="main-content">
        <div className="layout-px-spacing">
          <div className="row layout-top-spacing2 layout-top-spacing">
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 layout-top-spacing profile_padd_left">
              <div class="user-profile layout-spacing">
                <div class="widget-content widget-content-area widget-content-area-res">
                  <div class="d-flex justify-content-between">
                    <h3 class="">Edit Profile </h3>
                    <a class="mt-2 edit-profile edit-profile-back"></a>
                  </div>
                  <div class="text-center user-info">
                    <img
                      class="profile_image_res"
                      src="assets/img/user.png"
                      alt="avatar"
                    />
                    {/* <p class="">{this.state.admin_user_name}</p> */}
                  </div>
                  <br></br>
                  <div class="form" id="formid">
                    <div class="row">
                      <div class="col-sm-6">
                        <div class="form-group">
                          <label for="fullName">Email</label>
                          <input
                            name="admin_user_email"
                            type="text"
                            class="form-control mb-4"
                            readOnly
                            value={this.state.admin_user_email}
                            id="fullName"
                          />
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="form-group">
                          <label for="fullName">Phone number</label>
                          <input
                            name="admin_user_phone"
                            type="text"
                            class="form-control mb-4"
                            readOnly
                            id="fullName"
                            value={
                              this.state.prefix +
                              "  " +
                              this.state.admin_user_phone
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group mb-4">
                      <label for="country">User Name</label>
                      <input
                        type="text"
                        className="form-control"
                        autoComplete="off"
                        name="admin_user_name"
                        value={this.state.admin_user_name}
                        onChange={this.onChangeEvent}
                        placeholder="Enter Mobile Length"
                      />
                    </div>

                    <span
                      className="m-b-5 m-t-15"
                      style={{
                        fontSize: 12,
                        fontWeight: "bold",
                        color: "#d82b2a",
                      }}
                    >
                      {this.state.userNameERROR}
                    </span>

                    <div class="col-sm-6">
                      <Link
                        onClick={() => this.submitFormHandler()}
                        className="btn btn-primary"
                      >
                        Submit
                      </Link>
                      &nbsp; &nbsp;
                      <Link
                        to={{ pathname: "/Profile" }}
                        className="btn btn-danger"
                      >
                        Cancel
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

            <div className={this.state.overlay}>
              <div className={this.state.spinner}></div>
          </div>

      </div>
    );
  }
}

export default Editprofile;
